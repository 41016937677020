import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import pt from 'dayjs/locale/pt'

dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(isToday)
dayjs.locale(pt)
const WinvelMixin = {
    data() {
        return {
            dayjs: dayjs,
        };
    },
    computed: {
        errors() {
            return this.$page.props.errors
        },
    },
    methods: {
        clearAxiosValidate() {
            this.$page.props.errors = {}
        },
        validateAxiosForm(error) {
            if (error.response.status === 422) {
                const errors = error.response.data.errors;
                if (errors) {
                    Object.keys(errors).forEach((key) => {
                        this.errors[key] = errors[key][0];
                    });
                }
            }
        },
        addUrlParams(paramName, paramValue) {
            const urlWithoutParams = window.location.href.split('?')[0];
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set(paramName, paramValue);
            window.history.pushState({}, document.title, `${urlWithoutParams}?${urlParams.toString()}`);
        },
        removeUrlParams(paramName) {
            const urlWithoutParams = window.location.href.split('?')[0];
            window.history.pushState({}, document.title, urlWithoutParams);
        },
        isSuperAdmin(user) {
            return user.email === 'winvelapps@gmail.com';
        },
        changeFormValue(field, value) {
            this.form[field] = value
        },
        dtIsSameDay(dt, dt_two) {
            if (dayjs(dt).format('YYYY-MM-DD') === dayjs(dt_two).format('YYYY-MM-DD')) {
                return true
            }
            return false
        },
        dtToCalendar(date) {

            if (!date) {
                return null
            }
            date = new Date(date);
            const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            const dayOfWeek = daysOfWeek[date.getUTCDay()];
            const month = months[date.getUTCMonth()];
            const day = date.getUTCDate();
            const year = date.getUTCFullYear();
            let hours = date.getHours();
            const minutes = date.getUTCMinutes();
            const seconds = date.getUTCSeconds();
            if (hours < 10) {
                hours = '0' + hours;
            }

            const timeZoneOffset = date.getTimezoneOffset();
            const timeZoneOffsetHours = Math.abs(Math.floor(timeZoneOffset / 60));
            const timeZoneOffsetMinutes = Math.abs(timeZoneOffset % 60);
            const timeZoneSign = timeZoneOffset >= 0 ? '-' : '+';

            const formattedDateString = new Date(`${dayOfWeek} ${month} ${day.toString().padStart(2, '0')} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} GMT${timeZoneSign}${timeZoneOffsetHours.toString().padStart(2, '0')}${timeZoneOffsetMinutes.toString().padStart(2, '0')} (Hora de verão da Europa Ocidental)`);

            return formattedDateString;

        },

        dtToFront(dt) {
            if (dayjs(dt, 'YYYY-MM-DD', true).isValid()) {
                return dayjs(dt).format('DD/MM/YYYY')

            } else {
                return dayjs(dt).format('DD/MM/YYYY HH:mm')
            }
        },
        horaToFront(time) {
            return dayjs(time).format('HH:mm')
        },
        dtExtensa(dt) {
            if (!dt) return 'Sem data'
            return dayjs(dt).format('dddd, DD [de] MMMM [de] YYYY')
        },
        dtToBack(dt) {
            if (dayjs(dt, 'DD/MM/YYYY', true).isValid()) {
                return dayjs(dt).format('YYYY-MM-DD')

            } else {
                return dayjs(dt).format('YYYY-MM-DD HH:mm')
            }
        },
        timeAgo(dt, dt_dois = null) {
            if (dt_dois) {
                return dayjs(dt).to(dt_dois)
            }
            return dayjs(dt).fromNow()
        },
        isSameDay(dt, dt_two) {
            return this.dayjs(dt).isSame(this.dayjs(dt_two), 'day')
        },
        dtDiff(dt, dt_dois = null, unit = 'hours') {
            if (dt_dois) {
                return Math.abs(dayjs(dt_dois).diff(dt, unit))
            }
            return Math.abs(dayjs().diff(dt_dois, unit))
        },
        can(permission) {
            if (this.$page.props.permissions.indexOf(permission) > -1) {
                return true
            }
            return false
        },
        goTo(url) {
            this.$inertia.visit(url)
        },
        goToUrl(url) {
            window.location = url
        },

    },

};

export default WinvelMixin;
