<template>
    <div>
        <Button type="button" severity="secondary" icon="pi pi-ellipsis-v" @click="toggleMenuOpcoes"
                aria-haspopup="true" aria-controls="overlay_menu"/>
        <MenuPrime ref="menuOpcoes" id="overlay_menu" :model="itemsMenuOpcoes" :popup="true"/>

        <Dialog v-if="editarLocalidadesIsOpen" v-model:visible="editarLocalidadesIsOpen" modal
                header="Editar localidades"
                :style="{ width: '50%' }">
            <MassEditLocalidades :servicosIds="selectedServicos.map(el=> el.id)" @refresh="$emit('refresh')"
                                 @closeSidebar="editarLocalidadesIsOpen=false"/>
        </Dialog>

        <Dialog v-if="editarDatasIsOpen" v-model:visible="editarDatasIsOpen" modal header="Editar Datas"
                :style="{ width: '50%' }">
            <MassEditDatas :servicosIds="selectedServicos.map(el=> el.id)" @refresh="$emit('refresh')"
                           @closeSidebar="editarDatasIsOpen=false"/>
        </Dialog>

        <Dialog v-if="editarHorasIsOpen" v-model:visible="editarHorasIsOpen" modal header="Editar Horas"
                :style="{ width: '50%' }">
            <MassEditHoras :servicosIds="selectedServicos.map(el=> el.id)" @refresh="$emit('refresh')"
                           @closeSidebar="editarHorasIsOpen=false"/>
        </Dialog>

        <Dialog v-if="editarPrecosIsOpen" v-model:visible="editarPrecosIsOpen" modal header="Editar Preços"
                :style="{ width: '50%' }">
            <MassEditPrecos :servicosIds="selectedServicos.map(el=> el.id)" @refresh="$emit('refresh')"
                            @closeSidebar="editarPrecosIsOpen=false"/>
        </Dialog>

        <Dialog v-if="editarLotacaoIsOpen" v-model:visible="editarLotacaoIsOpen" modal header="Editar Lotação"
                :style="{ width: '50%' }">
            <MassEditLotacao :servicosIds="selectedServicos.map(el=> el.id)" @refresh="$emit('refresh')"
                             @closeSidebar="editarLotacaoIsOpen=false"/>
        </Dialog>

    </div>
</template>

<script>
import MassEditDatas from "@/Pages/Servicos/Components/MassEdit/MassEditDatas.vue";
import MassEditLocalidades from "@/Pages/Servicos/Components/MassEdit/MassEditLocalidades.vue";
import MassEditHoras from "@/Pages/Servicos/Components/MassEdit/MassEditHoras.vue";
import MassEditPrecos from "@/Pages/Servicos/Components/MassEdit/MassEditPrecos.vue";
import MassEditLotacao from "@/Pages/Servicos/Components/MassEdit/MassEditLotacao.vue";

export default {
    components: {MassEditLotacao, MassEditPrecos, MassEditHoras, MassEditLocalidades, MassEditDatas},
    props: {
        selectedServicos: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            editarLocalidadesIsOpen: false,
            editarDatasIsOpen: false,
            editarHorasIsOpen: false,
            editarPrecosIsOpen: false,
            editarLotacaoIsOpen: false,
            itemsMenuOpcoes: [
                {
                    label: 'Imprimir Guias',
                    icon: 'pi pi-print',
                    command: () => window.open('/servicos/print-multiple-guias-transporte?servico_ids=' + this.selectedServicos.map(el => el.id).join(',')),
                },
                {
                    label: 'Editar Localidades',
                    icon: 'pi pi-pencil',
                    command: () => this.editarLocalidadesIsOpen = true
                },
                {
                    label: 'Editar datas',
                    icon: 'pi pi-calendar',
                    command: () => this.editarDatasIsOpen = true
                },
                {
                    label: 'Editar horas',
                    icon: 'pi pi-clock',
                    command: () => this.editarHorasIsOpen = true
                },
                {
                    label: 'Editar preços',
                    icon: 'pi pi-euro',
                    command: () => this.editarPrecosIsOpen = true
                },
                {
                    label: 'Editar lotação',
                    icon: 'pi pi-user',
                    command: () => this.editarLotacaoIsOpen = true
                }

            ],
        }
    },
    methods: {
        toggleMenuOpcoes(event) {
            this.$refs.menuOpcoes.toggle(event);
        },
    },
}
</script>
