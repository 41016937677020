<template>
    <div>

        <WSkeleton v-if="loading"/>
        <DataTable v-if="data"
                   v-model:filters="filtersDT"
                   v-model:selection="resourceSelected"
                   selectionMode="multiple"
                   dataKey="id"
                   :metaKeySelection="false"
                   :first="0"
                   :lazy="true"
                   :loading="loading"
                   :paginator="true"
                   :rows="10"
                   :sortOrder="1"
                   :totalRecords="data.total"
                   :value="data.data"
                   filterDisplay="row"
                   sortField="name"
                   @filter="onFilter($event)"
                   @page="onPage($event)"
                   @sort="onSort($event)"
                   @rowSelect="addMotorista($event)"
                   @rowUnselect="removeMotorista($event)"

        >


            <Column field="codigo" header="Código" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.codigo }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por código.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="nome" header="Nome" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.nome }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por nome.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="zona" header="Zona" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.zona?.nome }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Select v-model="filterModel.value" :options="zonas" class="p-column-filter" optionLabel="nome"
                            optionValue="id" placeholder="Procurar por zona.." @change="filterCallback()">

                    </Select>

                </template>
            </Column>


            <Column field="disponivel" header="Disponivel" style="min-width: 12rem">
                <template #body="slotProps">

                    <div>
                        <font-awesome-icon v-if="this.filtersDT.disponivel.value==='Disponivel'" class="text-green-500"
                                           icon="fa-solid fa-check"/>
                        <font-awesome-icon v-if="this.filtersDT.disponivel.value==='Indisponivel'" class="text-red-500"
                                           icon="fa-solid fa-xmark"/>
                    </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Select v-model="filterModel.value" :options="['Disponivel','Indisponivel']"
                            class="p-column-filter"
                            @change="filterCallback()">

                    </Select>

                </template>
            </Column>


            <template #empty>
                <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum motorista encontrado"/>
            </template>
        </DataTable>


        <WCard titulo="Motoristas Selecionados">
            <template #content>
                <div class="grid grid-cols-2 sm:grid-cols-1">
                    <ChooseMotoristasHorario v-if="servico" ref="ChooseMotoristasHorario" :servico="servico"
                                             :motoristas="resourceSelected"/>
                    <div>
                        <ServicoItinerario ref="itinerario_ida" tipoViagem="IDA" :servico-id="servico_id"
                                           v-if="servico && !servico.is_servico_regresso"
                                           :editable="false"
                        />

                        <ServicoItinerario ref="itinerario_regresso" tipoViagem="REGRESSO" :servico-id="servico_id"
                                           :editable="false" v-if="servico && servico.is_servico_regresso"/>
                    </div>
                </div>
            </template>
        </WCard>

        <div class="flex justify-end mt-10 bg-gray-100 py-2">
            <Button class="ml-4"
                    label="Guardar" @click="handleSubmit()">
            </Button>
        </div>


    </div>
</template>


<script>


import WDataTable from "@/Mixins/WDataTable.js";
import {FilterMatchMode} from '@primevue/core/api';
import Motorista from "@/Models/Motorista.js";
import ZonasMixin from "@/Mixins/ZonasMixin.js";
import ChooseMotoristasHorario from "@/Pages/Servicos/Servicos/components/ChooseMotoristasHorario.vue";
import ServicoItinerario from "@/Pages/Servicos/Servicos/components/Edit/ServicoItinerario.vue";

export default {
    mixins: [WDataTable, ZonasMixin],
    components: {ServicoItinerario, ChooseMotoristasHorario},
    name: 'ChooseMotoristas',
    props: {
        dt_partida_servico: {
            type: String
        },
        dt_regresso_servico: {
            type: String
        },
        servico_id: {
            type: Number
        },
        motoristasChosen: {
            type: Array
        }
    },

    data() {
        return {
            servico: null,
            resourceSelected: null,
            motoristas: null,
            filtersDT: {
                codigo: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                nome: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                zona: {value: null, matchMode: FilterMatchMode.EQUALS},
                disponivel: {value: 'Disponivel', matchMode: FilterMatchMode.EQUALS},
            },
            filters: {
                search: '',
                date: [null, null],
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'codigo',
                        sortOrder: 1,
                    },
                },
            },
        }
    },


    mounted() {
        this.fetchServico()
        this.fetchZonas()
        this.fetchData()
        this.setResource()
    },

    methods: {
        setResource() {
            if (this.motoristasChosen) {
                this.resourceSelected = this.motoristasChosen
            }
        },
        onFilter() {
            this.filters.dt.page = 0
            this.fetchData()
        },
        async fetchData() {

            this.loading = true;
            this.data = await Motorista.where('filter', this.filters.search)
                .params({
                    dt_partida: this.dt_partida_servico,
                    dt_regresso: this.dt_regresso_servico,
                    disponivel: this.filtersDT.disponivel.value,
                    nome: this.filtersDT.nome.value,
                    zona_id: this.filtersDT.zona.value,
                    codigo: this.filtersDT.codigo.value,
                    not_in_servico_id: this.servico_id,
                    servico_estado: ['pronto', 'confirmado'],
                })
                .page(this.filters.dt.page)
                .limit(10)
                .orderBy(this.sortField)
                .get()
            this.loading = false;
        },

        closeSidebar() {
            this.autocarro.reset();
        },

        handleSubmit() {
            this.$emit('updateMotoristas', this.$refs.ChooseMotoristasHorario.motoristas);
        },
        fetchServico() {
            let params = {
                id: this.servico_id
            }
            axios.get(route('web-api.servicos.index', params)).then((res) => {
                this.servico = res.data.data[0]
            })
        },
        addMotorista(event) {
            this.$refs.ChooseMotoristasHorario.addMotorista(event.data)
        },
        removeMotorista(event) {
            this.$refs.ChooseMotoristasHorario.removeMotorista(event.data)
        }

    }

}
</script>
