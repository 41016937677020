<template>
    <div>

        <table v-if="itinerarios && itinerarios.length" class="min-w-full divide-y divide-gray-300">
            <thead>
            <tr>
                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0" scope="col">Data</th>
                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">OR->DEST</th>
                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">Pax</th>
                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">Kms</th>
                <th v-if="canEdit" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col"></th>
                <th v-if="canEdit" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col"></th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
            <tr v-for="(ite,index) in itinerarios" :key="index">
                <td v-if="!isFromServicoRegular"
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <div class="flex">
                    <span v-if="dtIsSameDay(ite.dt_partida,dtFirst)">  {{
                            dayjs(ite.dt_partida).format('HH:mm')
                        }}</span>
                        <span v-else>{{ dtToFront(ite.dt_partida) }}</span>
                    </div>

                    <div class="flex">
                    <span v-if="dtIsSameDay(ite.dt_chegada,dtFirst)">  {{
                            dayjs(ite.dt_chegada).format('HH:mm')
                        }}</span>
                        <span v-else>{{ dtToFront(ite.dt_chegada) }}</span>
                    </div>
                </td>
                <td v-if="isFromServicoRegular"
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <div class="flex">
                        <span>{{ getDtPartidaSR(ite, index) }}</span>
                    </div>

                    <div class="flex">

                        <span>{{ getDtChegadaSR(ite, index) }}</span>
                    </div>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    {{ ite.localidade_partida.description }} -> {{ ite.localidade_chegada.description }}
                    <p class="text-xs text-muted"> {{ ite.localidade_partida.secondary_text }} ->
                        {{ ite.localidade_chegada.secondary_text }}</p>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ite.pax }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ ite.kms }}</td>
                <td v-if="canEdit" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    <Button class="p-button-outlined" severity="warning" @click="editItinerario(ite,index)">
                        <font-awesome-icon icon="fa-solid fa-edit"/>
                    </Button>
                </td>
                <td v-if="canEdit" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">


                    <Button :disabled="index==0" class="p-button-outlined" severity="warning"
                            @click="editOrder(ite,index,'up')">
                        <font-awesome-icon icon="fa-solid fa-arrow-up"/>
                    </Button>


                    <Button :disabled="index+1===itinerarios.length" class="p-button-outlined" severity="warning"
                            @click="editOrder(ite,index,'down')">
                        <font-awesome-icon icon="fa-solid fa-arrow-down"/>
                    </Button>
                </td>
            </tr>
            </tbody>
        </table>


    </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'ItinerarioDisplay',
    components: {FontAwesomeIcon},
    props: {
        itinerarios: {
            type: Object,
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        isFromServicoRegular: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        dtFirst() {
            return this.itinerarios[0].dt;
        }
    },

    methods: {
        getDtPartidaSR(ite, indexIte) {

            if (this.isFromServicoRegular) {
                if (this.itinerarios[indexIte].hora_partida_dia > 0) {
                    return (this.itinerarios[indexIte].hora_partida_dia + 1) + 'ºdia - ' + ite.hora_partida
                }

            }
            return ite.hora_partida
        },

        getDtChegadaSR(ite, indexIte) {
            if (this.isFromServicoRegular) {
                if (this.itinerarios[indexIte].duracao_dias > 0 || this.itinerarios[indexIte].hora_partida_dia > 0) {
                    return (this.itinerarios[indexIte].hora_partida_dia + 1 + this.itinerarios[indexIte].duracao_dias) + 'ºdia - ' + ite.hora_chegada
                }
                return ite.hora_chegada
            }
            return ite.hora_chegada
        },
        editItinerario(itinerario, index) {
            this.$emit('edit-itinerario', itinerario, index);
        },
        editOrder(itinerario, index, direction) {
            let payload = {
                itinerario_id: itinerario.id,
                direction: direction
            }
            axios.patch(route('web-api.servicos.itinerarios.update-ordem', this.itinerarios[index].servico_id), payload)
                .then(response => {
                    this.$emit('update-itinerario');
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
}
</script>
