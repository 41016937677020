<template>
    <Dialog v-model:visible="modalServicoEditIsOpen"
            :modal="true"
            :style="{width: '90vw'}"
            :header="getTituloModal"
            @hide="closeModal()"

    >

        <div v-if="servico">
      <ServicoInternacionalEdit v-if="servico.tipo_servico==='Internacional'" @closeModal="closeModal"/>
        <ServicoEdit v-if="servico.tipo_servico==='Ocasional' || servico.tipo_servico==='Regular'" @closeModal="closeModal"/>
        </div>
    </Dialog>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import ServicoInternacionalEdit from "@/Pages/Servicos/Internacionais/Edit.vue";
import ServicoEdit from "@/Pages/Servicos/Servicos/Edit.vue";
import {useServicoStore} from "@/Store/ServicoStore.js";
export default {
    name:'GlobalModalServicoEdit',
    components: {
        ServicoInternacionalEdit,
        ServicoEdit
    },
    computed: {
        ...mapState(useServicoStore, ['servico']),
        ...mapWritableState(useGlobalModalsStore, ['modalServicoEditIsOpen','modalServicoIdSelected']),
        getTituloModal(){
            if(!this.servico){
                return ''
            }
            if(this.servico.tipo_servico ==='Ocasional'){
                return 'Serviço Ocasional: ' + this.servico.codigo
            }

            if(this.servico.tipo_servico ==='Internacional'){
                return 'Serviço Internacional: ' + this.servico.codigo
            }
            if(this.servico.tipo_servico === 'Regular'){
                return 'Serviço Regular: ' + this.servico.codigo + ' - ' + this.servico.servico_regular.nome + ' - ' + this.servico.turno.nome
            }
        },
    },
    watch: {
        modalServicoIdSelected(newValue) {
            if (newValue) {
                this.fetchServico(this.modalServicoIdSelected);
            }
        }
    },
    methods: {
        ...mapActions(useServicoStore, ['fetchServico']),
        closeModal() {
            this.modalServicoEditIsOpen = false
        },
    },
}
</script>

