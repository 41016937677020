<template>
    <div>

        <div @click="toggleEdit(!isEditing)">
            <span v-if="preco">{{ preco }} €</span>
            <span v-else class="text-red-500"> €</span>
        </div>

        <Dialog v-model:visible="isEditing" modal :style="{ width: '25rem' }">

            <span class="p-text-secondary block mb-5">{{ paragem.localidade.name }} -> {{
                    paragemPara.localidade.name
                }}</span>
            <div class="flex align-items-center gap-3 mb-3">
                <label for="username" class="font-semibold w-6rem">Preço</label>
                <InputNumber autofocus ref="inputPreco" v-model="preco" mode="currency" currency="EUR" locale="pt-PT"
                             class="w-1"/>
            </div>

            <template #footer>
                <Button label="Cancelar" text severity="secondary" @click="cancel"/>
                <Button label="Guardar" outlined @click="handleSubmit"/>
            </template>
        </Dialog>
    </div>
</template>

<script>
import InputNumber from 'primevue/inputnumber';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import {mapActions, mapWritableState} from "pinia";
import {useParagemPrecoStore} from "@/Pages/Paragens/Store/ParagemPrecoStore.js";
import Dialog from 'primevue/dialog';

export default {
    emits: ['updateParagemPreco'],
    components: {
        InputNumber,
        InputGroup,
        InputGroupAddon,
        Dialog
    },
    props: {
        relatedToId: {
            type: Number,
            required: true
        },
        relatedToType: {
            type: String,
            required: true
        },
        paragem: {
            type: Object,
        },
        paragemPara: {
            type: Object,
        },
    },

    data() {
        return {
            isEditing: false,
            preco: null,
        }
    },
    mounted() {
        this.getPreco();
    },
    computed: {
        ...mapWritableState(useParagemPrecoStore, ['paragensPrecos']),
    },
    methods: {
        ...mapActions(useParagemPrecoStore, ['updateParagemPreco']),
        cancel() {
            this.getPreco()
            this.isEditing = false
        },
        toggleEdit(value) {
            this.isEditing = value
            if (this.$refs.inputPreco) {
                this.$refs.inputPreco.focus();
            }
        },
        getPreco() {
            let paragemPreco = this.paragensPrecos.find(paragemPreco => paragemPreco.paragem_id == this.paragem.id && paragemPreco.paragem_id_para == this.paragemPara.id)?.preco

            if (paragemPreco) {
                this.preco = paragemPreco
            } else {
                this.preco = null
            }
        },
        handleSubmit() {
            if (this.preco === null) {
                this.preco = 0
            }
            this.updateParagemPreco(this.relatedToId, this.relatedToType, this.paragem.id, this.paragemPara.id, this.preco)
            this.isEditing = false
        }
    },

}
</script>

