<template>
    <div>
        <Button type="button" icon="pi pi-ellipsis-v" severity="secondary" data-servico="12" text
                @click="toggleMenuOpcoes" aria-haspopup="true" aria-controls="overlay_menu"/>
        <MenuPrime ref="menuOpcoes" id="overlay_menu" :model="itemsMenuOpcoes" :popup="true"/>

        <Dialog v-if="delegarSubContratanteIsOpen" v-model:visible="delegarSubContratanteIsOpen" modal
                header="Delegar Serviço para subContratante"
                :style="{ width: '50%' }">
            <DelegarServicoASubContratante :servicoId="servico.id" @refresh="$emit('refresh')"
                                           @closeSidebar="delegarSubContratanteIsOpen=false"/>
        </Dialog>

    </div>
</template>

<script>
import {mapActions} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import MassEditLocalidades from "@/Pages/Servicos/Components/MassEdit/MassEditLocalidades.vue";
import DelegarServicoASubContratante
    from "@/Pages/Servicos/Servicos/components/Edit/SubContratantes/DelegarServicoASubContratante.vue";

export default {
    components: {MassEditLocalidades, DelegarServicoASubContratante},
    props: {
        servico: {
            type: Object,
            default: 0
        },
    },
    data() {
        return {
            delegarSubContratanteIsOpen: false,
            itemsMenuOpcoes: [
                {
                    label: 'Copiar',
                    icon: 'pi pi-copy',
                    command: () => this.duplicarServico()
                },
                {
                    label: 'Eliminar',
                    icon: 'pi pi-trash',
                    command: () => this.deleteServico()
                },

            ]
        }
    },
    mounted() {
        if (this.servico.subcontratante_id) {
            this.itemsMenuOpcoes.push({
                label: 'Remover Subcontratante',
                icon: 'pi pi-times',
                command: () => this.removerSubContratanteServico()
            })
        } else {
            this.itemsMenuOpcoes.push({
                label: 'Delegar para subContratante',
                icon: 'pi pi-arrow-right',
                command: () => this.delegarSubContratanteServico()
            },)
        }


    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),
        removerSubContratanteServico() {
            if (!confirm('Tem a certeza que deseja remover o subcontratante?')) return
            axios.patch(route('web-api.servicos.subcontratantes.remover', this.servico.id)).then((res => {
                this.openModalServicoEdit(this.servico.id)
                this.$emit('refresh')
                this.$toast.add({
                    icon: 'pi pi-check',
                    severity: 'success',
                    summary: 'Subcontratante removido',
                    life: 3000
                });
            }))
        },
        delegarSubContratanteServico() {
            this.delegarSubContratanteIsOpen = true
        },
        duplicarServico() {
            if (!confirm('Tem a certeza que deseja duplicar este serviço?')) return

            axios.post(route('web-api.servicos.duplicar', this.servico.id))
                .then(response => {
                    this.openModalServicoEdit(response.data.id)
                    this.$emit('refresh')
                    this.$toast.add({
                        icon: 'pi pi-check',
                        severity: 'success',
                        summary: 'Serviço duplicado',
                        life: 3000
                    });
                })
                .catch(error => this.$swal.fire('Erro ao copiar o serviço', '', 'error'))
        },
        deleteServico() {

            if (!confirm('Tem a certeza que deseja eliminar? ESTA AÇÃO É IRREVERSÍVEL!')) {
                return false;
            }

            this.$inertia.delete(route("servicos.destroy", this.servico.id),
                {
                    onSuccess: () => {

                        this.$emit('closeModalServico')
                        this.$emit('refresh')
                        this.$swal('Serviço eliminado',
                            'O Serviço foi eliminado com sucesso!',
                            'success'
                        );
                    },
                }
            );
        },
        toggleMenuOpcoes(event) {
            this.$refs.menuOpcoes.toggle(event);
        },
    },
}
</script>

