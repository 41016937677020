<template>
  <div>
    <SidebarXS
      :navigation="navigation"
      :sidebarOpen="sidebarOpen"
      @closeSidebar="$emit('closeSidebar')"
    />
    <!-- Static sidebar for desktop -->
    <div
      :class="[isMouseEnter ? 'sidebar-menu-open' : 'sidebar-menu-closed']"
      class="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0 sidebar"
      @mouseleave="isMouseEnter = false"
      @mouseover="isMouseEnter = true"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex flex-col flex-grow pt-2 pb-4 overflow-y-auto"
        style="background-color: #1e293b"
      >
        <div
          v-if="isMouseEnter"
          class="flex items-center justify-between w-full px-4"
        >
          <div>
            <img
              alt="Winvel logo"
              class="h-8 w-auto"
              src="/logo.png"
              style="-webkit-filter: invert(1); filter: invert(1)"
            />
            <p class="text-white ml-2">BARQUENSE</p>
          </div>
          <div></div>
        </div>

        <div v-if="!isMouseEnter" class="flex w-full justify-end">
          <span class="text-xl text-white font-bold" style="margin-right: 33px"
            >W</span
          >
        </div>
        <nav
          aria-label="Sidebar"
          class="mt-5 flex-1 flex flex-col divide-y divide-gray-800 overflow-y-auto"
        >
          <div class="px-2 space-y-1">
            <SidebarNavigation :navigation="navigationTop" />

            <div>
              <div
                class="text-xs font-semibold leading-24 text-gray-400 mt-6 ml-2"
              >
                CONTRATUAL
              </div>
            </div>

            <SidebarNavigation :navigation="navigationContratual" />

            <div>
              <div
                class="text-xs font-semibold leading-12 text-gray-400 mt-6 ml-2"
              >
                INTERNACIONAL
              </div>
            </div>

            <SidebarNavigation :navigation="navigationInternacional" />

            <div>
              <div
                class="text-xs font-semibold leading-12 text-gray-400 mt-6 ml-2"
              >
                GERAL
              </div>
            </div>

            <SidebarNavigation :navigation="navigation" />
          </div>

          <div class="mt-auto">
            <SidebarNavigation :navigation="secondaryNavigation" />
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarXS from './SidebarXS.vue';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  ChartBarIcon,
  ChatIcon,
  ClipboardListIcon,
  CubeTransparentIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  EyeIcon,
  HomeIcon,
  MenuAlt1Icon,
  OfficeBuildingIcon,
  PaperAirplaneIcon,
  RefreshIcon,
  TicketIcon,
  TruckIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  XIcon,
  BookOpenIcon,
  PencilIcon,
  CurrencyEuroIcon,
  ClipboardIcon,
} from '@heroicons/vue/outline';
import {
  ChevronDownIcon,
  CogIcon,
  DocumentIcon,
  SearchIcon,
} from '@heroicons/vue/solid';
import SidebarNavigation from '@/Layouts/Components/SidebarNavigation.vue';

export default {
  props: {
    sidebarOpen: Boolean,
  },
  components: {
    SidebarNavigation,
    SidebarXS,
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt1Icon,
    XIcon,
    SearchIcon,
    BellIcon,
    ChatIcon,
    ChevronDownIcon,
    ExclamationIcon,
    CubeTransparentIcon,
    ChartBarIcon,
    RefreshIcon,
    ClipboardListIcon,
    ExclamationCircleIcon,
    CogIcon,
    UserCircleIcon,
    TruckIcon,
    UsersIcon,
    UserGroupIcon,
    PaperAirplaneIcon,
    EyeIcon,
    TicketIcon,
    BookOpenIcon,
    PencilIcon,
    CurrencyEuroIcon,
    ClipboardIcon,
  },

  data() {
    return {
      navigationTop: [
        {
          name: 'Dashboard',
          href: route('dashboard.index'),
          icon: HomeIcon,
          route: 'dashboard',
          childs: false,
          pode: true,
        },
      ],

      navigationContratual: [
        {
          name: 'Ocasionais',
          href: route('contratos.index'),
          icon: ClipboardIcon,
          route: 'contratos',
          childs: false,
          pode: true,
        },
        {
          name: 'Regulares',
          href: route('contratos.regulares.index'),
          icon: ClipboardListIcon,
          route: 'contratos/regulares',
          childs: false,
          pode: true,
        },
      ],
      navigation: [
        {
          name: 'Colaboradores',
          href: route('colaboradores.index'),
          icon: UserGroupIcon,
          route: 'colaboradores',
          pode: true,
          childs: [
            {
              name: 'Todos',
              href: route('colaboradores.index'),
              route: 'colaboradores',
              icon: UserGroupIcon,
              pode: true,
            },
            {
              name: 'Motoristas',
              href: route('motoristas.index'),
              icon: UserGroupIcon,
              route: 'motoristas',
              childs: false,
              pode: true,
            },
          ],
        },

        {
          name: 'Autocarros',
          href: route('autocarros.index'),
          icon: TruckIcon,
          route: 'autocarros',
          childs: false,
          pode: true,
        },

        {
          name: 'Clientes',
          href: route('clientes.index'),
          icon: OfficeBuildingIcon,
          route: 'clientes',
          childs: false,
          pode: true,
        },
        {
          name: 'Subcontratantes',
          href: route('subcontratantes.index'),
          icon: OfficeBuildingIcon,
          route: 'subcontratantes',
          childs: false,
          pode: true,
        },
        {
          name: 'Orçamentos',
          href: route('orcamentos.index'),
          icon: CurrencyEuroIcon,
          route: 'servicos',

          pode: true,
          childs: [
            {
              name: 'Orçamentos',
              href: route('orcamentos.index'),
              route: 'orcamentos',
              icon: CurrencyEuroIcon,
              pode: true,
            },
            {
              name: 'Pedidos',
              href: route('orcamentos.pedidos.index'),
              route: 'orcamentos.pedidos',
              icon: CurrencyEuroIcon,
              pode: true,
            },
          ],
        },

        {
          name: 'Atividade',
          href: route('regati.index'),
          icon: EyeIcon,
          route: 'regati',
          childs: false,
          pode: true,
        },
      ],

      navigationInternacional: [
        {
          name: 'Reservas',
          href: route('reservas.index'),
          icon: BookOpenIcon,
          route: 'reservas',
          childs: false,
          pode: true,
        },
        {
          name: 'Bilhetes',
          href: route('bilhetes.index'),
          icon: TicketIcon,
          route: 'bilhetes',
          childs: false,
          pode: true,
        },
        {
          name: 'Serviços',
          href: route('servicos-internacionais.index'),
          icon: PaperAirplaneIcon,
          route: 'servicos-internacionais',
          childs: false,
          pode: true,
        },
      ],
      secondaryNavigation: [
        {
          name: 'Faturas',
          href: route('faturas.index'),
          icon: DocumentIcon,
          route: 'faturas',
          childs: false,
          pode: this.canVerFaturas(),
        },
        {
          name: 'Tickets',
          href: route('tickets.index'),
          icon: TicketIcon,
          route: 'tickets',
          childs: false,
          pode: true,
        },
        {
          name: 'Configurações',
          href: route('configuracoes.index'),
          icon: CogIcon,
          route: 'configuracoes',
          childs: false,
          pode: true,
        },
      ],
      isMouseEnter: false,
    };
  },
  methods: {
    canVerFaturas() {
      return (
        this.$page.props.auth.user.email === 'winvelapps@gmail.com' ||
        this.$page.props.auth.user.id === this.$page.props.contabilista_id
      );
    },
    isUrl(...urls) {
      // Get the current URL path excluding the leading slash
      const currentUrl = this.$page.url.substr(1);
      console.log(currentUrl);

      // Check if the current URL exactly matches any of the provided URLs
      return urls.some((url) => currentUrl === url);
    },
  },
};
</script>
