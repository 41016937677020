<template>
    <div>


        <InputText type="date" v-model="dt_partida" @input="fetchData" class="w-full" placeholder="Data"/>

        <DataTable v-if="data"
                   :first="0"
                   :lazy="true"
                   :loading="loading"
                   :paginator="false"
                   :rows="10"
                   :sortOrder="1"
                   :value="data"
                   currentPageReportTemplate="A mostrar do {first} até {last} de um total de {totalRecords}"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   selectionMode="single"
                   sortField="dt_partida"
                   @page="onPage($event)"
                   @rowSelect="selectResource($event.data)"
                   @sort="onSort($event)"

        >

            <Column :sortable="true" field="id" header="ID" style="min-width: 12rem"></Column>
            <Column :sortable="true" field="dt_partida" header="Data">
                <template #body="{ data }">
                    {{ data.dt_partida }}
                </template>
            </Column>

            <Column field="localidade_partida" header="Local partida" style="min-width: 12rem"></Column>


            <Column field="localidade_chegada" header="Local chegada" style="min-width: 12rem"></Column>



            <Column header="Estado">
                <template #body="slotProps">
                    <WBadge :color="slotProps.data.estado_cor">{{
                            slotProps.data.estado
                        }}
                    </WBadge>
                </template>
            </Column>


            <template #empty>
                <WEmptyState icon="fa-solid fa-inbox"
                             titulo="Nenhum serviço encontrado"/>
            </template>
        </DataTable>
    </div>
</template>

<script>
import BilheteEstadoTag from "@/Pages/Bilhetes/Components/BilheteEstadoTag.vue";
import WDataTable from "@/Mixins/WDataTable.js";
import Servico from "@/Models/Servico.js";

export default {
    name: 'SelecionarServico',
    components: {BilheteEstadoTag},
    mixins: [WDataTable],
    props: {
        origemLocalidadeId: {
            type: Number,
        },
        destinoLocalidadeId: {
            type: Number,
        },
        tipoServico: {
            type: String,
            default: 'INTERNACIONAL',
        },
    },
    data() {
        return {
            resourceSelected: null,
            resourceShowIsOpen:false,
            resourceRoute: 'bilhetes',
            dt_partida: null,
            filters: {
                search: '',
                date: [null, null],
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'id',
                        sortOrder: -1,
                    },
                },
            },
        }
    },
    methods: {
        selectResource(resource){
            this.$emit('resourceSelected',resource)
        },
        async fetchData() {
            this.loading = true;
            let params = {
                origem_localidade_id: this.origemLocalidadeId,
                destino_localidade_id: this.destinoLocalidadeId,
                data_partida:this.dt_partida,
            }
            axios.get(route('web-api.reservas.create.servicos-por-localidades'), {params})
                .then(response => this.data = response.data)
                .catch(error => console.log('error', error))
                .then(() => this.loaded = true)
            this.loading = false;
        },

    }
}
</script>
