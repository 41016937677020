<template>
    <div>
        <button aria-controls="disclosure-1" aria-expanded="false"
                class="group flex items-center font-medium text-gray-700" type="button"
                @click="openModalServicoNotas(servico.id)">
            <font-awesome-icon class="mr-2" icon="fa-regular fa-note-sticky"/>
            {{ totalNotas }} Notas
        </button>

        <Drawer v-model:visible
                    ="modalServicoNotasIsOpen" class="lg:!w-[60rem]" position="right"
                @hide="closeSidebar()">

            <WEmptyState v-if="!notas || !notas.length" titulo="Nenhuma nota">
                <Button class=" p-button-outlined" icon="fa-solid fa-plus" label="Adicionar Nota"
                        @click="modalServicoNotasCreateIsOpen = true">

                </Button>
            </WEmptyState>

            <Button v-if="notas && notas.length" class=" p-button-outlined" icon="fa-solid fa-plus"
                    label="Adicionar Nota"
                    @click="modalServicoNotasCreateIsOpen = true">

            </Button>

            <ul class="space-y-6" role="list">
                <li v-for="(nota,indexNotas) in notas" :key="indexNotas" class="relative flex gap-x-4">
                    <div class="absolute left-0 top-0 flex w-6 justify-center -bottom-6">
                        <div class="w-px bg-gray-200"></div>
                    </div>
                    <img
                        :src="nota.creator.avatar"
                        alt="" class="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50">
                    <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                        <div class="flex justify-between gap-x-4">
                            <div class="py-0.5 text-xs leading-5 text-gray-500"><span
                                class="font-medium text-gray-900">{{ nota.creator.name }}</span>
                            </div>
                            <time class="flex-none py-0.5 text-xs leading-5 text-gray-500"
                                  datetime="2023-01-23T15:56">{{ timeAgo(nota.created_at) }}
                            </time>
                        </div>
                        <p class="text-sm leading-6 text-gray-500" v-html="nota.descricao"></p>
                    </div>
                </li>
            </ul>
        </Drawer>

        <Drawer v-model:visible
                    ="modalServicoNotasCreateIsOpen" class="lg:!w-[60rem]" position="right"
                @hide="closeSidebar()">
            <NotasCreateEdit :notable_id="servico.id" notable_type="SERVICO" @closeSidebar="closeSidebar()"/>
        </Drawer>

    </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {useServicoNotasStore} from "@/Pages/Servicos/Servicos/Store/ServicoNotasStore.js";
import {useServicoStore} from "@/Store/ServicoStore.js";
import NotasCreateEdit from "@/Pages/Notas/components/CreateEdit.vue";

export default {
    components: {
        NotasCreateEdit,
    },
    data() {
        return {
            modalServicoNotasCreateIsOpen: false
        }
    },
    computed: {
        ...mapWritableState(useServicoNotasStore, ['modalServicoNotasIsOpen']),
        ...mapState(useServicoNotasStore, ['notas', 'totalNotas']),
        ...mapState(useServicoStore, ['servico']),
    },

    mounted() {
        this.fetchServicoNotas(this.servico.id);
    },

    methods: {
        closeSidebar() {
            this.fetchServicoNotas(this.servico.id);
            this.modalServicoNotasCreateIsOpen = false;
        },
        ...mapActions(useServicoNotasStore, ['fetchServicoNotas', 'openModalServicoNotas', 'closeModalServicoNotas']),

    },
}
</script>


