import {defineStore} from 'pinia'
import {useServicoStore} from "@/Store/ServicoStore.js";


export const useGlobalModalsStore = defineStore('global_modals', {
    state: () => {
        return {
            tipoServico: null,
            contrato_id: null,
            orcamento_id: null,
            modalServicoCreateIsOpen: false,
            modalServicoEditIsOpen: false,
            modalServicoIdSelected: null,
        }
    },
    getters: {},
    actions: {
        openModalServicoEdit(servico_id) {
            this.modalServicoEditIsOpen = true
            this.modalServicoIdSelected = servico_id
        },
        openModalServicoCreate(tipo, contrato_id = null, orcamento_id = null) {
            this.tipoServico = tipo
            this.contrato_id = contrato_id
            this.orcamento_id = orcamento_id
            this.modalServicoCreateIsOpen = true
        },
        closeModalServicoEdit() {
            this.modalServicoEditIsOpen = false
            this.modalServicoIdSelected = null
            useServicoStore().servico = null
        },

        globalModalStoreRefreshServicos() {

        },

    },
})
