<template>
    <div>

        <WSkeleton v-if="loading"/>
        <DataTable v-if="data"
                   v-model:filters="filtersDT"
                   v-model:selection="resourceSelected"
                   :first="0"
                   :lazy="true"
                   :loading="loading"
                   :paginator="true"
                   :rows="10"
                   :sortOrder="1"
                   :totalRecords="data.total"
                   :value="data.data"
                   filterDisplay="row"
                   selectionMode="single"
                   sortField="name"
                   @filter="onFilter($event)"
                   @page="onPage($event)"
                   @sort="onSort($event)"

        >

            <Column field="codigo" header="Código" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.codigo }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por código.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="matricula" header="Matricula" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.matricula }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter"
                               placeholder="Procurar por matricula.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="lotacao" header="Lotação" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.lotacao }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Lotação"
                               type="number"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="marca" header="Marca" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.marca }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por marca.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="modelo" header="Modelo" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.modelo }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por modelo.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="disponivel" header="Disponivel" style="min-width: 12rem">
                <template #body="slotProps">

                    <div>
                        <font-awesome-icon v-if="this.filtersDT.disponivel.value==='Disponivel'" class="text-green-500"
                                           icon="fa-solid fa-check"/>
                        <font-awesome-icon v-if="this.filtersDT.disponivel.value==='Indisponivel'" class="text-red-500"
                                           icon="fa-solid fa-xmark"/>
                    </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Select v-model="filterModel.value" :options="['Disponivel','Indisponivel']"
                            class="p-column-filter"
                            @change="filterCallback()">

                    </Select>

                </template>
            </Column>
            <Column header="Último Serviço" style="min-width: 12rem">
                <template #body="slotProps">
                    <div v-if="slotProps.data.ultimo_servico" class="text-sm text-muted">
                        {{ dtToFront(slotProps.data.ultimo_servico.dt_partida) }} -
                        {{ dtToFront(slotProps.data.ultimo_servico.dt_regresso) }}
                        <p class="text-xs text-muted">
                            <font-awesome-icon icon="fa-regular fa-clock"/>
                            {{ dtDiff(this.dt_regresso_servico, slotProps.data.ultimo_servico.dt_partida) }}h |
                            <span v-if="slotProps.data.ultimo_servico.localChegada">
                                  <font-awesome-icon
                                      icon="fa-solid fa-location-dot"/>  {{
                                    slotProps.data.ultimo_servico.localidade_chegada.name
                                }}
                            </span>

                        </p>
                    </div>
                </template>
            </Column>

            <Column header="Próximo Serviço" style="min-width: 12rem">
                <template #body="slotProps">
                    <div v-if="slotProps.data.proximo_servico" class="text-sm text-muted">
                        {{ dtToFront(slotProps.data.proximo_servico.dt_partida) }} -
                        {{ dtToFront(slotProps.data.proximo_servico.dt_regresso) }}
                        <p class="text-xs text-muted">
                            <font-awesome-icon icon="fa-regular fa-clock"/>
                            {{ dtDiff(this.dt_regresso_servico, slotProps.data.proximo_servico.dt_partida) }}h |
                            <span v-if="slotProps.data.proximo_servico.localChegada">
                                  <font-awesome-icon
                                      icon="fa-solid fa-location-dot"/>  {{
                                    slotProps.data.proximo_servico.localidade_chegada.localidade_nome
                                }}
                            </span>

                        </p>
                    </div>
                </template>
            </Column>


            <template #empty>
                <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum autocarro encontrado"/>
            </template>
        </DataTable>
    </div>
</template>


<script>


import WDataTable from "@/Mixins/WDataTable.js";
import Autocarro from "@/Models/Autocarro.js";
import {FilterMatchMode} from '@primevue/core/api';

export default {
    mixins: [WDataTable],
    components: {},
    name: 'Autocarro',
    props: {
        dt_partida_servico: {
            type: String
        },
        dt_regresso_servico: {
            type: String
        },
        lotacao: {
            type: Number
        },
    },

    data() {
        return {
            resourceSelected: null,
            autocarros: null,
            filtersDT: {
                codigo: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                matricula: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                lotacao: {value: this.lotacao, matchMode: FilterMatchMode.EQUALS},
                marca: {value: null, matchMode: FilterMatchMode.EQUALS},
                modelo: {value: null, matchMode: FilterMatchMode.EQUALS},
                disponivel: {value: 'Disponivel', matchMode: FilterMatchMode.EQUALS},
            },
            filters: {
                search: '',
                date: [null, null],
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'codigo',
                        sortOrder: 1,
                    },
                },
            },
        }
    },

    watch: {
        resourceSelected(newValue, oldValue) {
            this.$emit('updateAutocarro', newValue)
        }
    },
    mounted() {
        this.setLotacao()
        this.fetchData()

    },

    methods: {
        setLotacao() {
            this.filtersDT.lotacao.value = this.lotacao
        },
        onFilter() {
            this.filters.dt.page = 0
            this.fetchData()
        },
        async fetchData() {

            this.loading = true;
            this.data = await Autocarro
                .params({
                    dt_partida: this.dt_partida_servico,
                    dt_regresso: this.dt_regresso_servico,
                    disponivel: this.filtersDT.disponivel.value,
                    codigo: this.filtersDT.codigo.value,
                    matricula: this.filtersDT.matricula.value,
                    marca: this.filtersDT.marca.value,
                    modelo: this.filtersDT.modelo.value,
                    include_ultimo_servico: true,
                    include_proximo_servico: true,
                    lotacao: this.filtersDT.lotacao.value,
                    ativo: true,
                })
                .page(this.filters.dt.page)
                .limit(10)
                .orderBy(this.sortField)
                .get()
            this.loading = false;
        },

        closeSidebar() {
            this.autocarro.reset();
        },

        handleSubmit() {
            this.$emit('updateAutocarro', this.form.autocarro);

        },

    }

}
</script>
