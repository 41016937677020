<template>
    <div>
        <BreezeValidationErrors class="mb-4"/>

        <WCard v-if="loaded" iconTitulo="fa-solid fa-circle-info" titulo="Detalhes">
            <template #content>

                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <TableServicoEstado :servico="servico" @updated="fetchData"/>
                        <ServicoEstadoTag :showFullLabel="true" :servico="servico"/>
                    </div>

                    <div class="sm:col-span-1">
                        <div class="flex">
                            <div>
                                <TableHorasServico :editable="true" :showFullDates="true"
                                                   :dt-partida="servico.dt_partida"
                                                   :servico-id="servico.id"
                                                   :dt-regresso="servico.dt_regresso"
                                                   :localidadeChegada="servico.localidade_chegada"
                                                   :localidadePartida="servico.localidade_partida"
                                                   @refresh="refreshAll()"
                                />
                            </div>
                            <div>
                                <Button text secondary @click="modalEditServicoDatasELocalidadesIsOpen=true">
                                    <font-awesome-icon icon="fa-solid fa-edit"/>
                                </Button>
                            </div>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel>Autocarro</WLabel>
                        <TableServicoAutocarro :servico="servico"/>
                    </div>

                    <div class="sm:col-span-1">
                        <WLabel>Motoristas</WLabel>
                        <TableServicoMotoristas :servico="servico"/>
                    </div>
                    <div class="sm:col-span-2">
                        <WLabel>Observações</WLabel>
                        <WInlineEdit :classCSS="[errors.observacoes ? 'p-invalid' : '']" :value="servico.observacoes"
                                     class="w-full"
                                     field="observacoes"
                                     type="textarea"
                                     @saveField="saveFormField"
                        />
                    </div>

                </div>


            </template>
        </WCard>


    </div>
</template>

<script>
import InlineEdit from "@/Components/Display/InlineEdit.vue";
import LocalidadesMixin from "@/Mixins/LocalidadesMixin.js";
import {mapActions, mapState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import Checkbox from 'primevue/checkbox';
import InputNumber from "primevue/inputnumber";
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import Fieldset from "primevue/fieldset";
import {useDashboardStore} from "@/Store/DashboardStore.js";
import Textarea from "primevue/textarea";
import ServicoCliente from "@/Pages/Servicos/Servicos/components/Edit/ServicoCliente.vue";
import ServicoAutocarro from "@/Pages/Servicos/Servicos/components/Edit/ServicoAutocarro.vue";
import WBadge from "@/Components/WBadge.vue";
import ServicoAutocarros from "@/Pages/Servicos/Servicos/components/Edit/ServicoAutocarros.vue";
import TableHorasServico from "@/Pages/Servicos/Servicos/components/Table/TableHorasServico.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import TableServicoEstado from "@/Pages/Servicos/Servicos/components/Table/TableServicoEstado.vue";
import ServicoEstadoTag from "@/Pages/Servicos/Servicos/components/ServicoEstadoTag.vue";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";

export default {
    components: {
        TableServicoMotoristas,
        TableServicoAutocarro,
        ServicoEstadoTag,
        TableServicoEstado,
        FontAwesomeIcon,
        TableHorasServico,
        ServicoAutocarros,
        AddressAutocomplete,
        InlineEdit,
        WInlineEdit,
        Checkbox,
        InputNumber,
        Fieldset,
        Textarea,
        ServicoCliente,
        ServicoAutocarro,
        WBadge,
    },
    mixins: [LocalidadesMixin],
    props: {
        servicoId: {
            required: true,
        },
    },
    data() {
        return {
            servico: null,
            loaded: false,
            modalEditServicoDatasELocalidadesIsOpen: false,
        }
    },
    mounted() {
        this.fetchData()
        const unsubscribe = useGlobalModalsStore().$onAction(
            ({
                 name, // name of the action
                 store, // store instance, same as `someStore`
                 args, // array of parameters passed to the action
                 after, // hook after the action returns or resolves
                 onError, // hook if the action throws or rejects
             }) => {

                if (name === 'globalModalStoreRefreshServicos') {
                    this.fetchData()
                }

            }
        )
    },
    computed: {
        precoTaxa() {
            if (this.servico.preco) {
                return Number(this.servico.preco * 0.25).toFixed(2)
            }
        }
    },
    methods: {
        ...mapActions(useServicoStore, ['closeModalServico']),
        ...mapActions(useDashboardStore, ['fetchServicosDia']),

        refreshAll() {
            this.fetchData()
            this.$emit('refresh')
        },
        fetchData() {
            this.loaded = false
            axios.get(route('web-api.servicos.show', this.servicoId))
                .then(response => {
                    this.servico = response.data
                    this.loaded = true
                })
        },

        saveFormField(payload) {
            axios.patch(route('web-api.servicos.updateField', this.servico.id), payload).then((res => {
                this.fetchData()
            })).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: error.response.data.message,
                    life: 3000
                });
            })
        }
    },
}
</script>
