<template>

    <div>
        <WCard class="" iconTitulo="fa-solid fa-ticket" titulo="Bilhetes">
            <template #content>
                <WSkeleton v-if="loading"/>
                <DataTable v-if="data"
                           v-model:selection="resourceSelected"
                           :first="0"
                           :lazy="true"
                           :loading="loading"
                           :paginator="true"
                           :rows="10"
                           :sortOrder="1"
                           :totalRecords="data.total"
                           :value="data.data"
                           currentPageReportTemplate="A mostrar do {first} ao {last} de um total de {totalRecords} registos"
                           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown"
                           selectionMode="single"
                           sortField="name"
                           @page="onPage($event)"
                           @sort="onSort($event)"

                >
                    <Column field="id" header="ID"></Column>

                    <Column field="cliente.nome" header="Cliente">
                        <template #body="slotProps">
                                    <span v-if="!slotProps.data.acompanhante_nome">{{
                                            slotProps.data.cliente.nome
                                        }}</span>
                            <div v-else>
                                <p>{{ slotProps.data.acompanhante_nome }}</p>
                                <p class="text-gray-400 text-sm">{{
                                        slotProps.data.cliente.nome
                                    }}</p>
                            </div>
                        </template>
                    </Column>

                    <Column field="origem_paragem.localidade.name" header="De"></Column>

                    <Column field="destino_paragem.localidade.name" header="Para"></Column>


                    <Column field="tipo_viagem" header="Tipo"></Column>


                    <Column field="observacoes" header="Observações"></Column>

                    <Column field="estado" header="Estado">
                        <template #body="slotProps">
                            <BilheteEstadoTag :bilhete="slotProps.data"/>
                        </template>
                    </Column>


                    <template #empty>
                        <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum bilhete encontrado"/>
                    </template>
                </DataTable>
            </template>
        </WCard>

        <Dialog v-if="resourceShowIsOpen" v-model:visible="resourceShowIsOpen" modal header="Bilhete"
                :style="{ width: '50%' }">
            <BilheteShow :bilhete-id="resourceSelected.id"/>
        </Dialog>

    </div>

</template>

<script>

import BilheteEstadoTag from "@/Pages/Bilhetes/Components/BilheteEstadoTag.vue";
import Bilhete from "@/Pages/Bilhetes/Models/Bilhete.js";
import WDataTable from "@/Mixins/WDataTable.js";
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import BilheteShow from "@/Pages/Bilhetes/Show.vue";

export default {
    name: 'ServicoBilhetes',
    components: {BilheteShow, BilheteEstadoTag},
    props: {
        relatedToId: {
            type: Number,
            required: true
        },
    },
    mixins: [WDataTable, WCrudMixin],
    data() {
        return {
            resourceSelected: null,
            resourceShowIsOpen: false,
            data: [],
            filters: {
                search: '',
                date: [null, null],
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'id',
                        sortOrder: -1,
                    },
                },
            },
            loading: false,
        }
    },
    computed: {},
    watch: {
        resourceSelected(newValue) {
            if (newValue) {
                this.resourceShowIsOpen = true
            }

        }
    },
    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData() {
            this.loading = true;
            this.data = await Bilhete.params({
                search: this.filters.search,
                servico_id: this.relatedToId
            })
                .page(this.filters.dt.page)
                .limit(10)
                .orderBy(this.sortField)
                .get()
            this.loading = false;
        },

    }
}
</script>

