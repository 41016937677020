import {defineStore} from 'pinia'

export const useConfirmacoesStore = defineStore('confirmacoes', {
    state: () => {
        return {
            totalServicosPorConfirmar: 0,
            loadingConfirmacoes: false,
            confirmacoesServicos: [],
            motoristas: [],
            selectedMotorista: null,
        }
    },
    getters: {
        servicosDoSelectedMotorista() {
            if (!this.selectedMotorista || !this.confirmacoesServicos) {
                return
            }

            return this.confirmacoesServicos.filter(servico => servico.motoristas.find(motorista => motorista.id === this.selectedMotorista.id))
        },
        servicosPorMotorista() {
            if (!this.confirmacoesServicos) return
            return this.confirmacoesServicos.reduce((result, servico) => {
                servico.motoristas.forEach((motorista) => {
                    const motoristaId = motorista.id;

                    if (!result[motoristaId]) {
                        let servicosWithPivot = this.confirmacoesServicos.filter(servico => servico.motoristas.find(motorista => motorista.id === motoristaId))
                        servicosWithPivot.forEach(servico => {
                            servico.pivot = servico.motoristas.find(motorista => motorista.id === motoristaId).pivot
                        })
                        let servicos_confirmados = this.confirmacoesServicos.filter(servico => servico.motoristas.find(motorista => motorista.id === motoristaId)).filter(servico => servico.pivot.confirmado_at !== null)

                        let servicos_por_confirmar = this.confirmacoesServicos.filter(servico => servico.motoristas.find(motorista => motorista.id === motoristaId)).filter(servico => servico.pivot.confirmado_at === null)
                        result[motoristaId] =
                            {
                                motorista: motorista,
                                servicos: servicosWithPivot,
                                servicos_por_confirmar: servicos_por_confirmar,
                                servicos_confirmados: servicos_confirmados
                            }
                    }
                });

                return result;
            }, {})
        },
    },
    actions: {

        fetchConfirmacoesCount(dt) {
            this.loadingConfirmacoes = true
            this.totalServicosPorConfirmar = 0
            axios.get(route('web-api.servicos.dashboard.confirmacoes-motoristas-dia', {count: true, dt: dt}))
                .then(response => {
                    this.totalServicosPorConfirmar = response.data
                    this.loadingConfirmacoes = false
                })
        },
        fetchConfirmacoesData(dt) {
            this.loadingConfirmacoes = true
            this.confirmacoesServicos = null
            axios.get(route('web-api.servicos.dashboard.confirmacoes-motoristas-dia', {get: true, dt: dt}))
                .then(response => {
                    this.confirmacoesServicos = response.data
                    const uniqueMotoristas = response.data
                        .flatMap(servico => servico.motoristas)
                        .reduce((accumulator, motorista) => {
                            const existingMotorista = accumulator.find(item => item.id === motorista.id);

                            if (!existingMotorista) {
                                accumulator.push(motorista);
                            }

                            return accumulator;
                        }, []);

                    this.motoristas = uniqueMotoristas
                    this.loadingConfirmacoes = false
                })
        },

    },
})
