<template>
    <div class="flex items-center">
        <div class="h-10 w-10 flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="cliente.avatar"
                 alt="">
        </div>
        <div class="ml-4">
            <div class="font-medium text-gray-900">{{ cliente.nome }}</div>
            <div v-if="cliente.data_saida" class="text-red-500 text-xs">Saiu em {{ cliente.data_saida }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cliente: {
            type: Object,
            required: true
        },
    },
}
</script>
