<template>
    <section>
        <div class="w-panel border border-gray-200">
            <div class="w-panel-header">
                <slot name="header">
                    <div class="flex justify-between">
                        <h2 class="titulo">{{ titulo }}</h2>

                        <div class="actions">
                            <slot name="actions"></slot>
                        </div>
                    </div>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500" v-if="subtitulo">{{ subtitulo }}</p>
                </slot>
            </div>


            <slot></slot>


            <div v-if="footerLinkTitle">
                <Link :href="footerLink"
                      class="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg">
                    {{ footerLinkTitle }}
                </Link>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    props: {
        titulo: String,
        subtitulo: String,
        footerLink: String,
        footerLinkTitle: String,
    },
}
</script>
