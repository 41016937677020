<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <div class="flex flex-wrap gap-3 mt-3">
                <div class="flex align-items-center">
                    <RadioButton v-model="form.estado" inputId="form.estado1" name="pronto" value="pronto"/>
                    <label class="ml-2" for="form.estado1">Pronto</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="form.estado" inputId="form.estado2" name="confirmado" value="confirmado"/>
                    <label class="ml-2" for="form.estado2">Confirmado</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="form.estado" inputId="form.estado3" name="pendente" value="pendente"/>
                    <label class="ml-2" for="form.estado3">Pendente</label>
                </div>
                <div class="flex align-items-center">
                    <RadioButton v-model="form.estado" inputId="form.estado4" name="cancelado" value="cancelado"/>
                    <label class="ml-2" for="ingredient4">Cancelado</label>
                </div>
            </div>
            <div class="flex justify-end mt-10 bg-gray-100 py-2">
                <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                        label="Guardar" @click="handleSubmit()">
                </Button>
            </div>
        </form>
    </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton';

export default {
    components: {
        RadioButton,
    },

    data() {
        return {
            form: this.$inertia.form({
                estado: null,
            })
        }
    },
    methods: {
        handleSubmit() {
            this.form.update(route("servicos.store"),
                {
                    onSuccess: () => {
                        this.closeSidebar();
                        this.$swal('Grupo Inserido',
                            'O Grupo foi inserido com sucesso!',
                            'success'
                        );
                    },
                }
            );
        },
    },
}
</script>

