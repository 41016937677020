<template>
    <div class="min-h-full">
        <Sidebar :sidebarOpen="sidebarOpen" @closeSidebar="sidebarOpen=false"/>

        <div class="lg:pl-16 flex flex-col flex-1">
            <div class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
                <button
                    class="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
                    type="button"
                    @click="sidebarOpen = true">
                    <span class="sr-only">Open sidebar</span>
                    <MenuAlt1Icon aria-hidden="true" class="h-6 w-6"/>
                </button>
                <!-- Search bar -->
                <div class="flex-1 px-4 flex justify-between sm:px-6  lg:mx-auto lg:px-4">
                    <div class="flex-1 flex">
                        <div class="relative inset-y-0 left-0 flex items-center  mr-4">
                            <h2 class="font-semibold text-xl text-gray-800 leading-tight mr-4 mt-2">
                                <slot name="title"/>
                            </h2>
                            <slot name="filters"/>
                        </div>
                    </div>

                    <div class="flex flex-1 items-center">

                    </div>
                    <div class="ml-4 flex items-center md:ml-6">

                        <!--                        <Notifications/>-->
                        <Notifications/>

                        <!-- Profile dropdown -->
                        <Menu as="div" class="ml-3 relative">
                            <div>
                                <MenuButton
                                    class="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                                    <img :src="user.avatar"
                                         alt=""
                                         class="h-8 w-8 rounded-full"/>
                                    <span class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"><span
                                        class="sr-only">Open user menu for </span>{{ user.name }}</span>
                                    <ChevronDownIcon aria-hidden="true"
                                                     class="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"/>
                                </MenuButton>
                            </div>
                            <transition enter-active-class="transition ease-out duration-100"
                                        enter-from-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-from-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95">
                                <MenuItems
                                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <MenuItem v-slot="{ active }">
                                        <a
                                            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                            href="/users/profile">O Meu Perfil
                                        </a>
                                    </MenuItem>

                                    <MenuItem v-slot="{ active }">
                                        <a
                                            :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                                            href="/logout">Terminar sessão
                                        </a>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <main class="flex-1 pb-8">
                <!-- Page header -->
                <div class="bg-white shadow">
                    <div class="lg:mx-auto ">
                        <div
                            class="p-4 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                            <slot name="header"/>
                        </div>
                    </div>
                </div>
                <div class="py-12">
                    <div class="mx-auto lg:pl-4">

                        <div class="border-b border-gray-200">

                            <slot/>
                        </div>
                    </div>
                </div>

                <GlobalModals/>
            </main>
        </div>
    </div>
</template>


<script>

import {
    BellIcon,
    ChevronDownIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentReportIcon,
    HomeIcon,
    MenuAlt1Icon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    SearchIcon,
    ShieldCheckIcon,
    UserGroupIcon
} from '@heroicons/vue/outline'
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import Sidebar from './Sidebar.vue'
import AutoComplete from 'primevue/autocomplete';
import Notifications from '../Components/Notifications.vue'
import GlobalModals from "@/Components/GlobalModals.vue";

export default {

    components: {
        Notifications,
        Sidebar,
        MenuAlt1Icon,
        SearchIcon,
        BellIcon,
        ChevronDownIcon,
        Dialog,
        DialogPanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        AutoComplete,
        GlobalModals

    },

    computed: {
        user() {
            return this.$page.props.auth.user
        }
    },

    data() {
        return {
            sidebarOpen: false,
            selectedCliente: null,
            filteredClients: null,
            navigation: [
                {name: 'Home', href: '#', icon: HomeIcon, current: true},
                {name: 'History', href: '#', icon: ClockIcon, current: false},
                {name: 'Balances', href: '#', icon: ScaleIcon, current: false},
                {name: 'Cards', href: '#', icon: CreditCardIcon, current: false},
                {name: 'Recipients', href: '#', icon: UserGroupIcon, current: false},
                {name: 'Reports', href: '#', icon: DocumentReportIcon, current: false},
            ],
            secondaryNavigation: [
                {name: 'Settings', href: '#', icon: CogIcon},
                {name: 'Help', href: '#', icon: QuestionMarkCircleIcon},
                {name: 'Privacy', href: '#', icon: ShieldCheckIcon},
            ]
        }
    },
    watch: {
        selectedCliente(newValue, oldValue) {
            if (newValue.id) {
                this.$inertia.visit('/clientes/' + newValue.id)
            }
        }
    },
    methods: {
        searchClient(event) {
            axios.get('/web-api/clientes?filter[nome]=' + event.query).then((res) => {
                this.filteredClients = res.data;
            })
        },

    },


}


</script>
