<template>
    <div>
        <h3 v-if="resource" class="text-3xl font-bold">Editar Cliente</h3>
        <h3 v-else class="text-3xl font-bold">Criar Cliente</h3>

        <div>
            <form @submit.prevent="submit">
                <BreezeValidationErrors class="mb-4"/>


                <FormGroup :erro="form.errors.nome" forProp="nome" label="Nome" required>
                    <InputText v-model="form.nome" :invalid="form.errors.nome"
                               @change="form.validate('nome')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.telemovel" forProp="telemovel" label="Telemóvel">
                    <InputText v-model="form.telemovel" :invalid="form.errors.telemovel"
                               @change="form.validate('telemovel')"/>
                </FormGroup>


                <FormGroup :erro="form.errors.email" forProp="email" label="Email">
                    <InputText v-model="form.email" :invalid="form.errors.email"
                               @change="form.validate('email')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.is_contratante" forProp="is_contratante" label="Contratante">
                    <Checkbox v-model="form.is_contratante" :binary="true" @change="form.validate('is_contratante')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.is_passageiro" forProp="is_passageiro" label="Passageiro">
                    <Checkbox v-model="form.is_passageiro" :binary="true" @change="form.validate('is_passageiro')"/>
                </FormGroup>

                <div class="border-b border-gray-200 py-5"><h3 class="text-base font-semibold leading-6 text-gray-900">
                    Dados fiscais</h3>
                    <p class="mt-2 max-w-4xl text-sm text-gray-500">Necessários para passar faturas</p></div>


                <FormGroup :erro="form.errors.nif" forProp="nif" label="NIF">
                    <VATValidator v-model="form.nif" :class="[errors.nif ? 'p-invalid' : '']"
                                  @preencher="preencherOutrosCamposNIF"/>
                </FormGroup>

                <FormGroup :erro="form.errors.nome_juridico" forProp="nome_juridico" label="Nome Juridico">
                    <InputText v-model="form.nome_juridico" :invalid="form.errors.nome_juridico"
                               @change="form.validate('nome_juridico')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.morada" forProp="morada" label="Morada">
                    <InputText v-model="form.morada" :invalid="form.errors.morada"
                               @change="form.validate('morada')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.codigo_postal" forProp="codigo_postal" label="Código Postal">
                    <InputText v-model="form.codigo_postal" :invalid="form.errors.codigo_postal"
                               @change="form.validate('codigo_postal')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.localidade" forProp="localidade" label="Localidade">
                    <InputText v-model="form.localidade" :invalid="form.errors.localidade"
                               @change="form.validate('localidade')"/>
                </FormGroup>

                <FormGroup :erro="form.errors.pais" forProp="pais" label="País">
                    <InputText v-model="form.pais" :invalid="form.errors.pais"
                               @change="form.validate('pais')"/>
                </FormGroup>

                <div class="flex justify-end mt-10 bg-gray-100 py-2">
                    <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                            label="Guardar" @click="submit()">
                    </Button>
                </div>

            </form>

        </div>

    </div>
</template>


<script>
import Sidebar from 'primevue/sidebar';
import AutoComplete from 'primevue/autocomplete';
import ToggleButton from 'primevue/togglebutton';
import Textarea from "primevue/textarea";
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import VATValidator from "@/Components/VATValidator.vue";
import {useForm} from "laravel-precognition-vue";

export default {
    components: {
        VATValidator,
        Checkbox,
        Textarea,
        Sidebar,
        AutoComplete,
        ToggleButton,
        MultiSelect
    },
    name: 'CreateCliente',
    props: {
        resource: {
            type: Object,
        },
    },
    data() {
        return {
            allPermissions: [],
            formInputs: {
                nome: null,
                telemovel: null,
                email: null,
                is_contratante: false,
                is_passageiro: false,
                nif: null,
                nome_juridico: null,
                morada: null,
                localidade: null,
                codigo_postal: null,
                pais: null
            },
            form: null

        }
    },
    computed: {
        formMethod() {
            return this.resource ? 'patch' : 'post'
        },
        formRoute() {
            return this.resource ? route('web-api.clientes.update', this.resource.id) : route('web-api.clientes.store')
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
        if (this.resource) {
            this.form.codigo = this.resource.codigo
            this.form.nome = this.resource.nome
            this.form.telemovel = this.resource.telemovel
            this.form.email = this.resource.email
            this.form.is_contratante = this.resource.is_contratante
            this.form.is_passageiro = this.resource.is_passageiro
            this.form.nif = this.resource.nif
            this.form.nome_juridico = this.resource.nome_juridico
            this.form.morada = this.resource.morada
            this.form.localidade = this.resource.localidade
            this.form.codigo_postal = this.resource.codigo_postal
            this.form.pais = this.resource.pais

        }
    },

    methods: {

        preencherOutrosCamposNIF(payload) {
            this.form.morada = payload.morada
            this.form.nome_juridico = payload.nome_juridico
        },
        nifValido(nif) {
            this.form.nif = nif
        },
        closeSidebar() {
            this.form.reset();
            this.$emit('closeSidebar')
            this.$emit('refresh');
        },
        submit() {

            this.form.submit().then((response) => {
                this.form.reset();
                this.$swal('Sucesso',
                    'Operação realizada com sucesso',
                    'success'
                );
                this.$emit('resourceCreated', response.data)
                this.$emit('closeSidebar')
                this.$emit('refresh')
            })

        }

    }

}
</script>
