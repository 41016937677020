<template>
    <div class="text-center opacity-50 text-xl">
        <font-awesome-icon v-if="icon" :icon="icon" class="text-7xl"/>
        <h3 class="mt-2 text-lg font-medium text-gray-900">{{ titulo }}</h3>
        <div class="mt-6">
            <slot/>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        titulo: String,
    }
}
</script>
