<template>

    <menuModalServico @closeModalServico="closeModal()" @refresh="globalModalStoreRefreshServicos"/>
    <div class="grid grid-cols-1 gap-x-6 gap-y-8">


        <Tabs value="0">
            <TabList>
                <Tab value="0">Detalhes</Tab>
                <Tab value="1">Bilhetes</Tab>
                <Tab value="2">Serviços Satélite</Tab>
                <Tab value="3">Paragens</Tab>
            </TabList>

            <TabPanels>
                <TabPanel value="0">
                    <ServicoInternacionalDetalhes @closeModalServico="closeModal()" :servicoId="modalServicoIdSelected"
                                                  @refresh="globalModalStoreRefreshServicos"/>
                </TabPanel>
                <TabPanel value="1">
                    <ServicoBilhetes :related-to-id="modalServicoIdSelected" related-to-type="SERVICO"/>
                </TabPanel>
                <TabPanel value="2">
                    <ServicoSatelites :related-to-id="modalServicoIdSelected"
                                      related-to-type="SERVICO"/>
                </TabPanel>
                <TabPanel value="3">
                    <TableParagens :related-to-id="modalServicoIdSelected" related-to-type="SERVICO"/>
                </TabPanel>
            </TabPanels>

        </Tabs>


    </div>

</template>


<script>
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import ServicoDetalhes from "@/Pages/Servicos/Servicos/components/Edit/ServicoDetalhes.vue";
import {mapActions, mapState, mapWritableState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import menuModalServico from "@/Pages/Servicos/Servicos/components/Edit/menuModalServico.vue";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import ServicoInternacionalDetalhes from "@/Pages/Servicos/Internacionais/Components/ServicoInternacionalDetalhes.vue";
import TableParagens from "@/Pages/Paragens/Components/TableParagens.vue";
import ServicoBilhetes from "@/Pages/Servicos/Internacionais/Components/ServicoBilhetes.vue";
import ServicoSatelites from "@/Pages/Servicos/Internacionais/Components/ServicosSatelites/ServicoSatelites.vue";

export default {
    components: {
        ServicoSatelites,
        ServicoBilhetes,
        TableParagens,
        ServicoInternacionalDetalhes,
        ServicoDetalhes,
        menuModalServico,
    },
    name: 'ServicoEdit',
    mixins: [WCrudMixin],
    emits: ['closeModal'],
    data() {
        return {
            modalPrecosIsOpen: false,
        }
    },

    computed: {
        ...mapState(useServicoStore, ['servico']),
        ...mapWritableState(useGlobalModalsStore, ['modalServicoInternacionalEditIsOpen', 'modalServicoIdSelected']),
        getTituloModal() {
            if (this.servico) {
                return 'Serviço Internacional: ' + this.servico.codigo
            }
        },
    },
    watch: {
        modalServicoIdSelected(newValue) {
            if (newValue) {
                this.fetchServico(this.modalServicoIdSelected);
            }
        }
    },

    methods: {
        ...mapActions(useGlobalModalsStore, ['closeModalServicoEdit', 'globalModalStoreRefreshServicos']),
        ...mapActions(useServicoStore, ['fetchServico']),


        closeModal() {
            this.removeUrlParams('modalServicoId')

            this.closeModalServicoEdit()
        },
        deleteResource(resource) {
            if (!confirm('Tem a certeza que deseja eliminar? ')) {
                return false;
            }
            this.loadingDelete = true
            this.$inertia.delete(route(this.resourceRoute + '.destroy', this.modalServicoIdSelected), {
                onSuccess: () => {
                    this.loadingDelete = false
                    this.removeUrlParams('modalServicoId')
                    this.closeModalServicoEdit()
                    this.globalModalStoreRefreshServicos()
                    this.$swal('Eliminado',
                        'O Registo foi eliminado com sucesso!',
                        'success'
                    );


                }
            })
        },
    }

}
</script>
