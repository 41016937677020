<template>
    <div>
        <p class="text-gray-400">Serviço Satélite</p>
        {{ servico.localidade_partida.name }} -> {{ servico.localidade_chegada.name }}

        <DataTable v-if="bilhetes"
                   v-model:selection="resourceSelected"
                   :first="0"
                   :lazy="true"
                   :loading="loading"
                   :paginator="true"
                   :rows="10"
                   :sortOrder="1"
                   :totalRecords="bilhetes.length"
                   :value="bilhetes.data"
                   currentPageReportTemplate="A mostrar do {first} ao {last} de um total de {totalRecords} registos"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown"
                   selectionMode="single"
                   sortField="name"
                   @page="onPage($event)"
                   @sort="onSort($event)"

        >
            <Column field="id" header="ID"></Column>

            <Column field="cliente.nome" header="Cliente">
                <template #body="slotProps">
                                    <span v-if="!slotProps.data.acompanhante_nome">{{
                                            slotProps.data.cliente.nome
                                        }}</span>
                    <div v-else>
                        <p>{{ slotProps.data.acompanhante_nome }}</p>
                        <p class="text-gray-400 text-sm">{{
                                slotProps.data.cliente.nome
                            }}</p>
                    </div>
                </template>
            </Column>

            <Column field="origem_paragem.localidade.name" header="De"></Column>

            <Column field="destino_paragem.localidade.name" header="Para"></Column>


            <Column field="tipo_viagem" header="Tipo"></Column>


            <Column field="observacoes" header="Observações"></Column>

            <Column field="estado" header="Estado">
                <template #body="slotProps">
                    <BilheteEstadoTag :bilhete="slotProps.data"/>
                </template>
            </Column>


            <template #empty>
                <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum bilhete encontrado"/>
            </template>
        </DataTable>
      

    </div>
</template>

<script>
import Bilhete from "@/Pages/Bilhetes/Models/Bilhete.js";
import BilheteEstadoTag from "@/Pages/Bilhetes/Components/BilheteEstadoTag.vue";
import WDataTable from "@/Mixins/WDataTable.js";

export default {
    components: {BilheteEstadoTag},
    mixins: [WDataTable],
    props: {
        servico: {
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            bilhetes: []
        }
    },
    mounted() {
        this.fetchBilhetes();
    },

    methods: {
        fetchData() {

        },
        async fetchBilhetes() {
            this.loading = true;
            this.bilhetes = await Bilhete.params({
                servico_id: this.servico.servico_parent_id
            })
                .limit(10)
                .orderBy('id')
                .get()
            this.loading = false;
        },
    },
}
</script>
