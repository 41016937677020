<template>
    <div>

        <h3 v-if="!resource" class="text-3xl font-bold">Novo Itinerário</h3>
        <h3 v-else class="text-3xl font-bold">Editar Itinerário</h3>

        <div>
            <form @submit.prevent="handleSubmit">
                <BreezeValidationErrors class="mb-4"/>
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <WLabel for="dt_partida" required>Data de partida</WLabel>
                        <div class="mt-2">
                            <InputText v-model="form.dt_partida" :class="[errors.dt_partida ? 'p-invalid' : '']"
                                       type="datetime-local"/>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel for="dt_regresso">Data de chegada
                            <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles"/>
                        </Wlabel>
                        <div class="mt-2">
                            <InputText v-model="form.dt_chegada" :class="[errors.dt_chegada ? 'p-invalid' : '']"
                                       type="datetime-local"/>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel for="localidade_partida" required>Origem</WLabel>
                        <div class="mt-2">
                            <AddressAutocomplete v-model="form.localidade_partida"
                                                 :classCSS="[errors.localidade_partida ? 'p-invalid' : 'w-full']"
                                                 :closable="false"
                                                 :is-edit-prop="true"
                                                 :valueProp="form.localidade_partida"
                                                 field="localidade_partida"

                                                 @changeFormValue="changeFormValue"/>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel
                            for="localidade_chegada" required>Destino
                        </WLabel>
                        <div class="mt-2">
                            <AddressAutocomplete v-model="form.localidade_chegada"
                                                 :classCSS="[errors.localidade_chegada ? 'p-invalid' : 'w-full']"
                                                 :closable="false"
                                                 :is-edit-prop="true"
                                                 :valueProp="form.localidade_chegada"
                                                 field="localidade_chegada"
                                                 @changeFormValue="changeFormValue"/>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel
                            for="pax">Nº Pax's
                        </WLabel>
                        <div class="mt-2">
                            <InputNumber v-model="form.pax" :classCSS="[errors.pax ? 'p-invalid' : 'w-full']"
                            />
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel
                            for="kms">Kms
                            <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles"/>
                        </WLabel>
                        <div class="mt-2">
                            <InputNumber v-model="form.kms" :classCSS="[errors.localidade ? 'p-invalid' : 'w-full']"
                            />
                        </div>
                    </div>

                </div>
                <div class="flex justify-between mt-10 bg-gray-100 py-2">
                    <Button v-if="resource" class="ml-4" label="Eliminar"
                            severity="danger" @click="handleDelete()">
                    </Button>
                    <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                            label="Guardar" @click="handleSubmit()">
                    </Button>
                </div>

            </form>

        </div>

    </div>
</template>


<script>


import WCrudMixin from "@/Mixins/WCrudMixin.js";
import AutoComplete from "primevue/autocomplete";
import LocalidadesMixin from "@/Mixins/LocalidadesMixin.js";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import InputNumber from "primevue/inputnumber";

export default {
    mixins: [WCrudMixin, LocalidadesMixin],
    components: {AddressAutocomplete, FontAwesomeIcon, InputNumber, AutoComplete},
    name: 'ServicoItinerarioCreateEdit',
    props: {
        servicoId: {
            type: Number,
            required: true
        },
        resource: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            form: this.$inertia.form({
                dt_partida: null,
                dt_chegada: null,
                localidade_partida: null,
                localidade_chegada: null,
                pax: null,
                kms: null,
            }),
            servico: null,
        }
    },

    mounted() {
        this.initialize();
    },

    methods: {
        async initialize() {
            await this.fetchServico();
            this.setResources();
        },

        async fetchServico() {
            await axios.get(route('web-api.servicos.show', this.servicoId)).then((res => {
                this.servico = res.data
            }))
        },
        setResources() {
            this.form.reset()
            if (!this.resource) {
                this.form.pax = this.servico.lotacao
                this.form.dt_partida = this.servico.dt_partida;
            } else {
                this.form.dt_partida = this.resource.dt_partida
                this.form.dt_chegada = this.resource.dt_chegada
                this.form.localidade_partida = this.resource.localidade_partida
                this.form.localidade_chegada = this.resource.localidade_chegada
                this.form.pax = this.resource.pax
                this.form.kms = this.resource.kms
            }


        },

        handleDelete() {
            if (!confirm('Tem a certeza que pretende eliminar?')) return;
            axios.delete(route("servicos.itinerarios.destroy", [this.servicoId, this.resource.id]))
                .then(() => {
                    this.form.reset()
                    this.$emit('update-itinerario')
                    this.$toast.add({
                        icon: 'pi pi-check',
                        severity: 'success',
                        summary: 'Atualizado',
                        life: 3000
                    });

                })
        },

        handleSubmit() {
            if (this.resource) {
                this.handleUpdate()
                return
            }
            this.form.post(route("servicos.itinerarios.store", this.servicoId),
                {
                    onSuccess: () => {
                        this.form.reset()
                        this.$emit('update-itinerario')
                        this.$toast.add({
                            icon: 'pi pi-check',
                            severity: 'success',
                            summary: 'Atualizado',
                            life: 3000
                        });
                    },
                }
            );
        },

        handleUpdate() {
            this.form.patch(route("servicos.itinerarios.update", [this.servicoId, this.resource.id]),
                {
                    onSuccess: () => {
                        this.form.reset()
                        this.$emit('update-itinerario')
                        this.$toast.add({
                            icon: 'pi pi-check',
                            severity: 'success',
                            summary: 'Atualizado',
                            life: 3000
                        });
                    },
                }
            );
        },

    }

}
</script>
