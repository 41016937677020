<template>
    <div>

        <FormErrors :erros="form.errors" class="mb-4"/>
        <WCard class="" iconTitulo="fa-regular fa-calendar" titulo="Viagem">
            <template #content>
                <div class="grid grid-cols-1 gap-y-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <WLabel
                            for="localidade_partida">Origem
                        </WLabel>
                        <div class="mt-2">
                            <AddressAutocomplete v-model="form.localidade_partida"
                                                 :classCSS="form.errors.localidade_partida ? 'p-invalid' : 'w-full'"
                                                 :valueProp="form.localidade_partida"
                                                 field="localidade_partida"
                                                 @changeFormValue="changeFormValue"/>
                            <span v-if="form.localidade_partida"
                                  class="text-gray-400">{{ form.localidade_partida.name }}</span>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel
                            for="localidade_chegada">Destino
                        </WLabel>
                        <div class="mt-2">
                            <AddressAutocomplete v-model="form.localidade_chegada"
                                                 :classCSS="form.errors.localidade_chegada ? 'p-invalid' : 'w-full'"
                                                 :valueProp="form.localidade_chegada"
                                                 field="localidade_chegada"
                                                 @changeFormValue="changeFormValue"/>
                            <span v-if="form.localidade_chegada"
                                  class="text-gray-400">{{ form.localidade_chegada.name }}</span>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel for="dt_partida">Data de partida</WLabel>
                        <div class="mt-2">
                            <InputText v-model="form.dt_partida" :class="[form.errors.dt_partida ? 'p-invalid' : '']"
                                       type="datetime-local"/>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel for="dt_regresso">data de chegada</Wlabel>
                        <div class="mt-2">
                            <WInputDataChegada v-model="form.dt_regresso" :dt-partida="form.dt_partida"
                                               :origem-localidade="form.localidade_partida"
                                               :destino-localidade="form.localidade_chegada"
                                               :model-value="form.dt_regresso"
                                               :class="[form.errors.dt_regresso ? 'p-invalid' : '']"
                                               type="datetime-local" @preencher="preencherDtChegada"/>
                        </div>
                    </div>


                </div>

                <hr/>


                <div class="flex justify-end mt-10  py-2">

                    <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                            label="Guardar" @click="handleSubmit()">
                    </Button>
                </div>
            </template>
        </WCard>
    </div>
</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import {useForm} from "laravel-precognition-vue";
import WInputDataChegada from "@/Components/WInputDataChegada.vue";
import FormErrors from "@/Components/FormErrors.vue";

export default {
    components: {FormErrors, AddressAutocomplete, WInputDataChegada},
    props: {
        servicoId: {
            type: Number,
        },
        dt: {
            type: String,
            required: false,
        },
        dtPartida: {
            type: String,
            required: true,
        },
        dtRegresso: {
            type: String,
        },
        localidadePartida: {
            type: Object
        },
        localidadeChegada: {
            type: Object
        }
    },
    data() {
        return {
            formInputs: {
                dt_partida: null,
                dt_regresso: null,
                localidade_partida: null,
                localidade_chegada: null,
            },
            form: null,
        }
    },
    computed: {
        formMethod() {
            return 'patch'
        },
        formRoute() {
            if (this.servicoId) {
                return route('web-api.servicos.update.datas-e-localidades', this.servicoId)
            }
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
        if (this.servico) {
            this.form.dt_partida = this.dtPartida
            this.form.dt_regresso = this.dtRegresso
            this.form.localidade_partida = this.localidadePartida.full_json
            this.form.localidade_chegada = this.localidadeChegada.full_json

        }
    },
    mounted() {
        this.setResource();
    },
    methods: {
        closeSidebar() {
            this.resourceCreateIsOpen = false
        },
        handleSubmit() {
            this.form.submit().then((response) => {
                this.$emit('refresh')
                this.$emit('closeModalServico')
                this.$toast.add({
                    severity: 'success',
                    summary: 'Serviço atualizado',
                    detail: 'Serviço atualizado com sucesso',
                    life: 3000
                });
            });
        },
        setResource() {
            this.form.dt_partida = this.dtPartida;
            this.form.dt_regresso = this.dtRegresso;
            this.form.localidade_partida = this.localidadePartida.full_json;
            this.form.localidade_chegada = this.localidadeChegada.full_json;
        },

        changeFormValue(value) {
            this.form[value.field] = value.value
        },
        preencherDtChegada(payload) {
            this.form.dt_regresso = payload.dt_chegada
        },

    },
}
</script>
