import './bootstrap';
import '../css/app.css';

//import "primeflex/primeflex.css";
//import 'primevue/resources/themes/aura-light-indigo/theme.css'
//import "primevue/resources/themes/lara-light-teal/theme.css";
//import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'


import {createApp, h} from 'vue';

import {createInertiaApp, Head, Link, useForm} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import mitt from 'mitt'

const emitter = mitt()
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

import InputText from 'primevue/inputtext';
import Card from 'primevue/card'
import Button from 'primevue/button'
import Drawer from 'primevue/drawer'
import Dialog from 'primevue/dialog'
import InputNumber from 'primevue/inputnumber';
import Tag from 'primevue/tag';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import Textarea from "primevue/textarea";
import MenuPrime from 'primevue/menu';
import Select from 'primevue/select'
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';


import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from "primevue/confirmdialog";
import Toast from 'primevue/toast';


import axios from 'axios'
import {Model} from 'vue-api-query'
import WinvelMixin from './Mixins/WinvelMixin'
import VueTippy from 'vue-tippy'


//Mines
import AuthLayout from '@/Layouts/Authenticated.vue';
import WLabel from './Components/WLabel.vue';
import WSkeleton from './Components/WSkeleton.vue'
import WWrapper from './Components/WWrapper.vue'
import WCard from './Components/WCard.vue'
import WPanel from './Components/WPanel.vue'
import WEmptyState from './Components/WEmptyState.vue'
import WBadge from "@/Components/WBadge.vue";
import FormGroup from "@/Components/FormHorizontal/FormGroup.vue";
import FormVerticalGroup from "@/Components/FormVertical/FormVerticalGroup.vue";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import {setupCalendar} from 'v-calendar';


import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import BreezeLabel from '@/Components/Label.vue';
import ClienteTd from './Components/ClienteTd.vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBus,
    faCheck,
    faCheckCircle,
    faCircleExclamation,
    faCircleInfo,
    faCircleXmark,
    faCity,
    faClipboardUser,
    faEye,
    faFilter,
    faFolder,
    faInbox,
    faLayerGroup,
    faLocationDot,
    faMap,
    faMinus,
    faPaperPlane,
    faPenToSquare,
    faPeopleGroup,
    faPrint,
    faRefresh,
    faRoad,
    faTrash,
    faUserCircle,
    faUsers,
    faUserTie,
    faXmark,
    faArrowRightArrowLeft,
    faArrowRotateRight,
    faPen,
    faEuro,
    faTicket,
    faCopy,
    faPhone,
    faWandMagicSparkles, faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import {
    faCalendar,
    faCircle,
    faClock,
    faEnvelope,
    faFile,
    faNoteSticky,
    faUser,
} from "@fortawesome/free-regular-svg-icons";
import {createPinia} from "pinia";
import {createORM} from 'pinia-orm'
import {createPiniaOrmAxios} from '@pinia-orm/axios'

const pinia = createPinia()
const piniaOrm = createORM({
    plugins: [
        createPiniaOrmAxios({
            axios,
        }),
    ],
})
pinia.use(piniaOrm)

import ToastService from "primevue/toastservice";


library.add(faCircleCheck, faUser, faWandMagicSparkles, faArrowRightArrowLeft, faPhone, faCopy, faTicket, faEuro, faArrowRotateRight, faPen, faArrowRightArrowLeft, faUserTie, faArrowUp, faArrowDown, faMap, faCircleExclamation, faCircle, faLayerGroup, faRefresh, faFile, faTrash, faPrint, faEnvelope, faNoteSticky, faInbox, faPenToSquare, faLocationDot, faClock, faCircleXmark, faCheckCircle, faPaperPlane, faFolder, faFilter, faUsers, faPeopleGroup, faBus, faCity, faCircleInfo, faCalendar, faRoad, faClipboardUser, faUserCircle, faArrowRight, faArrowLeft, faLocationDot, faCheck, faXmark, faEye,
    faMinus)

const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{indigo.50}',
            100: '{indigo.100}',
            200: '{indigo.200}',
            300: '{indigo.300}',
            400: '{indigo.400}',
            500: '{indigo.500}',
            600: '{indigo.600}',
            700: '{indigo.700}',
            800: '{indigo.800}',
            900: '{indigo.900}',
            950: '{indigo.950}'
        }
    }
});

const primeVueConfig = {
    theme: {
        preset: MyPreset
    },
    locale: {
        startsWith: 'Começa com',
        contains: 'Contém',
        notContains: 'Não contém',
        endsWith: 'Termina com',
        equals: 'Igual a',
        notEquals: 'Diferente de',
        noFilter: 'Sem filtro',
        lt: 'Menos de',
        lte: 'Menos de ou igual a',
        gt: 'Maior que',
        gte: 'Maior que ou igual a',
        dateIs: 'Data é',
        dateIsNot: 'Data não é',
        dateBefore: 'Data é antes de',
        dateAfter: 'Data é depois de',
        clear: 'Apagar',
        apply: 'Aplicar',
        matchAll: 'Igual a tudo',
        matchAny: 'Match Any',
        addRule: 'Adicionar Regra',
        removeRule: 'Remover Regra',
        accept: 'Sim',
        reject: 'Não',
        choose: 'Selecione',
        upload: 'Enviar',
        cancel: 'Cancelar',
        completed: 'Completed',
        pending: 'Pending',
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Jullho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        chooseYear: 'Selecione Ano',
        chooseMonth: 'Selecione Mês',
        chooseDate: 'Selecione Data',
        prevDecade: 'Década Anterior',
        nextDecade: 'Próxima Década',
        prevYear: 'Previous Year',
        nextYear: 'Next Year',
        prevMonth: 'Previous Month',
        nextMonth: 'Next Month',
        prevHour: 'Previous Hour',
        nextHour: 'Next Hour',
        prevMinute: 'Previous Minute',
        nextMinute: 'Next Minute',
        prevSecond: 'Previous Second',
        nextSecond: 'Next Second',
        am: 'am',
        pm: 'pm',
        today: 'Hoje',
        weekHeader: 'Wk',
        firstDayOfWeek: 0,
        dateFormat: 'mm/dd/yy',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
        searchMessage: '{0} results are available',
        selectionMessage: '{0} items selected',
        emptySelectionMessage: 'No selected item',
        emptySearchMessage: 'No results found',
        emptyMessage: 'No available options',
        aria: {
            trueLabel: 'True',
            falseLabel: 'False',
            nullLabel: 'Not Selected',
            star: '1 star',
            stars: '{star} stars',
            selectAll: 'All items selected',
            unselectAll: 'All items unselected',
            close: 'Close',
            previous: 'Previous',
            next: 'Next',
            navigation: 'Navigation',
            pageLabel: '123'
        }
    }

//FONTAWESOME


}


import {definePreset} from "@primevue/themes";
import AvatarUserName from "@/Components/Display/AvatarUserName.vue";
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import AutoComplete from "primevue/autocomplete";
import TableServicoEstado from "@/Pages/Servicos/Servicos/components/Table/TableServicoEstado.vue";


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Model.$http = axios
const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})

        app.config.globalProperties.emitter = emitter

        app.use(plugin)
            .component('Link', Link)
            .component('Head', Head)
            .component('useForm', useForm)
            .use(VueSweetalert2)
            .use(VueTippy, {
                directive: 'tippy',
                component: 'tippy',

                componentSingleton: 'tippy-singleton',
                defaultProps: {
                    appendTo: document.body,
                    placement: "auto",
                    allowHTML: true,
                    delay: 600,
                }
            })

            //Mine
            .component('AuthLayout', AuthLayout)
            .component('WLabel', WLabel)
            .component('WSkeleton', WSkeleton)
            .component('WWrapper', WWrapper)
            .component('WCard', WCard)
            .component('WPanel', WPanel)
            .component('WBadge', WBadge)
            .component('WEmptyState', WEmptyState)
            .component('WInlineEdit', WInlineEdit)
            .component('ClienteTd', ClienteTd)
            .component('font-awesome-icon', FontAwesomeIcon)
            .component('BreezeLabel', BreezeLabel)
            .component('BreezeValidationErrors', BreezeValidationErrors)
            .component('FormGroup', FormGroup)
            .component('FormVerticalGroup', FormVerticalGroup)
            .component('AvatarUserName', AvatarUserName)
            .component('AddressAutocomplete', AddressAutocomplete)
            .component('TableServicoEstado', TableServicoEstado)
            .use(PrimeVue, primeVueConfig)

            .mixin(WinvelMixin)

            .component('InputText', InputText)
            .component('Card', Card)
            .component('Button', Button)
            .component('Drawer', Drawer)
            .component('Dialog', Dialog)
            .component('ConfirmDialog', ConfirmDialog)
            .component('Toast', Toast)
            .component('InputNumber', InputNumber)
            .component('Tag', Tag)
            .component('Tabs', Tabs)
            .component('TabList', TabList)
            .component('Tab', Tab)
            .component('TabPanels', TabPanels)
            .component('TabPanel', TabPanel)
            .component('Message', Message)
            .component('Panel', Panel)
            .component('Textarea', Textarea)
            .component('MenuPrime', MenuPrime)
            .component('Select', Select)
            .component('Accordion', Accordion)
            .component('AccordionPanel', AccordionPanel)
            .component('AccordionHeader', AccordionHeader)
            .component('AccordionContent', AccordionContent)
            .component('InputGroup', InputGroup)
            .component('InputGroupAddon', InputGroupAddon)
            .component('Autocomplete', AutoComplete)
            .use(ZiggyVue, Ziggy)
            .use(setupCalendar, {})

            .use(ConfirmationService)
            .use(ToastService)
            .use(pinia)
            .use(mitt())
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});



