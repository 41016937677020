<template>
    <div>
        <InputGroup>
            <InputText v-model="nifValue" type="text"
            />
            <Button outlined @click="validateVAT">
                <font-awesome-icon icon="fa-solid fa-circle-info"/>
            </Button>
        </InputGroup>
        <div v-if="validationResult && !validationResult.error">
            <Message v-if="validationResult.format_valid" :closable="false" severity="success">
                <p>Nome da Empresa: {{ validationResult.company_name }}</p>
                <p>Morada da empresa: {{ validationResult.company_address }}</p>
                <Button text @click="preencher()">
                    <font-awesome-icon icon="fa-solid fa-edit"/>
                    Preencher campos
                </Button>
            </Message>

            <Message v-else severity="error">
                NIF Inválido
            </Message>

        </div>


        <p v-if="erro" class="text-red-500">{{ validationResult.error.info }}</p>
    </div>
</template>

<script>
import Message from 'primevue/message';
import InputGroup from 'primevue/inputgroup';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'VATValidator',
    components: {
        FontAwesomeIcon,
        Message,
        InputGroup
    },
    props: ['modelValue'],
    data() {
        return {
            validationResult: null,
            erro: false
        };
    },
    computed: {
        nifValue: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
    methods: {
        preencher() {
            let payload = {
                morada: this.validationResult.company_address,
                nome_juridico: this.validationResult.company_name,
            }
            this.$emit('preencher', payload)
        },
        validateVAT() {
            this.erro = false
            this.validationResult = null
            if (!this.nifValue) return
            axios
                .post(route('vat.validate'), {nif: this.nifValue})
                .then((response) => {
                    this.validationResult = response.data;

                    if (this.validationResult.error) {
                        this.erro = true
                    }
                })
                .catch((error) => {
                    this.erro = true;
                });
        },
    },
};
</script>
