<template>
    <div>
        <div v-if="!isEdit">
            <font-awesome-icon
                class="cursor-pointer"
                icon="fa-solid fa-pen-to-square"
                @click="enableEdit"
            />
            <span v-if="formattedAddress">{{ formattedAddress }}</span>
        </div>

        <div v-show="isEdit">
            <AutoComplete
                v-model="inputValue"
                :class="classCSS"
                :suggestions="items"
                force-selection
                option-label="description"
                @change="handleSelect"
                @complete="fetchSuggestions"
            >
                <template #option="slotProps">
                    <p class="font-bold">{{ slotProps.option.description }}</p>
                    <p class="text-sm text-muted">
                        {{ slotProps.option.structured_formatting.secondary_text }}
                    </p>
                </template>
            </AutoComplete>

            <font-awesome-icon
                v-if="closable"
                class="cursor-pointer"
                icon="fa-solid fa-times"
                @click="disableEdit"
            />
        </div>
    </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import axios from 'axios'; // Ensure axios is imported.

export default {
    name: 'AddressAutocomplete',
    components: {
        AutoComplete,
    },
    props: {
        classCSS: String,
        valueProp: Object,
        field: String,
        isEditProp: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isEdit: this.isEditProp,
            inputValue: this.valueProp ? this.valueProp : null,
            items: [],
        };
    },
    computed: {
        formattedAddress() {
            if (this.valueProp && this.valueProp.id) {
                return this.valueProp.formatted_address
            }

            if (this.valueProp && !this.valueProp.id) {
                if (this.valueProp.formatted_address) {
                    return this.valueProp.formatted_address;
                } else if (this.valueProp.structured_formatting) {
                    return `${this.valueProp.structured_formatting.main_text}, ${this.valueProp.structured_formatting.secondary_text}`;
                }
            }
            return '';
        }
    },
    watch: {
        valueProp: {
            immediate: true,
            handler(newVal) {

                if (newVal && newVal.id) {
                    console.log(newVal.full_json.formatted_address)
                    this.inputValue = {
                        description: newVal.name,
                        place_id: newVal.resource_id,
                        formatted_address: newVal.full_json.formatted_address
                    }
                } else {
                    this.inputValue = newVal || null;
                }


            }
        }
    },
    methods: {
        enableEdit() {
            this.inputValue = null;
            this.isEdit = true;
        },
        disableEdit() {
            this.isEdit = false;
        },
        handleSelect(selectedOption) {

            if (selectedOption && selectedOption.value && selectedOption.value.description) {
                console.log(selectedOption)
                this.isEdit = false;
                this.$emit('update:modelValue', selectedOption.value);
                this.$emit('changeFormValue', this.field, selectedOption.value);
            }
        },
        fetchSuggestions(query) {
            axios.get(route('web-api.localidades.localidades.google-search', {input: query.query}))
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error('Error fetching suggestions:', error);
                });
        }
    }
};
</script>
