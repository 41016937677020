<template>
    <div>
        <Button type="button" label="Remover contrato associado" outlined icon="pi pi-times-circle" @click="limpar" />

        <WSkeleton v-if="loading"/>
        <DataTable v-if="data"
                   :first="0"
                   :lazy="true"
                   :loading="loading"
                   :paginator="true"
                   :rows="10"
                   :sortOrder="1"
                   :totalRecords="data.total"
                   :value="data.data"
                   currentPageReportTemplate="A mostrar do {first} até {last} de um total de {totalRecords}"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   selectionMode="single"
                   sortField="id"
                   @page="onPage($event)"
                   @rowSelect="editResource($event.data)"
                   @sort="onSort($event)">

            <Column :sortable="true" field="id" header="ID"></Column>

            <Column :sortable="true" field="dt" header="Data"></Column>

            <Column :sortable="true" field="cliente_id" header="Cliente">
                <template #body="slotProps">
                                            <span v-if="slotProps.data.cliente">
                                                <AvatarUserName :avatar="slotProps.data.cliente.avatar"
                                                                :name="slotProps.data.cliente.nome"/>

                                            </span>

                </template>
            </Column>


            <template #empty>
                <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum contrato encontrado"/>
            </template>
        </DataTable>
    </div>
</template>

<script>
import WDataTable from "@/Mixins/WDataTable.js";
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import Contrato from "@/Models/Contrato.js";
import AvatarUserName from "@/Components/Display/AvatarUserName.vue";
import {mapWritableState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";

export default {
    components: {AvatarUserName},
    mixins: [WDataTable, WCrudMixin],
    props: {
        servico: {
            type: Object,
            required: true
        },
    },
    computed: {
        ...mapWritableState(useServicoStore, ['servico']),
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        limpar(){
          let resource = {
              id : null
          }
          this.editResource(resource)
        },
        editResource(resource) {
            if(!confirm('Tem a certeza que deseja associar este contrato ao serviço?')){
                return false
            }
            let params = {
                field:'contrato_id',
                value : resource.id
            }
            axios.patch(route('web-api.servicos.updateField',this.servico.id),params).then((response)=>{
                this.servico.contrato_id = response.data.contrato_id
                this.$emit('contratoSelected',resource)
            })

        },
        async fetchData() {
            this.loading = true;
            this.data = await Contrato
                .params({
                    ativo: true,
                    search: this.filters.search,
                    include_cliente: true,
                })
                .page(this.filters.dt.page)
                .limit(10)
                .orderBy(this.sortField)
                .get()
            this.loading = false;
        },

    }
}
</script>


