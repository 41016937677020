<template>
    <div>
        <FormH @w-submit="submit" @submit.prevent>


            <Message :closable="false" severity="info" >Ao colocar em crédito o bilhete, irá ficar sem serviço associado</Message>
            <FormGroup :erro="form.errors.motivo" forProp="motivo"
                       label="Motivo"
                       required>
             <Textarea v-model="form.motivo" :class="[errors.motivo ? 'p-invalid' : '']" autoResize
                       class="w-full"
                       rows="5"/>
            </FormGroup>

            <div class="pt-5">
                <div class="flex justify-end">

                    <button :disabled="form.processing" class="ml-3 w-btn-primary" type="submit">
                        <i v-if="form.processing" class="pi pi-spin pi-spinner mr-2"
                           style="font-size: 1rem"></i>
                        Guardar
                    </button>
                </div>
            </div>

        </FormH>
    </div>
</template>

<script>
import FormGroup from "@/Components/FormHorizontal/FormGroup.vue";
import FormH from "@/Components/FormHorizontal/Form.vue";
import {useForm} from "laravel-precognition-vue";

export default {
    components: {FormH, FormGroup},
    props: {
        bilhete: {
            type: Object,
        },
    },
    data() {
        return {
            formInputs: {
                motivo: null,
            },
            form: null
        }
    },
    beforeMount() {
        this.form = useForm('patch',
            route('web-api.bilhetes.colocar-em-credito', this.bilhete.id),
            this.formInputs);
    },
    methods: {
        submit() {

            this.form.submit().then((response) => {
                this.form.reset();
                this.$swal('Sucesso',
                    'Operação realizada com sucesso',
                    'success'
                );
                this.$emit('resourceCreated', response.data)
                this.$emit('closeSidebar')
                this.$emit('refresh')
            })

        }
    }
}
</script>


