<template>
    <div v-if="hasErrors">
        <div class="font-medium text-red-600">Alguma coisa correu mal</div>

        <ul class="mt-3 list-disc list-inside text-sm text-red-600">
            <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
        </ul>
    </div>
</template>


<script>
export default {
    name: 'ValidationErrors',
    computed: {
        hasErrors() {
            if (this.errors) {
                return Object.keys(this.errors).length > 0;
            }

        },
    },
};
</script>
