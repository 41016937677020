import {defineStore} from 'pinia'

export const useParagemPrecoStore = defineStore('paragemPreco', {
    state: () => {
        return {
            paragensPrecos: [],
            loading: false
        }
    },
    getters: {},
    actions: {
        fetchParagensPrecos(relatedToType, relatedToId) {
            this.loading = true
            let params = {
                relatedToType: relatedToType,
                relatedToId: relatedToId
            }
            return axios.get(route('web-api.paragens.precos.index', params)).then((res => {
                this.paragensPrecos = res.data
                this.loading = false
            }))
        },
        updateParagemPreco(relatedToId, relatedToType, paragemId, paragemIdPara, preco) {

            axios.patch(route('web-api.paragens.precos.update'), {
                related_to_id: relatedToId,
                related_to_type: relatedToType,
                paragem_id: paragemId,
                paragem_id_para: paragemIdPara,
                preco: preco
            })
                .then(response => {

                    this.isEditing = false
                })

            /* let paragem1Index = this.paragens.findIndex(paragem => paragem.id === response.paragem_id)

             let paragem = this.paragens[paragem1Index]

             //tem preco?
             let precoParagemPara = paragem.precos.find(preco => preco.paragem_id_para === response.paragem_id_para)

             if (precoParagemPara) {
                 precoParagemPara.preco = response.preco
             } else {
                 paragem.precos.push({
                     paragem_id: response.paragem_id,
                     paragem_id_para: response.paragem_id_para,
                     preco: response.preco
                 })
             }*/
        },

    },
})
