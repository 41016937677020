import Zona from "@/Models/Zona.js";

const ZonasMixin = {
    data() {
        return {
            zonas: [],
        };
    },

    methods: {
        async fetchZonas() {

            const response = await Zona.get()
            this.zonas = response.data

        }

    },

};

export default ZonasMixin;
