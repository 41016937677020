<template>

            <FormVerticalLabel :forProp="forProp" :required="required">{{ label }}</FormVerticalLabel>
            <div class="mt-2">
                <slot></slot>
                <p class="mt-2 text-sm text-red-600" v-if="erro">{{ erro}}</p>
            </div>

</template>


<script>
import FormVerticalLabel from "@/Components/FormVertical/FormVerticalLabel.vue";

export default {
    components: {
        FormVerticalLabel
    },
    props: {
        forProp: String,
        label: String,
        required: Boolean,
        erro:Array
    },
}
</script>
