import {defineStore} from 'pinia'

export const useParagemStore = defineStore('paragem', {
    state: () => {
        return {
            paragens: [],
        }
    },
    getters: {},
    actions: {

        fetchParagens(relatedToType, relatedToId) {
            this.loading = true
            let params = {
                relatedToType: relatedToType,
                relatedToId: relatedToId
            }
            return axios.get(route('web-api.paragens.index', params)).then((res => {
                this.paragens = res.data
                this.loading = false
            }))
        },

    },
})
