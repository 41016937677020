<template>
    <div>
        <FormH @w-submit="submit" @submit.prevent>


            <div class="border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Serviço</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <Button @click="selecionarServicoIsOpen=true" outlined>+ Associar serviço</Button>

                            {{servico ? servico.codigo : 'Não associado'}}
                        </dd>
                    </div>
                </dl>
                <div >
                </div>
            </div>
            <FormGroup :erro="form.errors.motivo" forProp="motivo"
                       label="Motivo"
                       required>
             <Textarea v-model="form.motivo" :class="[errors.motivo ? 'p-invalid' : '']" autoResize
                       class="w-full"
                       rows="5"/>
            </FormGroup>

            <div class="pt-5">
                <div class="flex justify-end">

                    <button :disabled="form.processing" class="ml-3 w-btn-primary" type="submit">
                        <i v-if="form.processing" class="pi pi-spin pi-spinner mr-2"
                           style="font-size: 1rem"></i>
                        Guardar
                    </button>
                </div>
            </div>

        </FormH>


        <Dialog v-if="selecionarServicoIsOpen" v-model:visible="selecionarServicoIsOpen" modal header="Associar Serviço"
                :style="{ width: '50%' }">
            <SelecionarServico @resourceSelected="servicoSelected"
                tipoServico="INTERNACIONAL" :origemLocalidadeId="bilhete.origem_localidade_id" :destinoLocalidadeId="bilhete.destino_localidade_id"/>
        </Dialog>

    </div>
</template>

<script>
import FormGroup from "@/Components/FormHorizontal/FormGroup.vue";
import FormH from "@/Components/FormHorizontal/Form.vue";
import {useForm} from "laravel-precognition-vue";
import SelecionarServico from "@/Pages/Bilhetes/Components/SelecionarServico.vue";

export default {
    components: {SelecionarServico, FormH, FormGroup},
    props: {
        bilhete: {
            type: Object,
        },
    },
    data() {
        return {
            formInputs: {
                motivo: null,
                servico_id: null,
            },
            form: null,
            servico:null,
            selecionarServicoIsOpen:false
        }
    },
    beforeMount() {
        this.form = useForm('patch',
            route('web-api.bilhetes.por-utilizar', this.bilhete.id),
            this.formInputs);
    },
    methods: {
        servicoSelected(servico){
          this.servico = servico
            this.selecionarServicoIsOpen = false
        },
        submit() {
this.form.servico_id = this.servico ? this.servico.id : null
            this.form.submit().then((response) => {
                this.form.reset();
                this.$swal('Sucesso',
                    'Operação realizada com sucesso',
                    'success'
                );
                this.$emit('resourceCreated', response.data)
                this.$emit('closeSidebar')
                this.$emit('refresh')
            })

        }
    }
}
</script>


