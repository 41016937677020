<template>

                <div>
                    <WCard class="" iconTitulo="fa-solid fa-road" titulo="Paragens">
                        <template #content>
    <div class="inline-block min-w-full py-2 align-middle">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <BlockUI :blocked="loading">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                    <tr>
                        <th scope="col"
                            class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            #
                        </th>
                        <th scope="col"
                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Paragem
                        </th>
                        <th scope="col"
                            class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Hora
                            Partida
                        </th>
                    </tr>
                    </thead>
                    <draggable
                        class="divide-y divide-gray-200 bg-white"
                        v-model="paragens"
                        group="people"
                        @change="onChangeOrder"
                        item-key="id"
                        tag="tbody"
                    >


                        <template #item="{element}">
                            <TrEditParagem :paragem="element" v-if="isRowEditing(element.id)" @cancelar="stopEditingRow"
                                           @updated="fetchData"
                                           :relatedToType="relatedToType"
                                           :relatedToId="relatedToId"/>
                            <tr v-else>
                                <td scope="row"
                                    class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"><span
                                    class="text-xl">
              {{ element.ordem }}
            </span>

                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ element.localidade.name }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                    {{ element.hora_partida }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">


                                    <Button text class=" ml-4" @click="editRow(element.id)">
                                        <font-awesome-icon icon="fa-solid fa-pen" class="mr-2"/>
                                        Editar
                                    </Button>

                                    <Button text class=" ml-4" @click="deleteRow(element.id)">
                                        <font-awesome-icon icon="fa-solid fa-times" class="mr-2"/>
                                        Eliminar
                                    </Button>
                                </td>
                            </tr>
                        </template>


                    </draggable>
                    <TrCreateParagem @refresh="$emit('refresh')" @ParagemCreated="fetchData" :relatedToType="relatedToType"
                                     :relatedToId="relatedToId"/>
                </table>
            </BlockUI>
        </div>

    </div>
                        </template>
                    </WCard>
                </div>

</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import TrCreateParagem from "@/Pages/Paragens/Components/TrCreateParagem.vue";
import TrEditParagem from "@/Pages/Paragens/Components/TrEditParagem.vue";
import ButtonGroup from 'primevue/button';
import draggable from 'vuedraggable'
import {mapActions, mapWritableState} from "pinia";
import {useParagemStore} from "@/Pages/Paragens/Store/ParagemStore.js";
import BlockUI from 'primevue/blockui';

export default {
    name: 'TableParagens',
    components: {TrEditParagem, TrCreateParagem, AddressAutocomplete, ButtonGroup, draggable, BlockUI},
    props: {
        relatedToId: {
            type: Number,
            required: true
        },
        relatedToType: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            drag: false,
            editingRows: [],
            data: [],
            loading: false,
            adicionarParagemIsOpen: false,
            editParagemIsOpen: false,
            form: this.$inertia.form({
                paragem: null,
                hora_partida: null,
            }),
        }
    },
    computed: {
        ...mapWritableState(useParagemStore, ['paragens']),
    },
    mounted() {
        this.fetchData()
    },

    methods: {
        ...mapActions(useParagemStore, ['fetchParagens']),
        onChangeOrder(event) {
            this.loading = true
            this.paragens.forEach((item, index) => (item.ordem = index + 1))

            axios.put(route("web-api.paragens.update-order", {
                    relatedToType: this.relatedToType,
                    relatedToId: this.relatedToId
                }),
                {
                    paragens: this.paragens
                }).then(response => {
                    this.$emit('refresh')
                    this.loading = false
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Ordem das paragens atualizada!',
                        life: 3000
                    });
                }
            );

        },
        deleteRow(id) {
            if (!confirm('Tem a certeza que deseja eliminar?')) {
                return false
            }

            axios.delete(route("web-api.paragens.destroy", {paragem: id})).then(response => {
                this.$emit('refresh')
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Paragem eliminada!',
                    life: 3000
                });
                this.fetchData()
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Erro ao eliminar paragem!',
                    life: 3000
                });
            })

        },
        stopEditingRow(id) {
            this.editingRows = this.editingRows.filter(row => row.id !== id)
        },
        isRowEditing(id) {
            if (this.editingRows.length > 0) {
                return this.editingRows.some(row => row.id === id)
            }
        },
        editRow(id) {
            this.editingRows = [this.paragens.find(row => row.id === id)]
        },

        async fetchData() {
            this.loading = true;
            this.fetchParagens(this.relatedToType, this.relatedToId)
            this.loading = false;
        },

    }
}
</script>
<script setup>
</script>
