<template>
    <div>
        <WCard iconTitulo="fa-solid fa-road" :titulo="getTitulo">
            <template #actions>
                <!--                <Button v-if="tipoViagem==='REGRESSO'" class="p-button-outlined"
                                        @click="itinerarioAoContrarioDaIda()">
                                    <font-awesome-icon icon="fa-solid fa-wand-magic-sparkles"/>
                                    Auto-preencher
                                </Button>-->
                <Button class=" p-button-outlined" icon="fa-solid fa-plus" label="+ Novo"
                        @click="createItinerario()">

                </Button>

            </template>
            <template #content>

                <WEmptyState v-if="!itinerarios|| !itinerarios.length" titulo="Nenhum itinerário">
                    <Button class=" p-button-outlined" icon="fa-solid fa-plus" label="Adicionar Itinerário"
                            @click="modalServicoItinerarioIsOpen = true">

                    </Button>
                </WEmptyState>


                <ItinerarioDisplay :canEdit="editable" :itinerarios="itinerarios" @edit-itinerario="editItinerario"
                                   @update-itinerario="updatedServicoItinerario"/>


                <Drawer v-if
                            ="modalServicoItinerarioIsOpen" v-model:visible="modalServicoItinerarioIsOpen"
                        class="lg:!w-[60rem]"
                        position="right"
                        @hide="closeSidebar()">
                    <ServicoItinerarioCreateEdit v-if="modalServicoItinerarioIsOpen" :resource="itinerarioSelected"
                                                 :servico-id="servicoId"
                                                 @update-itinerario="updatedServicoItinerario"/>

                </Drawer>
            </template>
        </WCard>

    </div>
</template>

<script>
import ItinerarioDisplay from "@/Components/ItinerarioDisplay.vue";
import ServicoItinerarioCreateEdit from "@/Pages/Servicos/Servicos/components/Edit/ServicoItinerarioCreateEdit.vue";

export default {
    name: 'ServicoItinerario',
    components: {ItinerarioDisplay, ServicoItinerarioCreateEdit},
    props: {
        editable: {
            type: Boolean,
            default: true
        },
        tipoViagem: {
            type: String,
            default: 'IDA'
        },
        servicoId: {
            type: Number,
            required: true
        }
    },
    computed: {
        getTitulo() {
            return this.tipoViagem === 'IDA' ? 'Itinerário Ida' : 'Itinerário Regresso'
        },
    },

    data() {
        return {
            itinerarioSelected: null,
            itinerarios: [],
            modalServicoItinerarioIsOpen: false
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        itinerarioAoContrarioDaIda() {
            axios.post(route('web-api.servicos.itinerarios.regresso-a-partir-da-ida', {servico: this.servicoId}))
                .then((res) => {
                    this.itinerarios = res.data
                })
        },
        fetchData() {
            axios.get(route('web-api.servicos.itinerarios.index', {servico: this.servicoId}))
                .then((res) => {
                    this.itinerarios = res.data
                })
        },
        createItinerario() {
            this.itinerarioSelected = null
            this.modalServicoItinerarioIsOpen = true
        },
        editItinerario(itinerario) {
            this.itinerarioSelected = itinerario
            this.modalServicoItinerarioIsOpen = true
        },
        updatedServicoItinerario() {
            this.itinerarioSelected = null
            this.modalServicoItinerarioIsOpen = false
            this.fetchData()
        },
        closeSidebar() {
            this.modalServicoItinerarioIsOpen = false
        },
    },
}
</script>

