<template>
    <div>
        <span v-if="!servico.cliente_id" class="text-red-500">
            <font-awesome-icon icon="fa-solid fa-circle-info"/>
            Nenhum cliente selecionado</span>
        <span v-else>
          <AvatarUserName :avatar="servico.cliente.avatar" :name="servico.cliente.nome"/>
        </span>
        <div class="flex">
            <font-awesome-icon class="cursor-pointer" icon="fa-solid fa-pen-to-square"
                               @click="resourceClienteEditIsOpen=true"/>


            <div v-if="servico.cliente">
                |

                <font-awesome-icon class="cursor-pointer" icon="fa-solid fa-xmark"
                                   @click="updateCliente(null)"/>
            </div>
        </div>

        <Dialog v-if="resourceClienteEditIsOpen" v-model:visible="resourceClienteEditIsOpen"
                :breakpoints="{'960px': '75vw', '640px': '90vw'}"
                :fullscreen="true" :modal="true"
                :style="{width: '50vw'}" header="Cliente" @hide="closeSidebar()">

            <Cliente :cliente="servico.cliente" @update-cliente="updateCliente"/>
        </Dialog>

    </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import Cliente from "@/Pages/Servicos/Servicos/components/Cliente.vue";
import AvatarUserName from "@/Components/Display/AvatarUserName.vue";

export default {
    components: {Cliente, AvatarUserName},

    data() {
        return {
            resourceClienteEditIsOpen: false,
        }
    },
    computed: {
        ...mapWritableState(useServicoStore, ['servico'])
    },
    methods: {
        updateCliente(cliente) {
            if (cliente) {
                this.servico.cliente = cliente
                this.servico.cliente_id = cliente.id
            }

            this.$emit('update-cliente', cliente)
            this.resourceClienteEditIsOpen = false
        }
    },
}
</script>

