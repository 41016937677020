<template>
    <div>
        <WCard class="" iconTitulo="fa-solid fa-bus" titulo="Autocarro(s)" :subtitulo="!servico.servico_parent_id ? 'Ao adicionar mais do que 1 autocarro irá ser criado um serviço filho deste serviço' : ''">
            <template #actions>
                <Button v-if="!servico.servico_parent_id" class="p-button-outlined" @click="addServicoFilho()">
                    Adicionar
                    Autocarro
                </Button>
            </template>

            <template #content>
            <div class=" flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                    scope="col">
                                    Autocarro
                                </th>
                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                                    Lotação
                                </th>
                                <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" scope="col">
                                    Motoristas
                                </th>
                                <th class="relative py-3.5 pl-3 pr-4 sm:pr-0" scope="col"></th>
                            </tr></thead>
                            <tbody class="divide-y divide-gray-200">
                            <tr>
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    <TableServicoAutocarro :servico="servico" @updated="updateAutocarro"/>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <WInlineEdit :classCSS="[errors.lotacao ? 'p-invalid' : '']"
                                                 :value="servico.lotacao"
                                                 class="w-full"
                                                 field="lotacao"
                                                 type="number"
                                                 @saveField="updateServicoLotacao"
                                    />
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <TableServicoMotoristas :servico="servico" @updated="updateMotoristas"/>
                                </td>
                                <td>

                                </td>
                            </tr>
                            <!--FILHOS-->
                            <tr v-for="(servico_filho,index) in servico.servicos_filhos" :key="servico_filho">
                                <ServicoTdFilho :servico-filho-prop="servico_filho" @deleted="servicoFilhoEliminado"/>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            </template>
        </WCard>

    </div>
</template>

<script>
import RolesCreate from "@/Pages/Roles/Create.vue";
import {mapWritableState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import ServicoAutocarro from "@/Pages/Servicos/Servicos/components/Edit/ServicoAutocarro.vue";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import ServicoTdFilho from "@/Pages/Servicos/Servicos/components/Edit/ServicoTdFilho.vue";

export default {
    components: {
        ServicoTdFilho,
        TableServicoAutocarro, TableServicoMotoristas, WInlineEdit, ServicoAutocarro, RolesCreate
    },

    data() {
        return {
            resourceCreateIsOpen: false
        }
    },
    computed: {
        ...mapWritableState(useServicoStore, ['servico',]),
    },
    methods: {
        servicoFilhoEliminado(id) {
            this.servico.servicos_filhos = this.servico.servicos_filhos.filter(item => item.id !== id)
        },
        updateMotoristas(motoristas) {
            this.servico.motoristas = motoristas

        },
        updateAutocarro(autocarro) {
            if (autocarro) {
                this.servico.autocarro_id = autocarro.id
                this.servico.autocarro = autocarro
            } else {
                this.servico.autocarro_id = null
                this.servico.autocarro = null
            }

        },
        updateServicoLotacao(payload) {

            axios.patch(route('web-api.servicos.updateField', this.servico.id), payload)
                .then(response => {
                    this.servico.lotacao = payload['value']
                    this.$emit('refresh')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        addServicoFilho() {

            axios.post(route('web-api.servicos.servico-filhos.store', this.servico.id), this.servico)
                .then(response => {
                    this.servico.servicos_filhos.push(response.data)
                    this.$emit('refresh')
                })
                .catch(error => {
                    console.log(error)
                })

        },
        closeSidebar() {
            this.resourceCreateIsOpen = false
        }
    },
}
</script>
