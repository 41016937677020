<template>
    <div>


        <div class="grid grid-cols-1 gap-y-8 sm:grid-cols-1">


            <!--
                        <div class="sm:col-span-1">
                            <FormGroup :erro="form.errors.preco" forProp="preco" label="Preço" required>
                                <InputNumber v-model="form.preco" :class="[errors.preco ? 'p-invalid' : '']" currency="EUR"
                                             inputId="preco"
                                             locale="pt-PT" mode="currency"/>

                            </FormGroup>
                        </div>
            -->


            <div class="sm:col-span-1">
                <FormGroup :erro="form.errors.sub_contratante" forProp="sub_contratante" label="Sub Contratante"
                           required>
                    <Select v-model="form.sub_contratante" :options="sub_contratantes" option-value="id"
                            option-label="nome" filter
                            :class="[errors.sub_contratante ? 'p-invalid' : '']"
                            inputId="sub_contratante"/>
                </FormGroup>
            </div>
        </div>

        <hr/>


        <div class="flex justify-end mt-10  py-2">

            <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                    label="Guardar" @click="handleSubmit()">
            </Button>
        </div>
    </div>
</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import {useForm} from "laravel-precognition-vue";

export default {
    components: {AddressAutocomplete},
    props: {
        servicoId: {
            type: Number,
        },
    },
    data() {
        return {
            sub_contratantes: [],
            formInputs: {
                sub_contratante: null,
            },
            form: null,
        }
    },
    computed: {
        formMethod() {
            return 'patch'
        },
        formRoute() {
            return route('web-api.servicos.subcontratantes.delegar', this.servicoId)
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
    },
    mounted() {
        this.fetchSubContratantes()
    },
    methods: {
        fetchSubContratantes() {
            let params = {
                limit: 9999999999
            }
            axios.get(route('web-api.subcontratantes.index', params)).then((res => {
                this.sub_contratantes = res.data.data
            }))
        },
        closeSidebar() {
            this.resourceCreateIsOpen = false
        },
        handleSubmit() {
            this.form.submit().then((response) => {
                this.$emit('refresh')
                this.$emit('closeSidebar')
                let message = 'Os Serviços foram atualizados com sucesso!'
                if (this.$page.props.flash.toast) {
                    message = this.$page.props.flash.toast.summary
                }
                this.$swal('Serviço atualizado',
                    message,
                    'success'
                );
            });
        },

        changeFormValue(value) {
            this.form[value.field] = value.value
        }

    },
}
</script>
