<template>
    <TransitionRoot :show="sidebarOpen" as="template">
        <Dialog as="div" class="relative z-40 lg:hidden" @close="$emit('closeSidebar')">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                             enter-from="opacity-0" enter-to="opacity-100"
                             leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                             leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
            </TransitionChild>

            <div class="fixed inset-0 flex z-40">
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                 enter-from="-translate-x-full" enter-to="translate-x-0"
                                 leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                 leave-to="-translate-x-full">
                    <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 "
                                 style="background-color:#1E293B">
                        <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                         enter-to="opacity-100" leave="ease-in-out duration-300"
                                         leave-from="opacity-100" leave-to="opacity-0">
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    type="button"
                                    @click="$emit('closeSidebar')">
                                    <span class="sr-only">Fechar menu</span>
                                    <XIcon aria-hidden="true" class="h-6 w-6 text-white"/>
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="flex items-center justify-between w-full px-4">
                            <div>
                                <img alt="Winvel logo"
                                     class="h-8 w-auto"
                                     src="/logo.png" style=" -webkit-filter: invert(1);
                           filter: invert(1);"/>
                                <p class="text-white ml-2">BARQUENSE</p>
                            </div>
                            <div>
                                <a href="/dashboard"> <i
                                    class="pi pi-th-large text-white"></i></a>
                            </div>
                        </div>
                        <nav aria-label="Sidebar"
                             class="mt-5 flex-1 flex flex-col divide-y divide-gray-800 overflow-y-auto">
                            <div class="px-2 space-y-1">
                                <div v-for="item in navigation"
                                     :key="item.name"
                                >
                                    <div v-if="!item.childs" class="flex w-full">
                                        <Link
                                            v-show="item.pode"
                                            :aria-current="item.current ? 'page' : undefined"
                                            :class="[isUrl(item.route) ? 'bg-gray-800 text-white' : 'text-gray-300 hover:text-white hover:bg-gray-900', 'w-full group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md']"
                                            :href="item.href"
                                        >

                                            <div class="flex w-full justify-between">
                                                <span>  {{ item.name }}</span>
                                                <component :is="item.icon"
                                                           :class="[isUrl(item.route) ? 'text-blue-500':'text-gray-400']"
                                                           aria-hidden="true"
                                                           class="mr-4 flex-shrink-0 h-6 w-6 "/>
                                            </div>
                                        </Link>
                                    </div>

                                    <div v-if="item.childs">

                                        <div>
                                            <button
                                                class="flex items-center p-2 w-full text-base font-normal text-gray-300 rounded-lg transition duration-75 group hover:bg-gray-900 dark:text-white dark:hover:bg-gray-700"
                                                type="button"
                                                @click="item.open=!item.open">
                                                <div class="flex w-full justify-between">

                                            <span class="flex-1 text-left whitespace-nowrap"
                                                  sidebar-toggle-item>{{ item.name }}</span>

                                                    <div class="mr-6 text-xs">
                                                        <i v-if="!item.open" class="pi pi-chevron-left h-2 w-2"
                                                           style="font-size:0.5rem"></i>
                                                        <i v-if="item.open" class="pi pi-chevron-down h-2 w-2"
                                                           style="font-size:0.5rem"></i>
                                                    </div>
                                                    <component :is="item.icon"
                                                               :class="[isUrl(item.route) ? 'text-blue-500':'text-gray-400']"
                                                               aria-hidden="true"
                                                               class="mr-4 flex-shrink-0 h-6 w-6 "/>


                                                </div>
                                            </button>
                                        </div>
                                        <div>
                                            <ul v-if="item.open" id="dropdown-example"
                                                class="py-4 space-y-2 px-0 bg-gray-900">
                                                <li v-for="subItem in item.childs" :key="subItem.name">
                                                    <Link
                                                        v-show="subItem.pode"
                                                        :href="subItem.href"
                                                        class="text-gray-300 hover:text-white hover:bg-gray-900 w-full group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                                                    >

                                                        <div class="flex w-full justify-between">
                                                            <span>{{ subItem.name }}</span>
                                                            <component :is="subItem.icon"
                                                                       :class="[isUrl(subItem.route) ? 'text-blue-500':'text-gray-400']"
                                                                       aria-hidden="true"
                                                                       class="mr-4 flex-shrink-0 h-6 w-6 "/>
                                                        </div>
                                                    </Link>

                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </nav>
                    </DialogPanel>
                </TransitionChild>
                <div aria-hidden="true" class="flex-shrink-0 w-14">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>

<script>
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {XIcon} from "@heroicons/vue/outline";

export default {
    props: {
        sidebarOpen: Boolean,
        navigation: Object,
    },
    components: {
        Dialog,
        DialogPanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        XIcon
    },

    methods: {

        isUrl(...urls) {

            let currentUrl = this.$page.url.substr(1)
            if (urls[0] === '') {
                return currentUrl === ''
            }
            return urls.filter((url) => currentUrl.startsWith(url)).length
        },

    },

}
</script>
