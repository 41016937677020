<template>
    <div>
        <div class="grid grid-cols-1 gap-y-8 sm:grid-cols-2">


            <WLabel for="dt_partida">Lotação</WLabel>
            <div class="mt-2">
                <InputNumber v-model="form.lotacao" :class="[form.errors.lotacao ? 'p-invalid' : '']"
                             inputId="lotacao"
                />
            </div>


            <div class="flex justify-end mt-10  py-2">

                <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                        label="Guardar" @click="handleSubmit()">
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import {useForm} from "laravel-precognition-vue";

export default {
    props: {
        servicosIds: {
            type: Array,
        },
    },
    data() {
        return {
            formInputs: {
                lotacao: null,
                servicos_ids: null,
            },
            form: null,
        }
    },
    computed: {
        formMethod() {
            return 'patch'
        },
        formRoute() {
            return route('web-api.servicos.mass-edit.updateLotacao')
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
        if (this.servicosIds) {
            this.form.servicos_ids = this.servicosIds
        }
    },
    mounted() {
    },
    methods: {
        closeSidebar() {
            this.resourceCreateIsOpen = false
        },
        handleSubmit() {
            this.form.submit().then((response) => {
                this.$emit('refresh')
                this.$emit('closeSidebar')
                let message = 'Os Serviços foram atualizados com sucesso!'
                if (this.$page.props.flash.toast) {
                    message = this.$page.props.flash.toast.summary
                }
                this.$swal('Serviço atualizado',
                    message,
                    'success'
                );
            });
        },

        changeFormValue(value) {
            this.form[value.field] = value.value
        }

    },
}
</script>
