<template>
    <div>

        <WSkeleton v-if="loading"/>

        <DataTable v-if="data" :value="data.data" dataKey="id"
                   tableStyle="min-width: 60rem" :row-class="rowClass" v-model:selection="selectedServicos">
            <template #header v-if="selectedServicos.length">
                <MenuOpcoesServicosLista :selected-servicos="selectedServicos" @refresh="fetchData"/>
            </template>

            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column header="Cod">
                <template #body="slotProps">
                    <TableServicoEstado :show-full-label="true" :servico="slotProps.data" @updated="fetchData"/>
                </template>
            </Column>
            <Column header="Lotação">
                <template #body="slotProps">{{ slotProps.data.lotacao }}</template>
            </Column>
            <Column header="Preço">
                <template #body="slotProps">{{ slotProps.data.preco }} €</template>
            </Column>
            <Column header="Viagem Ida">
                <template #body="slotProps">
                    <TableHorasServico :editable="true" :show-full-dates="true" :dt-partida="slotProps.data.dt_partida"
                                       :servicoId="slotProps.data.id"
                                       :dt-regresso="slotProps.data.dt_regresso"
                                       :localidadeChegada="slotProps.data.localidade_chegada"
                                       :localidadePartida="slotProps.data.localidade_partida"
                                       @refresh="refreshAll"
                    />

                    <TableServicoAutocarroMotoristas :editable="true" :servico="slotProps.data"
                                                     @updated="fetchData"/>


                </template>
            </Column>
            <Column header="Regresso">
                <template #body="slotProps">
                    <div v-if="slotProps.data.servico_regresso">
                        <TableHorasServico :editable="true" :show-full-dates="true"
                                           :dt-partida="slotProps.data.servico_regresso.dt_partida"
                                           :servicoId="slotProps.data.servico_regresso.id"
                                           :dt-regresso="slotProps.data.servico_regresso.dt_regresso"
                                           :localidadeChegada="slotProps.data.servico_regresso.localidade_chegada"
                                           :localidadePartida="slotProps.data.servico_regresso.localidade_partida"
                                           @refresh="refreshAll"
                        />
                        <TableServicoAutocarroMotoristas :editable="true" :servico="slotProps.data.servico_regresso"
                                                         @updated="fetchData"/>
                    </div>
                    <span v-else class="text-gray-400"> Sem regresso</span>
                </template>
            </Column>


            <Column header="Ações">
                <template #body="slotProps">
                    <div class="flex space-x-4">
                        <font-awesome-icon class="cursor-pointer" icon="fas fa-eye" @click="showServico(slotProps.data)"
                                           v-if="slotProps.data.id != servicoIdSelected"/>
                        <MenuOpcoesServicoLinha :servico="slotProps.data" @refresh="refreshAll()"/>
                    </div>
                </template>
            </Column>

        </DataTable>


    </div>
</template>

<script>
import WDataTable from "@/Mixins/WDataTable.js";
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import {mapActions} from "pinia";
import Servico from "@/Models/Servico.js";
import TableHorasServico from "@/Pages/Servicos/Servicos/components/Table/TableHorasServico.vue";
import TableServicoGuiaDeTransporte from "@/Pages/Servicos/Servicos/components/Table/TableServicoGuiaDeTransporte.vue";
import ButtonGroup from "primevue/button";
import ServicoItinerario from "@/Pages/Servicos/Servicos/components/Edit/ServicoItinerario.vue";
import ItinerarioDisplay from "@/Components/ItinerarioDisplay.vue";
import MenuOpcoesServicoLinha from "@/Pages/Contratos/Ocasionais/Components/MenuOpcoesServicoLinha.vue";
import MenuOpcoesServicosLista from "@/Pages/Servicos/Components/MassEdit/MenuOpcoesServicosLista.vue";
import TableServicoAutocarroMotoristas
    from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarroMotoristas.vue";

export default {
    components: {
        TableServicoAutocarroMotoristas,
        MenuOpcoesServicosLista,
        MenuOpcoesServicoLinha,
        ItinerarioDisplay,
        ServicoItinerario,
        TableServicoGuiaDeTransporte,
        TableHorasServico,
        ButtonGroup
    },
    props: {
        contrato: {},
        servicoIdSelected: {}
    },
    mixins: [WDataTable, WCrudMixin],
    data() {
        return {
            selectedServicos: [],
            filters: {
                search: '',
                date: [null, null],
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'dt_partida',
                        sortOrder: 1,
                    },
                },
            },
        }
    },
    mounted() {
        this.fetchData()
        const unsubscribe = useGlobalModalsStore().$onAction(
            ({
                 name, // name of the action
                 store, // store instance, same as `someStore`
                 args, // array of parameters passed to the action
                 after, // hook after the action returns or resolves
                 onError, // hook if the action throws or rejects
             }) => {

                if (name === 'globalModalStoreRefreshServicos') {
                    this.fetchData()
                }

            }
        )
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),

        refreshAll() {
            this.fetchData()
            this.$emit('refresh')
        },
        showServico(servico) {
            this.openModalServicoEdit(servico.id)
        },
        async fetchData() {
            this.loading = true;
            this.data = await Servico
                .params({
                    contrato_id: this.contrato.id,
                    is_servico_regresso: false,
                    include_servico_regresso: true,
                    include_subcontratante: true
                })
                .page(this.filters.dt.page)
                .limit(9999)
                .orderBy(this.sortField)
                .get()
            this.loading = false;
        },
        rowClass(data) {
            return [{'bg-indigo-100': data.id == this.servicoIdSelected}];
        },

        closeSidebar() {
            this.editarLocalidadesIsOpen = false
            this.editarDatasIsOpen = false
        },
    }
}
</script>

