<template>
    <div>
        <div class="flex space-x-4"
             :class="editable ? 'cursor-pointer':''"
             @click="editable ? resourceCreateIsOpen=true : null" aria-haspopup="true" aria-controls="overlay_panel">

            <div class="font-medium text-gray-900 hover:text-gray-600">
                <p><span v-if="isSameDay(dtPartida,dt) && !showFullDates">{{
                        dayjs(dtPartida).format('HH:mm')
                    }} </span>
                    <span v-else class="text-indigo-500">{{
                            dtToFront(dtPartida)
                        }} </span>
                </p>
                <p v-if="localidadePartida">{{
                        localidadePartida.description
                    }}</p>
            </div>
            <div class="align-middle flex items-center">
                <font-awesome-icon icon="fa-solid fa-arrow-right"/>
            </div>
            <div class="font-medium text-gray-900 hover:text-gray-600" href="#">
                <p v-if="dtRegresso">
          <span v-if="isSameDay(dtRegresso,dt) && !showFullDates">
               {{
                  dayjs(dtRegresso).format('HH:mm')
              }}
          </span>
                    <span v-else class="text-indigo-500">{{
                            dtToFront(dtRegresso)
                        }} </span>
                </p>
                <p v-else class="text-red-500">Sem hora de chegada!</p>
                <p v-if="localidadeChegada">{{
                        localidadeChegada.description
                    }}</p>

            </div>
        </div>
        <Drawer v-model:visible="resourceCreateIsOpen" class="lg:!w-[60rem]"
                position="right"
                @hide="closeSidebar()">
            <ServicoEditDatasELocalidades :servicoId="servicoId"
                                          :dt="dt"
                                          :dtPartida="dtPartida"
                                          :dtRegresso="dtRegresso"
                                          :localidadePartida="localidadePartida"
                                          :localidadeChegada="localidadeChegada"
                                          @refresh="$emit('refresh')"
                                          @closeModalServico="closeSidebar"/>
        </Drawer>
    </div>
</template>

<script>
import Popover from 'primevue/popover';
import {useForm} from "laravel-precognition-vue";
import ServicoEditDatasELocalidades from "@/Pages/Servicos/Servicos/components/Edit/ServicoEditDatasELocalidades.vue";


export default {
    components: {
        ServicoEditDatasELocalidades,
        Popover,
    },
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        showFullDates: {
            type: Boolean,
            default: false
        },
        servicoId: {
            type: Number,
        },
        dt: {
            type: String,
            required: false,
        },
        dtPartida: {
            type: String,
            required: true,
        },
        dtRegresso: {
            type: String,
        },
        localidadePartida: {
            type: Object
        },
        localidadeChegada: {
            type: Object
        }
    },
    data() {
        return {
            formInputs: {
                dt_partida: null,
                dt_regresso: null,
                localidade_partida: null,
                localidade_chegada: null,
            },
            form: null,
            resourceCreateIsOpen: false,
        }
    },
    computed: {
        formMethod() {
            return 'patch'
        },
        formRoute() {
            if (this.servicoId) {
                return route('web-api.servicos.update.datas-e-localidades', this.servicoId)
            }
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
        if (this.servico) {
            this.form.dt_partida = this.dtPartida
            this.form.dt_regresso = this.dtRegresso
            this.form.localidade_partida = this.localidadePartida.full_json
            this.form.localidade_chegada = this.localidadeChegada.full_json

        }
    },
    mounted() {
        this.setResource();
    },
    methods: {
        closeSidebar() {
            this.resourceCreateIsOpen = false
        },
        handleSubmit() {
            this.form.submit().then((response) => {
                this.$emit('refresh')
                this.$emit('closeModalServico')
                this.$toast.add({
                    severity: 'success',
                    summary: 'Serviço atualizado',
                    detail: 'Serviço atualizado com sucesso',
                    life: 3000
                });
            });
        },
        setResource() {
            this.form.dt_partida = this.dtPartida;
            this.form.dt_regresso = this.dtRegresso;
            this.form.localidade_partida = this.localidadePartida.full_json;
            this.form.localidade_chegada = this.localidadeChegada.full_json;
        },
        changeFormValue(value) {
            this.form[value.field] = value.value
        }

    },
}
</script>

