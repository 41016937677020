<template>
    <Dialog v-model:visible="modalServicoEditIsOpen"
            :header=getTituloModal
            :modal="true"
            :style="{width: '90vw'}"
            @hide="closeModal()"
    >

        <div v-if="servico" class="bg-gray-100">
            <menuModalServico @closeModalServico="closeModal()" @refresh="globalModalStoreRefreshServicos"/>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8">
                <Tabs value="0">
                    <TabList>
                        <Tab value="0">Detalhes</Tab>
                        <Tab value="1">Contrato</Tab>
                        <Tab value="2">Itinerário</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel value="0">
                            <ServicoDetalhes @closeModalServico="closeModal()" :servicoId="servico.id"
                                             @refresh="refresh"/>

                        </TabPanel>
                        <TabPanel value="1">
                            <ServicoContrato v-if="servico.tipo_servico==='Ocasional'"
                                             @refresh="refresh"/>
                        </TabPanel>
                        <TabPanel value="2">
                            <div class="flex justify-end">
                                <MenuOpcoesServicoItinerarios :servico-id="servico.id"/>
                            </div>
                            <ServicoItinerario ref="itinerario_ida" tipo_viagem="IDA" :servico-id="servico.id"/>
                            <ServicoItinerario ref="itinerario_regresso" tipo-viagem="REGRESSO"
                                               v-if="servico.servico_regresso_id"
                                               :servico-id="servico.servico_regresso_id"/>
                        </TabPanel>
                    </TabPanels>

                </Tabs>


            </div>
        </div>
    </Dialog>
</template>


<script>
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import ServicoDetalhes from "@/Pages/Servicos/Servicos/components/Edit/ServicoDetalhes.vue";
import {mapActions, mapState, mapWritableState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import ServicoItinerario from "@/Pages/Servicos/Servicos/components/Edit/ServicoItinerario.vue";
import menuModalServico from "@/Pages/Servicos/Servicos/components/Edit/menuModalServico.vue";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import ServicoContrato from "@/Pages/Servicos/Servicos/components/ServicoContrato.vue";
import MenuOpcoesServicoItinerarios
    from '@/Pages/Servicos/Servicos/components/Edit/Itinerarios/MenuOpcesServicoItinerarios.vue'


export default {
    components: {
        MenuOpcoesServicoItinerarios,
        ServicoContrato,
        ServicoDetalhes,
        ServicoItinerario,
        menuModalServico,

    },
    name: 'ServicoEdit',
    mixins: [WCrudMixin],


    data() {
        return {
            tabActive: 0
        }
    },

    computed: {
        ...mapState(useServicoStore, ['servico']),
        ...mapWritableState(useGlobalModalsStore, ['modalServicoEditIsOpen', 'modalServicoIdSelected']),
        getTituloModal() {
            if (this.servico && !this.servico.servico_regular_id) {
                return 'Serviço Ocasional: ' + this.servico.codigo
            }
            if (this.servico && this.servico.servico_regular_id) {
                return 'Serviço Regular: ' + this.servico.codigo + ' - ' + this.servico.servico_regular.nome + ' - ' + this.servico.turno.nome
            }
        },
    },
    watch: {
        tabActive(newValue) {
            if (newValue == 2) {
                this.fetchItinerarios()
            }
        },
        modalServicoIdSelected(newValue) {
            if (newValue) {
                this.fetchServico(this.modalServicoIdSelected);
            }
        }
    },

    methods: {
        ...mapActions(useGlobalModalsStore, ['closeModalServicoEdit', 'globalModalStoreRefreshServicos']),
        ...mapActions(useServicoStore, ['fetchServico']),

        refresh() {
            this.globalModalStoreRefreshServicos()
            this.fetchServico(this.servico.id)
        },
        fetchItinerarios() {
            this.$refs.itinerario_ida.fetchData()
            if (this.servico.servico_regresso_id) {
                this.$refs.itinerario_regresso.fetchData()
            }
        },

        closeModal() {
            this.removeUrlParams('modalServicoId')

            this.closeModalServicoEdit()
        },

        deleteResource(resource) {
            if (!confirm('Tem a certeza que deseja eliminar? ')) {
                return false;
            }
            this.loadingDelete = true
            this.$inertia.delete(route(this.resourceRoute + '.destroy', this.modalServicoIdSelected), {
                onSuccess: () => {
                    this.loadingDelete = false
                    this.removeUrlParams('modalServicoId')
                    this.closeModalServicoEdit()
                    this.globalModalStoreRefreshServicos()
                    this.$swal('Eliminado',
                        'O Registo foi eliminado com sucesso!',
                        'success'
                    );


                }
            })
        },
    }

}
</script>
