<template>
    <div>


        <button
            class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 relative"
            type="button" @click="toggle">
            <span class="sr-only">Ver notificações</span>
            <BellIcon aria-hidden="true" class="h-8 w-8"/>
            <span v-if="items.length>0"
                  class="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{{
                    items.length
                }}</span>


        </button>

        <Popover ref="op">


            <div v-for="(item,index) in items" :key="index"
                 class="text-secondary-600 px-4 py-2 text-sm flex items-center cursor-pointer rounded-md transition-colors duration-150 hover:text-secondary-900 hover:bg-secondary-100 dark:text-secondary-400 dark:hover:bg-secondary-700 hover:bg-gray-100">

                <div v-if="item.tipo==='url'" class="w-full">
                    <div @click="goTo(item.url)">
                        {{ item.descricao }}
                    </div>
                    <div class="flex justify-between w-full">
                        <p class="text-xs text-gray-400">{{ item.timeAgo }}</p>

                        <button class="outline-none inline-flex justify-center items-center group transition-all ease-in duration-150 focus:ring-2 focus:ring-offset-2 hover:shadow-sm disabled:opacity-80 disabled:cursor-not-allowed rounded-full w-9 h-9     border text-slate-500 hover:bg-slate-100 ring-slate-200 dark:ring-slate-600 dark:border-slate-500
    dark:ring-offset-slate-800 dark:text-slate-400 dark:hover:bg-slate-700" type="button"
                                @click="marcaNotificacaoLida(item.id)">
                            <div>
                                <svg class="h-5 w-5" fill="none" stroke="currentColor"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 13l4 4L19 7" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"></path>
                                </svg>
                            </div>

                        </button>
                    </div>
                </div>


                <div v-if="item.tipo==='novidadeModal'" class="w-full">
                    <div @click="openNotification(item)">
                        <p class="text-green-500 font-bold">Novidade!</p>
                        <span class="font-bold"> {{ item.titulo }}</span>
                        <p class="text-indigo-500 text-sm">Ver detalhes -> </p>

                    </div>

                </div>

            </div>

            <div v-if="items.length===0">
                <div class="flex items-center justify-center mb-3">
                    <i class="pi pi-check text-green-500 " style="font-size:1.2rem"/>
                </div>
                <div class="flex items-center justify-center text-gray-400 text-sm">
                    Nenhuma notificação por ler
                </div>
            </div>

            <div class="w-full my-1 border-t border-secondary-200 dark:border-secondary-600"></div>


            <div class="bg-gray-50 py-4 cursor-pointer hover:bg-gray-100">
                <Link href="/notificacoes">
                    <div class="flex items-center justify-center text-gray-400 text-sm">
                        Ver todas ->
                    </div>
                </Link>
            </div>


        </Popover>


        <Dialog v-if="selectedNotification" v-model:visible="isNotificationOpen"
                :breakpoints="{'960px': '75vw', '640px': '90vw'}"
                :header="selectedNotification.titulo"
                :modal="true" :style="{width: '50vw'}">

            <div v-if="selectedNotification" class="whitespace-pre-line" v-html="selectedNotification.descricao"></div>
            <template #footer>
                <Button autofocus icon="pi pi-check" label="Marcar lida"
                        @click="marcaNotificacaoLida(selectedNotification.id)"/>
            </template>
        </Dialog>
    </div>
</template>

<script>

import Popover from 'primevue/popover';
import {BellIcon} from "@heroicons/vue/outline";
import Badge from 'primevue/badge';
import Dialog from 'primevue/dialog'

export default {
    components: {
        Badge,
        Popover,
        BellIcon,
        Dialog
    },

    mounted() {
        this.fetchData()
    },
    data() {
        return {
            isNotificationOpen: false,
            selectedNotification: null,
            items: []
        }
    },
    methods: {

        openNotification(notification) {
            this.isNotificationOpen = true
            this.selectedNotification = notification
        },
        marcaNotificacaoLida(notificacaoId) {
            const payload = {
                notificacao_id: notificacaoId
            }
            axios.post('/web-api/notificacoes/marcar-lida', payload).then((res) => {
                this.fetchData()
                this.selectedNotification = null
                this.isNotificationOpen = false
            })
        },
        goTo(url) {
            window.location.href = url
        },
        toggle(event) {
            this.$refs.op.toggle(event);
        },

        fetchData() {
            axios.get('/web-api/notificacoes').then((res) => {
                this.items = res.data
            })
        }
    }
}
</script>
