<template>
    <div>
        <div class="flex space-x-4" v-if="!servico.subcontratante_id">
            <TableServicoAutocarro :servico="servico" @updated="refreshAll()"/>
            <TableServicoMotoristas :servico="servico" @updated="refreshAll()"/>
        </div>
        <div class="flex space-x-4" v-if="servico.subcontratante_id">
            <WBadge> delegado a
                {{ servico.subcontratante.nome }}
            </WBadge>
            <p class="text-sm text-indigo-400"></p>
        </div>
    </div>
</template>

<script>
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";

export default {
    components: {TableServicoMotoristas, TableServicoAutocarro},
    props: {
        servico: {},
    },

    methods: {
        refreshAll() {
            this.$emit('refreshAll')
        }
    },
}
</script>

