<template>
    <div>
        <h3 v-if="resource" class="text-3xl font-bold">Editar Nota</h3>
        <h3 v-else class="text-3xl font-bold">Criar Nota</h3>

        <div>
            <form @submit.prevent="handleSubmit">
                <BreezeValidationErrors class="mb-4"/>


                <BreezeLabel for="descricao" required value="Descrição"/>
                <Textarea v-model="form.descricao" :class="[errors.descricao ? 'p-invalid' : '']" class="w-full"

                />


                <div class="flex justify-end mt-10 bg-gray-100 py-2">
                    <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                            label="Guardar" @click="handleSubmit()">
                    </Button>
                </div>

            </form>

        </div>

    </div>
</template>


<script>
import Textarea from "primevue/textarea";

export default {
    components: {
        Textarea,

    },
    props: {
        resource: {
            type: Object,
        },
        notable_id: {
            type: Number,
        },
        notable_type: {
            type: String,
        },
    },
    name: 'CreateEditNota',
    data() {
        return {
            form: this.$inertia.form({
                notable_type: this.notable_type,
                notable_id: this.notable_id,
                descricao: null,
            }),
        }
    },

    mounted() {
        this.setResource();
    },

    methods: {

        setResource() {
            if (this.resource) {
                this.form.descricao = this.resource.descricao
            }
        },
        closeSidebar() {
            this.form.reset();
            this.$emit('closeSidebar')
            this.$emit('refresh');
        },
        handleSubmit() {


            if (!this.resource) {
                this.form.post(route("notas.store"),
                    {
                        onSuccess: () => {
                            this.closeSidebar();
                            this.$swal('Nota Inserida',
                                'A Nota foi inserido com sucesso!',
                                'success'
                            );
                        },
                    }
                );
            } else {
                this.form.patch(route("notas.update", this.resource.id),
                    {
                        onSuccess: () => {
                            this.closeSidebar();
                            this.$swal('Nota editado',
                                'A Nota foi editada com sucesso!',
                                'success'
                            );
                        },
                    }
                );
            }
        },

    }

}
</script>
