import {defineStore} from 'pinia'

export const useServicoItinerarioStore = defineStore('servico_itinerario', {
    state: () => {
        return {
            modalItinerarioIsOpen: false,
            itinerarios: null,
        }
    },
    getters: {},
    actions: {
        async fetchItinerarioServico(servico_id) {
            await axios.get(route('web-api.servicos.itinerarios.index', {servico: servico_id}))
                .then((res) => {
                    this.itinerarios = res.data
                })

        },
        openModalServicoItinerario(servico_id) {
            this.fetchItinerarioServico(servico_id)
            this.modalItinerarioIsOpen = true
        },


        closeModalServicoItinerario() {
            this.modalItinerarioIsOpen = false
            this.itinerarios = null
        },

    },
})
