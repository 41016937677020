<template>

    <FormH :title="resource ? 'Editar tipo': 'Criar tipo'" @w-submit="submit" @submit.prevent>

        <FormGroup :erro="form.errors.nome" forProp="nome" label="Nome" required>
            <InputText v-model="form.nome" disabled :invalid="form.errors.nome"
                       @change="form.validate('nome')"/>
        </FormGroup>

        <FormGroup :erro="form.errors.desconto" forProp="desconto" label="Desconto ( % )"
                   required>
            <InputNumber :max="100" :min="0" v-model="form.desconto" :invalid="form.errors.desconto"
                         @change="form.validate('desconto')"/>
        </FormGroup>


        <div class="pt-5">
            <div class="flex justify-end">

                <button :disabled="form.processing" class="ml-3 w-btn-primary" type="submit">
                    <i v-if="form.processing" class="pi pi-spin pi-spinner mr-2"
                       style="font-size: 1rem"></i>
                    Guardar
                </button>
            </div>
        </div>

    </FormH>

</template>


<script>
import FormH from '@/Components/FormHorizontal/Form.vue'
import FormGroup from '@/Components/FormHorizontal/FormGroup.vue'
import Select from "primevue/select";
import {useForm} from 'laravel-precognition-vue';
import Textarea from "primevue/textarea";

export default {
    components: {
        FormH,
        FormGroup,
        Select,
        Textarea
    },
    name: 'TiposCreate',

    props: {
        resource: Object,
        relatedToType: String,
        relatedToId: Number,
    },
    data() {
        return {
            formInputs: {
                nome: null,
                desconto: null,
                related_to_id: this.relatedToId,
                related_to_type: this.relatedToType
            },
            form: null
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
        if (this.resource) {
            this.form.nome = this.resource.nome
            this.form.desconto = this.resource.desconto
            this.form.related_to_id = this.resource.related_to_id
            this.form.related_to_type = this.resource.related_to_type

        }
    },
    mounted() {

    },
    computed: {
        formMethod() {
            return this.resource ? 'patch' : 'post'
        },
        formRoute() {
            return this.resource ? route('web-api.bilhetes.tipos.update', this.resource.id) : route('web-api.bilhetes.tipos.store')
        }
    },
    methods: {
        submit() {

            this.form.submit().then((response) => {
                this.form.reset();
                this.$swal('Sucesso',
                    'Operação realizada com sucesso',
                    'success'
                );
                this.$emit('resourceCreated', response.data)
                this.$emit('closeSidebar')
                this.$emit('refresh')
            })

        }
    }

}
</script>
