import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputSearch from "@/Components/InputSearch.vue";
import Calendar from 'primevue/calendar';
import dayjs from "dayjs";

const WDataTable = {
    components: {
        Column,
        DataTable,
        InputSearch,
        Calendar
    },
    data() {
        return {
            data: null,
            removeWatchFilters: false,
            loading: true,
            filterDate: null,
            filters: {
                search: null,
                date: [null, null],
                rows_per_page: 10,
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'dt',
                        sortOrder: -1,
                    },
                },
            },
        };
    },

    watch: {
        filters: {
            handler: function (newValue) {
                if (this.removeWatchFilters) {
                    return false
                }
                this.fetchData();
            },
            deep: true
        },
        filterDate(newValue) {
            if (newValue && newValue[1]) {
                this.filters.date[0] = dayjs(newValue[0]).format('YYYY-MM-DD')
                this.filters.date[1] = dayjs(newValue[1]).format('YYYY-MM-DD')
            }
            if (!newValue) {
                this.filters.date[0] = null;
                this.filters.date[1] = null
            }
        }
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        sortField() {
            const sortField = this.filters.dt.sort.sortField;
            if (this.filters.dt.sort.sortOrder === 1) {
                return sortField
            } else {
                return '-' + sortField
            }
        }
    },
    methods: {
        onFilter(event) {
            console.log(event)
        },
        onPage(event) {
            console.log('aqui')
            console.log(event)
            this.filters.dt.page = event.page + 1
            this.filters.rows_per_page = event.rows
            //  this.fetchData();
        },
        onSort(event) {

            this.filters.dt.sort.sortField = event.sortField
            this.filters.dt.sort.sortOrder = event.sortOrder
            this.filters.dt.page = 1
            //this.fetchData();
        },
        closeSidebar() {
            this.resourceToEdit = null;
            this.resourceEditIsOpen = false;
            this.resourceCreateIsOpen = false;
        },
    },

};

export default WDataTable;
