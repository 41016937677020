<template>

    <div class="flex items-center">
        <tippy class="flex" arrow
               interactive sticky z-index="9999">
            <div class="h-10 w-10 flex-shrink-0">
                <img :src="avatar" alt=""
                     class="h-10 w-10 rounded-full">
            </div>
            <div class="ml-4">
                <div class="font-medium text-gray-900">{{ name }}</div>
                <div v-if="subtitle" class="text-red-500 text-xs">{{ subtitle }}</div>
            </div>


            <template #content>
                <div class="rounded-lg bg-gray-900 shadow-sm ring-1 ring-gray-900/5 z-auto">
                    <dl class="flex flex-wrap">
                        <div class="mt-4 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 ">
                            <dt class="flex-none">
                                <font-awesome-icon class="text-gray-400" icon="fa-regular fa-user"/>
                            </dt>
                            <dd class="text-sm font-medium leading-6 text-gray-400">{{ name }}</dd>
                        </div>
                        <div class="mt-4 flex w-full flex-none gap-x-4 px-6" v-if="telemovel">
                            <dt class="flex-none">
                                <font-awesome-icon class="text-gray-400" :icon="['fas', 'phone']"/>
                            </dt>
                            <dd class="text-sm leading-6 text-gray-400">
                                {{ telemovel }}
                            </dd>
                        </div>
                        <div class="my-4 flex w-full flex-none gap-x-4 px-6" v-if="email">
                            <dt class="flex-none">
                                <font-awesome-icon class="text-gray-400" :icon="['far', 'envelope']"/>
                            </dt>
                            <dd class="text-sm leading-6 text-gray-400">
                                {{ email }}
                            </dd>
                        </div>
                    </dl>
                </div>
            </template>
        </tippy>


    </div>
</template>

<script>

export default {
    props: {
        avatar: {
            type: String,
        },
        name: {
            type: String
        },
        subtitle: {
            type: String
        },
        telemovel: {
            type: String
        },
        email: {
            type: String
        }
    },
    components: {},
    data() {
        return {
            tooltipTimeout: null,
            tooltipVisible: false,
        }
    },
    methods: {
        toggle(event) {
            console.log(this.$refs.op.$.data.visible)

        },
        startTooltipTimer(event) {
            console.log(event)
            // Start the timer when mouse enters, but don't show the tooltip immediately
            this.tooltipTimeout = setTimeout(() => {

                this.tooltipVisible = true; // Show the tooltip if the timer completes
                this.$refs.op.show(event);
            }, 1000); // 1 second (1000ms) delay
        },
        clearTooltipTimer(event) {
            // Clear the timer and hide the tooltip when mouse leaves
            clearTimeout(this.tooltipTimeout); // Cancel the tooltip show if mouse leaves before 1 second
            //this.$refs.op.toggle(event);
            this.tooltipVisible = false; // Hide the tooltip if it's visible
        }
    },
}
</script>
