const WCrudMixin = {

    data() {
        return {
            loadingDelete: false,
            selectedResource: null,
            resourceEditIsOpen: false,
            resourceCreateIsOpen: false,
            resourcePrintIsOpen: false,
            resourceIsOpen: false,
        };
    },

    methods: {
        deleteResource(resource) {
            if (!confirm('Tem a certeza que deseja eliminar? ')) {
                return false;
            }
            this.loadingDelete = true
            this.$inertia.delete(route(this.resourceRoute + '.destroy', resource.id), {
                onSuccess: () => {
                    this.fetchData();
                    this.loadingDelete = false
                    this.$swal('Eliminado',
                        'O Registo foi eliminado com sucesso!',
                        'success'
                    );

                }
            })
        },
        editResource(resource) {
            this.selectedResource = resource
            this.resourceEditIsOpen = true
            this.resourceIsOpen = true
        },
        refresh() {
            this.$inertia.reload()
        },
        closeSidebar() {
            this.selectedResource = null
            this.resourcePrintIsOpen = false;
            this.resourceEditIsOpen = false;
            this.resourceCreateIsOpen = false;
            this.resourceIsOpen = false;
        }

    },

};

export default WCrudMixin;
