<template>

    <BreezeValidationErrors class="mb-4"/>
    <!-- DETALHES-->
    <WCard iconTitulo="fa-solid fa-circle-info" titulo="Detalhes">
        <template #content>


            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.preco" forProp="preco" label="Preço" required>
                        <InputNumber v-model="form.preco" :class="[errors.preco ? 'p-invalid' : '']" currency="EUR"
                                     inputId="preco"
                                     locale="pt-PT" mode="currency"/>
                        <p v-if="form.preco" class="ml-2">taxa de reserva: {{ precoTaxa }} €</p>
                    </FormGroup>

                </div>


                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.lotacao" forProp="lotacao" label="Lotação">
                        <InputNumber v-model="form.lotacao" :class="[errors.lotacao ? 'p-invalid' : '']"
                                     inputId="lotacao"
                        />
                    </FormGroup>
                </div>


                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.internacional" forProp="internacional" label="Internacional">
                        <Checkbox v-model="form.internacional" :binary="true" :value="true"
                                  inputId="internacional" name="internacional"/>
                    </FormGroup>
                </div>
                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.tcc" forProp="tcc" label="TCC">
                        <div class="card flex flex-wrap justify-content-center gap-3">
                            <div class="flex align-items-center">
                                <Checkbox v-model="form.tcc" :binary="true"/>
                            </div>
                        </div>
                    </FormGroup>
                </div>
                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.tipo_cinto" forProp="tipo_cinto" label="Tipo de cinto">
                        <Select v-model="form.tipo_cinto" :options="[null,'2 Pontos','3 Pontos']"
                                placeholder="Selecione o tipo de cinto"/>
                    </FormGroup>
                </div>
                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.bancos_elevatorios" forProp="bancos_elevatorios"
                               label="Bancos Elevatórios">
                        <div class="mt-2">
                            <InputText v-model="form.bancos_elevatorios"
                                       :class="[errors.bancos_elevatorios ? 'p-invalid' : '']"
                                       inputId="bancos_elevatorios"
                            />
                        </div>
                    </FormGroup>
                </div>

                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.finalidade" forProp="finalidade" label="Finalidade">
                        <InputText v-model="form.finalidade" :class="[errors.finalidade ? 'p-invalid' : '']"
                                   inputId="finalidade"
                        />
                    </FormGroup>
                </div>
            </div>

        </template>
    </WCard>

    <!--VIAGEM-->

    <WCard class="mt-20" iconTitulo="fa-regular fa-calendar" titulo="Viagem">
        <template #content>

            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.localidade_partida" forProp="origem" label="Origem" required>
                        <AddressAutocomplete v-model="form.localidade_partida"
                                             :classCSS="form.errors.localidade_partida ? 'p-invalid' : 'w-full'"
                                             :is-edit-prop="true"
                                             :valueProp="form.localidade_partida"
                                             field="localidade_partida"
                                             @changeFormValue="changeFormValue"/>
                    </FormGroup>

                </div>


                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.localidade_chegada" forProp="destino" label="Destino" required>
                        <AddressAutocomplete v-model="form.localidade_chegada"
                                             :classCSS="form.errors.localidade_chegada ? 'p-invalid' : 'w-full'"
                                             :is-edit-prop="true"
                                             :valueProp="form.localidade_chegada"
                                             field="localidade_chegada"
                                             @changeFormValue="changeFormValue"/>
                    </FormGroup>

                </div>


                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.dt_partida" forProp="dt_partida" label="Data de partida" required>
                        <InputText v-model="form.dt_partida" :class="[errors.dt_partida ? 'p-invalid' : '']"
                                   type="datetime-local"/>
                    </FormGroup>
                </div>


                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.dt_regresso" forProp="dt_regresso" label="Data de chegada" required>
                        <WInputDataChegada v-model="form.dt_regresso" :dt-partida="form.dt_partida"
                                           :origem-localidade="form.localidade_partida"
                                           :destino-localidade="form.localidade_chegada" :model-value="form.dt_regresso"
                                           :class="[errors.dt_regresso ? 'p-invalid' : '']"
                                           @preencher="preencherDtChegada"/>
                    </FormGroup>
                </div>


                <div class="sm:col-span-2">
                    <FormGroup :erro="form.errors.tem_servico_regresso" forProp="tem_servico_regresso"
                               label="Criar serviço de Regresso ?">
                        <Checkbox v-model="form.tem_servico_regresso" :binary="true" :value="true"
                                  inputId="servico_regresso" name="servico_regresso"/>
                    </FormGroup>
                </div>
            </div>

            <!--REGRESSO-->
            <div v-show="form.tem_servico_regresso" class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                <div class="col-span-1 sm:col-span-2 mb-10">
                    Serviço de Regresso
                    <hr/>
                </div>

                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.regresso_localidade_partida" forProp="regresso_localidade_partida"
                               label="Origem" required>
                        <AddressAutocomplete v-model="form.regresso_localidade_partida"
                                             :classCSS="form.errors.regresso_localidade_partida ? 'p-invalid' : 'w-full'"
                                             :is-edit-prop="true"
                                             :valueProp="form.regresso_localidade_partida"
                                             field="localidade_partida"
                                             @changeFormValue="changeFormValue"/>
                    </FormGroup>
                </div>


                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.regresso_localidade_chegada" forProp="regresso_localidade_chegada"
                               label="Destino" required>
                        <AddressAutocomplete v-model="form.regresso_localidade_chegada"
                                             :classCSS="form.errors.regresso_localidade_chegada ? 'p-invalid' : 'w-full'"
                                             :is-edit-prop="true"
                                             :valueProp="form.regresso_localidade_chegada"
                                             field="localidade_chegada"
                                             @changeFormValue="changeFormValue"/>
                    </FormGroup>
                </div>
                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.regresso_dt_partida" forProp="regresso_dt_partida"
                               label="Data de partida" required>
                        <InputText v-model="form.regresso_dt_partida"
                                   :class="[errors.regresso_dt_partida ? 'p-invalid' : '']"
                                   type="datetime-local"/>
                    </FormGroup>
                </div>

                <div class="sm:col-span-1">
                    <FormGroup :erro="form.errors.regresso_dt_regresso" forProp="regresso_dt_regresso"
                               label="Data de chegada" required>
                        <WInputDataChegada v-model="form.regresso_dt_regresso"
                                           :dt-partida="form.regresso_dt_partida"
                                           :origem-localidade="form.regresso_localidade_partida"
                                           :destino-localidade="form.regresso_localidade_chegada"
                                           :model-value="form.regresso_dt_regresso"
                                           :class="[errors.regresso_dt_regresso ? 'p-invalid' : '']"
                                           @preencher="preencherRegressoDtChegada"/>
                    </FormGroup>
                </div>


            </div>


        </template>
    </WCard>

    <WCard titulo="Observações">
        <template #content>
            <Textarea v-model="form.observacoes" class="w-full" rows="3"></Textarea>

        </template>
    </WCard>

    <div class="flex justify-end mt-10 bg-gray-100 py-2">
        <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                label="Guardar" @click="handleSubmit()">
        </Button>
    </div>
</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import LocalidadesMixin from "@/Mixins/LocalidadesMixin.js";
import WBadge from "@/Components/WBadge.vue";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import {mapActions, mapWritableState} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import WInputDataChegada from "@/Components/WInputDataChegada.vue";

export default {
    components: {
        WInputDataChegada,
        AddressAutocomplete,
        WBadge,
        InputNumber,
        Checkbox,
        Textarea
    },
    name: 'ServicoCreate',
    mixins: [WCrudMixin, LocalidadesMixin],
    emits: ['closeModal'],
    data() {
        return {
            form: this.$inertia.form({
                dt_partida: null,
                dt_regresso: null,
                localidade_partida: null,
                localidade_chegada: null,
                internacional: false,
                preco: null,
                tcc: null,
                bancos_elevatorios: null,
                tipo_cinto: null,
                finalidade: null,
                lotacao: null,
                observacoes: null,
                contrato_id: null,
                orcamento_id: null,
                tem_servico_regresso: false,
                regresso_dt_partida: null,
                regresso_dt_regresso: null,
                regresso_localidade_partida: null,
                regresso_localidade_chegada: null,
            }),
        }
    },
    computed: {
        ...mapWritableState(useGlobalModalsStore, ['tipoServico', 'contrato_id', 'orcamento_id', 'modalServicoCreateIsOpen', 'globalModalStoreRefreshServicos']),
        isDialogOpen() {
            return this.modalServicoCreateIsOpen && this.tipoServico === 'Ocasional'
        },
        precoTaxa() {
            if (this.form.preco) {
                return this.form.preco * 0.25
            }
        }
    },
    watch: {
        'form.tem_servico_regresso'(newValue, oldValue) {
            if (newValue === true) {
                this.form.regresso_dt_partida = this.form.dt_regresso
                this.form.regresso_dt_regresso = null
                this.form.regresso_localidade_partida = this.form.localidade_chegada
                this.form.regresso_localidade_chegada = this.form.localidade_partida
            }
        }
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),
        closeModal() {
            this.modalServicoCreateIsOpen = false
        },
        handleSubmit() {
            this.form.contrato_id = this.contrato_id
            this.form.orcamento_id = this.orcamento_id
            this.form.post(route("servicos.store"),
                {
                    onSuccess: () => {
                        this.closeModal()
                        this.globalModalStoreRefreshServicos()
                        this.$toast.add({
                            icon: 'pi pi-bell',
                            severity: 'success',
                            summary: 'Serviço Inserido',
                            life: 3000
                        });
                        let servico_created_id = this.$page.props.flash.created_id
                        this.addUrlParams('modalServicoId', servico_created_id)
                        this.openModalServicoEdit(servico_created_id)

                    },
                }
            );
        },
        preencherDtChegada(payload) {
            this.form.dt_regresso = payload.dt_chegada
        },
        preencherRegressoDtChegada(payload) {
            this.form.regresso_dt_regresso = payload.dt_chegada
        },
    },
}
</script>

