<template>
    <div>


                <div class="grid grid-cols-1 gap-y-8 sm:grid-cols-2">


                    <div class="sm:col-span-1">
                        <WLabel
                            for="localidade_partida">Origem
                        </WLabel>
                        <div class="mt-2">
                            <AddressAutocomplete v-model="form.localidade_partida"
                                                 :classCSS="[errors.localidade_partida ? 'p-invalid' : 'w-full']"
                                                 :valueProp="form.localidade_partida"
                                                 field="localidade_partida"
                                                 @changeFormValue="changeFormValue"/>
                        </div>
                    </div>


                    <div class="sm:col-span-1">
                        <WLabel
                            for="localidade_chegada">Destino
                        </WLabel>
                        <div class="mt-2">
                            <AddressAutocomplete v-model="form.localidade_chegada"
                                                 :classCSS="[errors.localidade_chegada ? 'p-invalid' : 'w-full']"
                                                 :valueProp="form.localidade_chegada"
                                                 field="localidade_chegada"
                                                 @changeFormValue="changeFormValue"/>
                        </div>
                    </div>
                </div>

                <hr/>


                <div class="flex justify-end mt-10  py-2">

                    <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                            label="Guardar" @click="handleSubmit()">
                    </Button>
                </div>
    </div>
</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import {useForm} from "laravel-precognition-vue";

export default {
    components: {AddressAutocomplete},
    props: {
        servicosIds: {
            type: Array,
        },
    },
    data() {
        return {
            formInputs: {
                localidade_partida: null,
                localidade_chegada: null,
                servicos_ids: null,
            },
            form: null,
        }
    },
    computed: {
        formMethod() {
            return 'patch'
        },
        formRoute() {
                return route('web-api.servicos.mass-edit.updateLocalidades')
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
        if (this.servicosIds) {
            this.form.servicos_ids = this.servicosIds
        }
    },
    mounted() {
    },
    methods: {
        closeSidebar(){
            this.resourceCreateIsOpen = false
        },
        handleSubmit() {
            this.form.submit().then((response) => {
                this.$emit('refresh')
                this.$emit('closeSidebar')
                let message = 'Os Serviços foram atualizados com sucesso!'
                if (this.$page.props.flash.toast) {
                    message = this.$page.props.flash.toast.summary
                }
                this.$swal('Serviço atualizado',
                    message,
                    'success'
                );
            });
        },

        changeFormValue(value) {
            this.form[value.field] = value.value
        }

    },
}
</script>
