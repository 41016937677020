<template>
    <div>

        <div class="cursor-pointer" @click="editMotoristas(servico)">
            <WBadge
                v-if="servico.motoristas && servico.motoristas.length"
                color="blue">
                <div class="w-32 h-8 flex items-center">
                    <font-awesome-icon icon="fa-solid fa-clipboard-user"/>
                    <div>
                        <p v-for="(motorista,index) in servico.motoristas"
                           :key="index"
                           class=" ml-2">
                            <font-awesome-icon
                                v-if="motorista.pivot && !motorista.pivot.mensagem_enviada_em"
                                class="text-red-500"
                                icon="fa-solid fa-paper-plane"/>
                            <font-awesome-icon
                                v-if="motorista.pivot && motorista.pivot.mensagem_enviada_em && !motorista.pivot.confirmado_at"
                                class="text-yellow-500"
                                icon="fa-solid fa-paper-plane"/>
                            <font-awesome-icon
                                v-if="motorista.pivot && motorista.pivot.mensagem_enviada_em && motorista.pivot.confirmado_at"
                                class="text-green-500"
                                icon="fa-solid fa-circle-check"/>

                            {{
                                motorista.nome
                            }}
                        </p>
                    </div>
                </div>
            </WBadge>
            <WBadge v-else color="red">
                <div class="w-26 h-8 flex items-center">
                    <font-awesome-icon icon="fa-solid fa-clipboard-user"/>
                    <p class=" px-2"> Sem motorista</p>
                </div>
            </WBadge>

        </div>

        <Dialog v-if="modalChooseMotoristasIsOpen" v-model:visible="modalChooseMotoristasIsOpen"
                :modal="true"
                :style="{width: '90vw'}" header="Motoristas" @hide="closeSidebar()">

            <ChooseMotoristas :dt_partida_servico="servico.dt_partida"
                              :dt_regresso_servico="servico.dt_regresso"
                              :motoristas-chosen="servico.motoristas"
                              :servico_id="servico.id"
                              @update-motoristas="updateMotoristas"
            />
        </Dialog>

    </div>
</template>

<script>
import WBadge from "@/Components/WBadge.vue";
import ChooseMotoristas from "@/Pages/Servicos/Servicos/components/ChooseMotoristas.vue";
import {mapActions} from "pinia";
import {useDashboardStore} from "@/Store/DashboardStore.js";
import ChooseAutocarro from "@/Pages/Servicos/Servicos/components/ChooseAutocarro.vue";
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import TableServicoEstado from "@/Pages/Servicos/Servicos/components/Table/TableServicoEstado.vue";

export default {
    components: {TableServicoEstado, TableServicoAutocarro, ChooseAutocarro, ChooseMotoristas, WBadge},

    props: {
        servico: {
            type: Object,
        },
    },
    data() {
        return {
            modalChooseMotoristasIsOpen: false,
        }
    },
    computed: {},
    methods: {
        ...mapActions(useDashboardStore, ['updateServico']),
        editMotoristas() {
            this.modalChooseMotoristasIsOpen = true
        },
        updateMotoristas(motoristas) {
            const payload = {
                motoristas: motoristas
            }
            axios.patch(route('web-api.servicos.motoristas.update', this.servico.id), payload).then((res => {
                this.modalChooseMotoristasIsOpen = false
                if (res.data.total_notificacoes > 0) {
                    this.$toast.add({
                        icon: 'pi pi-bell',
                        severity: 'success',
                        summary: res.data.total_notificacoes + ' Notificações enviadas',
                        life: 3000
                    });
                }
                let servicoIdToUpdate = this.servico.is_servico_regresso ? this.servico.servico_ida_id : this.servico.id
                this.updateServico(servicoIdToUpdate)
                this.$emit('updated', motoristas)
            })).catch((err => {
                if (err.response.data) {
                    alert(err.response.data.message)
                }

            }))
        },

    },
}
</script>

