<template>
  <tr>
    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"></td>
    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
      <AddressAutocomplete v-model="form.paragem"
                           :classCSS="[errors.paragem ? 'p-invalid' : 'w-full']"
                           :closable="false"
                           :is-edit-prop="true"
                           :valueProp="form.paragem"
                           field="paragem"
                           @changeFormValue="changePlaceValue"/>
      <span v-if="errors.paragem" :class="[errors.paragem ? 'text-red-400' : '']">Paragem obrigatória</span>
    </td>
    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
      <InputText type="time" v-model="form.hora_partida" :class="[errors.hora_partida ? 'p-invalid' : '']"
      />
    </td>
    <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
      <Button :disabled="form.processing" :loading="form.processing" text class=" ml-4"
              @click="handleCancelar">
        <font-awesome-icon icon="fa-solid fa-times" class="mr-2"/>
        Cancelar
      </Button>
      <Button :disabled="form.processing" :loading="form.processing" class=" ml-4"
              @click="handleSubmit">
        <font-awesome-icon icon="fa-solid fa-check" class="mr-2"/>
        Editar
      </Button>
    </td>
  </tr>

</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import {useForm} from "laravel-precognition-vue";

export default {
  components: {AddressAutocomplete},
  props: {
    paragem: {
      type: Object,
      required: true
    },
    relatedToId: {
      type: Number,
      required: true
    },
    relatedToType: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      createParagemIsOpen: false,
      form: useForm('PATCH',route('web-api.paragens.update',this.paragem.id),{
        paragem: null,
        hora_partida: null,
      }),
    }
  },
  mounted() {
    this.form.paragem = this.paragem.localidade;
    this.form.hora_partida = this.paragem.hora_partida;
  },
  methods: {
      handleCancelar() {
          this.$emit('cancelar', this.paragem.id)
      },
      handleSubmit() {
          this.form.submit().then((response) => {
              this.form.reset()
              this.$swal('Paragem atualizada',
                  'A Paragem foi atualizada com sucesso!',
                  'success'
              );
              this.$emit('updated')
              this.$emit('cancelar', this.paragem.id)
          })

      },
  }

}
</script>

