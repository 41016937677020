<template>
    <div>

        <!--    <Dialog v-if="modalServicoCreateIsOpen" v-model:visible="modalServicoCreateIsOpen"
                    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
                    :modal="true"
                    :style="{width: '50vw'}" header="Novo Serviço Ocasional"
                    @hide="closeSidebar()">
              <ServicoCreate/>
            </Dialog>-->

        <GlobalModalServicoCreate/>
        <GlobalModalServicoEdit/>

        <ConfirmDialog></ConfirmDialog>
        <Toast/>

    </div>
</template>

<script>
import {mapActions} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import GlobalModalServicoCreate from "@/Pages/Servicos/Components/GlobalModalServicoCreate.vue";
import GlobalModalServicoEdit from "@/Pages/Servicos/Components/GlobalModalServicoEdit.vue";

export default {
    components: {
        GlobalModalServicoEdit,
        GlobalModalServicoCreate,
    },

    data() {
        return {
            queryParams: {},
        }
    },
    mounted() {
        this.queryParams = this.getQueryParameters();
        // window.addEventListener('popstate', this.handleURLChange);
        this.checkUrl();
    },
    beforeDestroy() {

        // Remove the event listener when the component is destroyed
        window.removeEventListener('popstate', this.handleURLChange);
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),
        showToast() {

            this.$toast.add({
                icon: this.$page.props.flash.toast.icon,
                severity: this.$page.props.flash.toast.severity,
                summary: this.$page.props.flash.toast.summary,
                life: 3000
            });
        },
        getQueryParameters() {
            const url = new URL(window.location.href);
            return Object.fromEntries(url.searchParams.entries());
        },
        handleURLChange() {

            // Handle changes in URL query parameters here
            const newQueryParams = this.getQueryParameters();

            // Compare the new query parameters to the old ones
            if (JSON.stringify(newQueryParams) !== JSON.stringify(this.queryParams)) {
                // Query parameters have changed
                this.queryParams = newQueryParams;
                // You can perform actions or updates here
                this.checkUrl()
            }
        },
        checkUrl() {
            const params = new URLSearchParams(new URL(window.location.href).search);
            this.checkModalServicoEdit(params);
        },
        checkModalServicoEdit(params) {
            const modalServicoId = params.get("modalServicoId");
            if (modalServicoId) {
                this.openModalServicoEdit(modalServicoId);
            }
        }
    },

}

</script>


