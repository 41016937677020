<template>
    <div>
        <div :class='"bg-"+servico.estado_cor+"-500"'
             class="flex w-16 flex-shrink-0 cursor-pointer items-center justify-center rounded-l-md text-sm font-medium text-white"
             @click="editEstado"
             v-if="!showFullLabel"
        >
            {{ servico.codigo }}
            <Popover ref="op" appendTo="body" showCloseIcon>
                <Listbox v-model="selectedEstado" :options="['pronto','confirmado','pendente','cancelado']"
                         class="w-full md:w-14rem"
                         @click="updateEstado(selectedEstado)"/>

            </Popover>
        </div>

        <div v-else @click="editEstado" class="cursor-pointer">
            #{{ servico.codigo }}
            <ServicoEstadoTag :servico="servico"/>
            <Popover ref="op" appendTo="body" showCloseIcon>
                <Listbox v-model="selectedEstado" :options="options"
                         class="w-full md:w-14rem"
                         @click="updateEstado(selectedEstado)">
                    <template #option="slotProps">
                        <WBadge :color="slotProps.option.color" class="w-full">
                            {{ slotProps.option.label }}
                        </WBadge>
                    </template>
                </Listbox>

            </Popover>
        </div>

    </div>
</template>

<script>
import Popover from "primevue/popover";
import Listbox from 'primevue/listbox';
import {mapActions} from "pinia";
import {useDashboardStore} from "@/Store/DashboardStore.js";
import ServicoEstadoTag from "@/Pages/Servicos/Servicos/components/ServicoEstadoTag.vue";
import WBadge from "@/Components/WBadge.vue";

export default {
    components: {
        WBadge,
        ServicoEstadoTag,
        Popover,
        Listbox
    },
    props: {
        showFullLabel: {
            type: Boolean,
            default: false
        },
        servico: {
            type: Object,
        },
    },
    data() {
        return {
            selectedEstado: null,
            options: [
                {label: 'Pronto', value: 'pronto', color: 'green'},
                {label: 'Confirmado', value: 'confirmado', color: 'indigo'},
                {label: 'Pendente', value: 'pendente', color: 'orange'},
                {label: 'Cancelado', value: 'cancelado', color: 'red'}
            ]
        }
    },
    methods: {
        ...mapActions(useDashboardStore, ['updateServico']),
        editEstado(event) {
            this.$refs.op.toggle(event);
        },
        updateEstado(estado) {
            const payload = {
                estado: estado.value
            }
            axios.patch(route('web-api.servicos.update.estado', this.servico.id), payload).then((res => {
                if (res.data.total_notificacoes > 0) {
                    this.$toast.add({
                        icon: 'pi pi-bell',
                        severity: 'success',
                        summary: res.data.total_notificacoes + ' Notificações enviadas',
                        life: 3000
                    });
                }

                this.$refs.op.hide();
                this.$emit('updated')
                this.updateServico(this.servico.servico_parent_id !== null ? this.servico.servico_parent_id : this.servico.id)


            })).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: error.response.data.message,
                    life: 3000
                });
            })
        }
    },
}
</script>
