<template>
    <div v-if="hasErrors">
        <div class="font-medium text-red-600">Alguma coisa correu mal</div>

        <ul class="mt-3 list-disc list-inside text-sm text-red-600">
            <li v-for="(error, key) in erros" :key="key">{{ error }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        erros: {
            type: Object,
            default: 0
        },
    },
    computed: {
        hasErrors() {
            if (this.erros) {
                return Object.keys(this.erros).length > 0;
            }
        }
    },
}
</script>

