<template>
    <WSkeleton v-if="loading"/>

    <div v-if="bilhete">

        <div class="bg-gray-100">
            <section aria-labelledby="filter-heading" class="grid items-center border-b border-t border-gray-200">
                <div class="relative col-start-1 row-start-1 py-4">
                    <div class="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
                        <div>
                            <a :href="route('bilhetes.print.show',bilhete.id)" target="_blank">
                                <font-awesome-icon icon="fa-solid fa-print"/>
                                <button class="text-gray-500 ml-2" type="button">Imprimir Bilhete</button>
                            </a>
                        </div>


                    </div>
                </div>

            </section>
        </div>

        <div class="mt-6">


            <div v-if="!bilhete.servico">
                <Message severity="error" :closable="false">Bilhete sem servico associado</Message>
            </div>


            <table class="min-w-full divide-y divide-gray-300" v-if="bilhete.servico">
                <tbody class="divide-y divide-gray-200">
                <tr>
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        <div class="flex space-x-4">
                            <div class="font-medium text-gray-900 hover:text-gray-600">
                                <div class="flex space-x-12">
                                    <div class="font-medium text-gray-900 hover:text-gray-600" href="#">
                                        <p><span>{{ bilhete.servico.dt_partida }}</span></p>
                                        <p class="text-2xl">{{ bilhete.origem_localidade.name }}</p>
                                    </div>
                                    <div class="align-middle flex items-center text-2xl">
                                        ->
                                    </div>
                                    <div class="font-medium text-gray-900 hover:text-gray-600" href="#">
                                        <p><span>{{ dayjs(bilhete.servico.dt_chegada).format('HH:MM') }}</span></p>
                                        <p class="text-2xl">{{ bilhete.destino_localidade.name }}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="flex space-x-4">
                            Serviço : #{{ bilhete.servico.codigo }}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>


            <dl class="grid grid-cols-1 sm:grid-cols-2">

                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Nome do passageiro</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ bilhete.passageiro_nome }}</dd>
                </div>
                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Tipo de bilhete</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ bilhete.tipo_bilhete }}</dd>
                </div>

                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Tipo de Viagem</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ bilhete.tipo_viagem }}</dd>
                </div>

                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Reserva</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">#{{ bilhete.reserva_id }}</dd>
                </div>

                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">Estado</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                        <div class="flex">
                            <BilheteEstadoTag :bilhete="bilhete"/>
                            <BilheteEstadosMenu v-if="bilhete" :bilhete="bilhete" @refresh="refresh"/>
                        </div>
                    </dd>
                </div>

            </dl>
        </div>

        <div>
            <ul role="list" class="space-y-6">
                <li class="relative flex gap-x-4" v-for="regati in bilhete.regatis" :key="regati.id">
                    <div class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center">
                        <div class="w-px bg-gray-200"></div>
                    </div>
                    <img :src="regati.creator.avatar" alt=""
                         class="relative mt-3 h-6 w-6 flex-none rounded-full bg-gray-50">
                    <div class="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">
                        <div class="flex justify-between gap-x-4">
                            <div class="py-0.5 text-xs leading-5 text-gray-500"><span class="font-medium text-gray-900">{{
                                    regati.creator.name
                                }}</span>
                                {{ regati.descricao }}
                            </div>
                            <time datetime="2023-01-23T15:56" class="flex-none py-0.5 text-xs leading-5 text-gray-500">
                                {{ dtToFront(regati.created_at) }}
                            </time>
                        </div>
                        <p class="text-sm leading-6 text-gray-500">{{ regati.extra?.motivo }}</p>
                    </div>
                </li>

            </ul>


        </div>


    </div>
</template>

<script>
import Bilhete from "@/Pages/Bilhetes/Models/Bilhete.js"
import Message from 'primevue/message';
import ClientesCreate from "@/Pages/Clientes/Create.vue";
import AssociarServicoABilhete from "@/Pages/Bilhetes/Components/AssociarServicoABilhete.vue";
import Menu from 'primevue/menu';
import BilheteAlterarEstado from "@/Pages/Bilhetes/Components/Show/BilheteAlterarEstado.vue";
import BilheteEstadoTag from "@/Pages/Bilhetes/Components/BilheteEstadoTag.vue";
import ServicoNotas from "@/Pages/Servicos/Servicos/components/Edit/ServicoNotas.vue";
import BilheteEstadosMenu from "@/Pages/Bilhetes/Components/Show/Estados/BilheteEstadosMenu.vue";

export default {
    emits: ['refresh'],
    props: {
        bilheteId: {
            type: Number,
            required: true
        }
    },
    components: {
        BilheteEstadosMenu,
        ServicoNotas,
        AssociarServicoABilhete,
        BilheteEstadoTag,
        BilheteAlterarEstado,
        ClientesCreate,
        Menu,
        Message
    },
    data() {
        return {
            bilhete: null,
            loading: false,
            alterarEstadoIsOpen: false,
            alterarServicoIsOpen: false,
            data: null,
            colocarEmCreditoIsOpen: false,

        }
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        refresh() {
            this.$emit('refresh')
            this.fetchData()
        },
        closeSidebar() {
            this.alterarEstadoIsOpen = false
            this.alterarServicoIsOpen = false
        },

        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        async fetchData() {
            this.bilhete = null;
            this.loading = true;
            let params = {
                id: this.bilheteId,
                include_servico: true,
                include_regati: true,
            }
            axios.get(route('web-api.bilhetes.index', params))
                .then((response) => {
                    this.bilhete = response.data[0]
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error)
                })

            this.loading = false;
        },

    },
}
</script>

