<template>
    <label class="block font-medium text-sm text-gray-700" :for="forLabel">
        <span v-if="value">{{ value }} <span class="text-red-500" v-if="required==''">*</span></span>
        <span v-else><slot/></span>
    </label>
</template>

<script>
export default {
    props: {
        value: String,
        required: {
            required: false,
        },
        for: String

    },

    data() {
        return {
            forLabel: this.for
        }
    },

}
</script>
