<template>
  <div>
    <div class="cursor-pointer" @click="editAutocarro()">
      <WBadge v-if="servico.autocarro" color="blue">
        <div class="w-24 h-8 flex items-center">
          <font-awesome-icon icon="fa-solid fa-bus"/>
          <p class="text-lg ml-2">{{
              servico.autocarro.codigo
            }} </p>

        </div>

        <p v-if="lotacaoEMaiorQueAutocarro" class="text-red-500">
          <font-awesome-icon icon="fa-solid fa-circle-info"/>
          {{ servico.autocarro.lotacao }}
        </p>
        <p v-else class="text-green-500">

          {{ servico.autocarro.lotacao }}
        </p>
      </WBadge>
      <WBadge v-else color="red">
        <div class="w-26 h-8 flex items-center">
          <font-awesome-icon icon="fa-solid fa-bus"/>
          <p class=" px-2"> Sem autocarro</p>
        </div>
      </WBadge>
    </div>

    <Dialog v-if="modalChooseAutocarroIsOpen" v-model:visible="modalChooseAutocarroIsOpen"

            :modal="true"
            :style="{width: '90vw'}" header="Escolher Autocarro" @hide="closeSidebar()">

        <Button v-if="servico.autocarro" type="button" label="Remover autocarro selecionado" outlined icon="pi pi-times-circle" @click="limpar" />

        <ChooseAutocarro v-if="servico" :autocarro="servico.autocarro"
                       :dt_partida_servico="servico.dt_partida"
                       :dt_regresso_servico="servico.dt_regresso"
                       :lotacao="servico.lotacao"
                       @update-autocarro="updateAutocarro"/>
    </Dialog>

  </div>
</template>

<script>
import WBadge from "@/Components/WBadge.vue";
import ChooseMotoristas from "@/Pages/Servicos/Servicos/components/ChooseMotoristas.vue";
import {mapActions} from "pinia";
import {useDashboardStore} from "@/Store/DashboardStore.js";
import ChooseAutocarro from "@/Pages/Servicos/Servicos/components/ChooseAutocarro.vue";

export default {
  components: {ChooseAutocarro, ChooseMotoristas, WBadge},

  props: {
    editable: {
      type: Boolean,
      default: true
    },
    servico: {
      type: Object,
    },
  },
  data() {
    return {
      modalChooseAutocarroIsOpen: false,
    }
  },
  computed: {
    lotacaoEMaiorQueAutocarro() {
      if (!this.servico.lotacao || !this.servico.autocarro) {
        return null
      }
      return Number(this.servico.lotacao) > Number(this.servico.autocarro.lotacao);
    },
  },
  methods: {
    ...mapActions(useDashboardStore, ['updateServico']),
    editAutocarro() {
      this.editable ? this.modalChooseAutocarroIsOpen = true : null
    },
      limpar(){
        let autocarro = {
            id : null
        }
        this.updateAutocarro(autocarro)
      },
    updateAutocarro(autocarro) {
      const payload = {
        autocarro_id: autocarro.id
      }
      axios.patch(route('web-api.servicos.update.autocarro', this.servico.id), payload).then((res => {
        this.modalChooseAutocarroIsOpen = false
        if (res.data.total_notificacoes > 0) {
          this.$toast.add({
            icon: 'pi pi-bell',
            severity: 'success',
            summary: res.data.total_notificacoes + ' Notificações enviadas',
            life: 3000
          });
        }
          this.updateServico(this.servico.is_servico_regresso ? this.servico.servico_ida_id : this.servico.id)
        this.$emit('updated', autocarro)
      }))
    },
  },
}
</script>

