<template>

    <BreezeValidationErrors class="mb-4"/>
    <!-- DETALHES-->
    <WCard iconTitulo="fa-solid fa-circle-info" titulo="Detalhes">
        <template #content>


            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">

                <div class="sm:col-span-1">
                    <FormVerticalGroup :erro="form.errors.lotacao" forProp="lotacao" label="Lotação" required>
                        <InputText type="number" v-model="form.lotacao" :invalid="form.errors.lotacao"
                                   @change="form.validate('lotacao')"/>
                    </FormVerticalGroup>
                </div>


            </div>

        </template>
    </WCard>


    <!--VIAGEM-->

    <WCard class="mt-20" iconTitulo="fa-regular fa-calendar" titulo="Viagem">
        <template #content>

            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">

                <div class="sm:col-span-2">
                    <FormVerticalGroup :erro="form.errors.linha_id" forProp="linha_id" label="Linha">
                        <Select v-model="form.linha_id" showClear :options="linhas" optionLabel="nome" optionValue="id"
                                placeholder="Selecione uma linha"/>

                    </FormVerticalGroup>
                </div>

                <div class="sm:col-span-1">
                    <FormVerticalGroup :erro="form.errors.dt_partida" forProp="dt_partida" label="Data de partida"
                                       required>
                        <InputText type="datetime-local" v-model="form.dt_partida" :invalid="form.errors.dt_partida"
                                   @change="form.validate('dt_partida')"/>
                    </FormVerticalGroup>
                </div>


                <div class="sm:col-span-1">
                    <FormVerticalGroup :erro="form.errors.dt_regresso" forProp="dt_regresso" label="data de chegada"
                                       required>
                        <InputText type="datetime-local" v-model="form.dt_regresso" :invalid="form.errors.dt_regresso"
                                   @change="form.validate('dt_regresso')"/>
                    </FormVerticalGroup>
                </div>


                <div class="sm:col-span-1">

                    <div class="sm:col-span-1">
                        <FormVerticalGroup :erro="form.errors.localidade_partida" forProp="localidade_partida"
                                           label="Origem" required>
                            <AddressAutocomplete ref="localidade_partida" v-model="form.localidade_partida"
                                                 :classCSS="form.errors.localidade_partida ? 'p-invalid' : 'w-full'"
                                                 :is-edit-prop="true"
                                                 :valueProp="form.localidade_partida"
                                                 field="localidade_partida"
                                                 @changeFormValue="changeFormValue"/>
                        </FormVerticalGroup>
                    </div>

                </div>


                <div class="sm:col-span-1">
                    <FormVerticalGroup :erro="form.errors.localidade_chegada" forProp="localidade_chegada"
                                       label="Destino" required>
                        <AddressAutocomplete ref="localidade_chegada" v-model="form.localidade_chegada"
                                             :classCSS="form.errors.localidade_chegada ? 'p-invalid' : 'w-full'"
                                             :is-edit-prop="true"
                                             :valueProp="form.localidade_chegada"
                                             field="localidade_chegada"
                                             @changeFormValue="changeFormValue"/>
                    </FormVerticalGroup>
                </div>


            </div>


        </template>
    </WCard>


    <WCard titulo="Observações">
        <template #content>
            <Textarea v-model="form.observacoes" class="w-full" rows="3"></Textarea>

        </template>
    </WCard>

    <div class="flex justify-end mt-10 bg-gray-100 py-2">
        <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                label="Guardar" @click="submit()">
        </Button>
    </div>
</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import LocalidadesMixin from "@/Mixins/LocalidadesMixin.js";
import WBadge from "@/Components/WBadge.vue";
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import {mapActions, mapWritableState} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import {useForm} from "laravel-precognition-vue";
import FormVerticalGroup from "@/Components/FormVertical/FormVerticalGroup.vue";

export default {
    emits: ['closeModal'],
    components: {
        AddressAutocomplete,
        WBadge,
        InputNumber,
        Checkbox,
        Textarea,
        FormVerticalGroup
    },
    name: 'ServicoInternacionalCreate',
    mixins: [WCrudMixin, LocalidadesMixin],

    data() {
        return {
            linhas: [],
            form: useForm('POST', route('web-api.servicos-internacionais.store'), {
                lotacao: null,
                dt_partida: null,
                dt_regresso: null,
                localidade_partida: null,
                localidade_chegada: null,
                observacoes: null,
                linha_id: null,
            }),
        }
    },
    computed: {
        ...mapWritableState(useGlobalModalsStore, ['tipoServico', 'modalServicoCreateIsOpen', 'globalModalStoreRefreshServicos']),
        isDialogOpen() {
            return this.modalServicoCreateIsOpen && this.tipoServico === 'Internacional'
        },
    },
    watch: {
        'form.linha_id'(newValue, oldValue) {
            let params = {
                id: newValue,
                include_origem: true,
                include_destino: true
            }
            axios.get(route('web-api.linhas.index', params)).then((response) => {
                let linha = response.data.data[0]

                this.form.localidade_partida = linha.origem.localidade
                this.form.localidade_chegada = linha.destino.localidade
                const [horaPartida, minutoPartida] = linha.origem.hora_partida.split(':').map(Number);
                this.form.dt_partida = this.dayjs(this.form.dt_partida).set('hours', horaPartida).set('minutes', minutoPartida).format('YYYY-MM-DDTHH:mm')

                const [horaChegada, minutoChegada] = linha.destino.hora_partida.split(':').map(Number);
                this.form.dt_regresso = this.dayjs(this.form.dt_partida).set('hours', horaChegada).set('minutes', minutoChegada).format('YYYY-MM-DDTHH:mm')

            })
        }
    },
    mounted() {
        this.fetchLinhas();
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),
        fetchLinhas() {
            axios.get(route('web-api.linhas.index')).then((response) => {
                this.linhas = response.data.data
            })
        },
        closeModal() {
            this.modalServicoInternacionalCreateIsOpen = false
        },

        submit() {

            this.form.submit().then((response) => {
                this.form.reset();
                this.$emit('closeModal')
                this.globalModalStoreRefreshServicos()
                this.addUrlParams('modalServicoId', response.data.id)
                this.openModalServicoEdit(response.data.id)
                this.$swal('Serviço inserido',
                    'O Serviço foi inserido com sucesso!',
                    'success'
                );
            })

        },
    },
}
</script>

