<template>
    <div @click="$emit('clicked')" class="text-xl">
        <WBadge class="text-xl" v-if="servico.estado==='pronto'" color="green">{{
                servico.estado
            }}
        </WBadge>
        <WBadge v-if="servico.estado==='confirmado'" color="indigo">{{
                servico.estado
            }}
        </WBadge>
        <WBadge v-if="servico.estado==='pendente'" color="orange">{{
                servico.estado
            }}
        </WBadge>
        <WBadge v-if="servico.estado==='cancelado'" color="red">{{
                servico.estado
            }}
        </WBadge>
    </div>
</template>

<script>
import WBadge from "@/Components/WBadge.vue";

export default {
    components: {WBadge},
    props: {
        servico: {
            type: Object,
            required:true,
        },
    },
}
</script>
