<template>
    <div>
        <Button type="button" icon="pi pi-ellipsis-v" severity="secondary" data-servico="12" text
                @click="toggleMenuOpcoes" aria-haspopup="true" aria-controls="overlay_menu"/>
        <MenuPrime ref="menuOpcoes" id="overlay_menu" :model="itemsMenuOpcoes" :popup="true"/>

    </div>
</template>

<script>
import {mapActions} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import ServicoSateliteGerirPassageiros
    from "@/Pages/Servicos/Internacionais/Components/ServicosSatelites/ServicoSateliteGerirPassageiros.vue";

export default {
    components: {},
    props: {
        servicoId: {
            type: Number,
        },
    },
    data() {
        return {
            resourceGerirPassageirosIsOpen: false,
            itemsMenuOpcoes: [
                /*  {
                      label: 'Gerir passageiros',
                      icon: 'pi pi-user',
                      command: () => this.resourceGerirPassageirosIsOpen = true
                  },*/
                {
                    label: 'Copiar itinerarios',
                    icon: 'pi pi-copy',
                    command: () => this.copiarItinerarios()
                },
            ]
        }
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),
        copiarItinerarios() {
            if (!confirm('Tem a certeza que deseja copiar este itinerario para os outros serviços deste contrato?')) return

            let params = {
                servico: this.servicoId
            }
            axios.post(route('web-api.servicos.copiar-para-todos-servicos-do-contrato', params))
                .then(response => {
                    this.$emit('refresh')
                    this.$toast.add({
                        icon: 'pi pi-check',
                        severity: 'success',
                        summary: 'Itinerarios copiados',
                        life: 3000
                    });
                })
                .catch(error => this.$swal.fire('Erro ao copiar o itinerario', '', 'error'))
        },
        toggleMenuOpcoes(event) {
            this.$refs.menuOpcoes.toggle(event);
        },
    },
}
</script>

