<template>
    <div>

        <span
            :class="'bg-'+color+'-50 text-'+color+'-700' +' ring-'+color+'-700/10'"
            class="inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset">
            <slot/>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            default: 'gray'
        }
    }
}
</script>
