<template>
    <section>
        <div class="w-card">
            <div v-if="titulo" class="px-4 py-5 sm:px-6">
                <slot name="header">
                    <div class="flex justify-between">
                        <div>
                            <h2 class="titulo">

                                <font-awesome-icon v-if="iconTitulo" :icon="iconTitulo"/>
                                {{ titulo }}
                            </h2>
                            <slot name="subtitulo"></slot>
                        </div>

                        <div class="actions">
                            <slot name="actions"></slot>
                        </div>
                    </div>
                    <p v-if="subtitulo" class="mt-1 max-w-2xl text-sm text-gray-500">{{ subtitulo }}</p>
                </slot>
            </div>

            <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <slot name="content">

                </slot>

            </div>


            <div v-if="footerLinkTitle">
                <Link :href="footerLink"
                      class="block bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg">
                    {{ footerLinkTitle }}
                </Link>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    props: {
        titulo: String,
        subtitulo: String,
        footerLink: String,
        footerLinkTitle: String,
        iconTitulo: String,
    },
}
</script>
