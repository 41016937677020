<template>
    <div>
        <span v-if="!autocarro" class="text-red-500">
            <font-awesome-icon icon="fa-solid fa-circle-info"/>
            Nenhum autocarro selecionado</span>


        <span v-else>
        <ul class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4" role="list">
                        <li class="col-span-1 flex rounded-md shadow-sm">
                            <div
                                :class="[lotacaoEMaiorQueAutocarro ? 'bg-red-500' : 'bg-green-500']"
                                class="flex w-16 flex-shrink-0 items-center justify-center bg-green-500 rounded-l-md text-sm font-medium text-white"
                            >
                                {{ autocarro.codigo }}
                            </div>
                            <div
                                class="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                                <div class="flex-1 truncate px-4 py-2 text-sm">
                                    <div class="font-medium text-gray-900 hover:text-gray-600">
                                        {{ autocarro.matricula }}
                                    </div>
                                    <p class="text-gray-500">{{ autocarro.marca }} {{
                                            autocarro.modelo
                                        }}</p>
                                </div>
                                <div class="mr-2">
                                    <font-awesome-icon
                                        icon="fa-solid fa-users"/> {{ autocarro.lotacao }}
                                </div>
                            </div>

                        </li>
            </ul>
        </span>

        <span v-if="lotacaoEMaiorQueAutocarro" class="text-red-500">
            <font-awesome-icon icon="fa-solid fa-circle-info"/>
            A lotação do servico é maior do que a do autocarro !</span>

        <div class="flex">
            <font-awesome-icon class="cursor-pointer" icon="fa-solid fa-pen-to-square"
                               @click="resourceAutocarroEditIsOpen=true"/>


            <div v-if="autocarro">
                |

                <font-awesome-icon class="cursor-pointer" icon="fa-solid fa-xmark"
                                   @click="updateAutocarro(null)"/>
            </div>
        </div>

        <Dialog v-if="resourceAutocarroEditIsOpen" v-model:visible="resourceAutocarroEditIsOpen"

                :modal="true"
                :style="{width: '90vw'}" header="Autocarro" @hide="closeSidebar()">

            <ChooseAutocarro :autocarro="autocarro"
                             :dt_partida_servico="dtPartida"
                             :dt_regresso_servico="dtRegresso"
                             :lotacao="lotacao"
                             @update-autocarro="updateAutocarro"/>
        </Dialog>

    </div>
</template>

<script>
import ChooseAutocarro from "@/Pages/Servicos/Servicos/components/ChooseAutocarro.vue";

export default {
    components: {ChooseAutocarro},

    props: {
        dtPartida: {
            type: String
        },
        dtRegresso: {
            type: String
        },
        autocarroProp: {
            type: Object
        },
        servico: {
            type: Object,
        },
        lotacao: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            autocarro: null,
            resourceAutocarroEditIsOpen: false,
        }
    },

    computed: {
        lotacaoEMaiorQueAutocarro() {
            if (!this.lotacao || !this.autocarro) {
                return null
            }
            if (this.lotacao > this.autocarro.lotacao) {
                return true
            }
        },
    },
    mounted() {
        this.autocarro = this.autocarroProp;
    },
    methods: {
        updateAutocarro(autocarro) {
            if (autocarro) {
                this.autocarro = autocarro
                this.autocarro_id = autocarro.id
            }
            this.$emit('update-autocarro', autocarro)
            this.resourceAutocarroEditIsOpen = false
        }
    },
}
</script>

