<template>
    <div v-if="!contrato">Este serviço não tem contrato associado</div>
    <div v-if="contrato">
        <WCard iconTitulo="fa-solid fa-edit" :titulo="'Contrato #'+contrato.codigo">
            <template #actions>

                <Button v-if="contrato" class=" p-button-outlined" icon="fa-solid fa-edit" @click="goToContrato"
                        label="Ver contrato">
                </Button>

            </template>
            <template #content>

                <WEmptyState v-if="!contrato" titulo="Nenhum contrato">
                    <Button class=" p-button-outlined" icon="fa-solid fa-plus" label="Associar a contrato"
                            @click="modalAssociarAContratoIsOpen = true">

                    </Button>
                </WEmptyState>


                <div v-if="contrato">
                    <div>
                        <div class="mt-6">
                            <dl class="grid grid-cols-1 sm:grid-cols-2">
                                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">Cliente</dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                        <AvatarUserName :avatar="contrato.cliente.avatar"
                                                        :name="contrato.cliente.nome"/>
                                        <p>{{ contrato.cliente.telemovel }} | {{ contrato.cliente.email }}</p>
                                    </dd>
                                </div>
                                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt class="text-sm font-medium leading-6 text-gray-900">Período</dt>
                                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                        {{ dtToFront(contrato.dt_inicio) }} até {{ dtToFront(contrato.dt_fim) }}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    <TableContratoServicos :contrato="contrato" :servicoIdSelected="this.servico.id"
                                           @refresh="$emit('refresh')"/>


                </div>


            </template>
        </WCard>

        <Dialog v-model:visible="modalAssociarAContratoIsOpen"
                :modal="true"
                :style="{width: '90vw'}"
                header="Associar Serviço a um contrato"
                @hide="modalAssociarAContratoIsOpen = false"

        >
            <AssociarServicoAContrato :servico="servico" @contrato-selected="contratoSelected"/>
        </Dialog>
    </div>
</template>

<script>
import {mapActions, mapState, mapWritableState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import {useServicoItinerarioStore} from "@/Pages/Servicos/Servicos/Store/ItinerarioStore.js";
import AssociarServicoAContrato from "@/Pages/Servicos/Components/AssociarServicoAContrato.vue";
import AvatarUserName from "@/Components/Display/AvatarUserName.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import ServicoTdFilho from "@/Pages/Servicos/Servicos/components/Edit/ServicoTdFilho.vue";
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import TableServicoEstado from "@/Pages/Servicos/Servicos/components/Table/TableServicoEstado.vue";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import TableContratoServicos from "@/Pages/Contratos/Ocasionais/Components/TableContratoServicos.vue";

export default {
    components: {
        TableContratoServicos,
        TableServicoEstado,
        TableServicoAutocarro,
        ServicoTdFilho,
        WInlineEdit,
        TableServicoMotoristas,
        AvatarUserName, AssociarServicoAContrato
    },
    computed: {
        ...mapWritableState(useServicoStore, ['servico']),
        ...mapState(useServicoItinerarioStore, ['itinerarios'])
    },
    emits: ['refresh'],
    data() {
        return {
            modalAssociarAContratoIsOpen: false,
            contrato: null
        }
    },
    mounted() {
        if (this.servico.contrato_id) {
            this.fetchContrato();
        }
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit', 'closeModalServicoEdit']),
        showServico(servico) {
            this.openModalServicoEdit(servico.id)
        },
        refresh() {
            this.fetchContrato()
        },
        contratoSelected() {
            this.modalAssociarAContratoIsOpen = false;
            this.contrato = null
            if (this.servico.contrato_id) {
                this.fetchContrato()
            }
        },
        async fetchContrato() {
            this.loading = true;
            let params = {
                id: this.servico.contrato_id,
                include_cliente: true,
                include_servicos: true,
            }
            axios.get(route('web-api.contratos.index', params))
                .then(response => {
                    if (response.data.data.length) {
                        this.contrato = response.data.data[0]
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        goToContrato() {
            this.removeUrlParams('modalServicoId')
            this.closeModalServicoEdit()
            this.$inertia.visit(route('contratos.show', this.contrato.id))
        }
    },
}
</script>

