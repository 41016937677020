<template>
    <div>


        <table class="min-w-full border-none ">
            <tbody class="bg-white">
            <tr v-for="index in 10" :key="index">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <Skeleton></Skeleton>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <Skeleton></Skeleton>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <Skeleton></Skeleton>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <Skeleton></Skeleton>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton'

export default {
    components: {
        Skeleton
    },
    data() {
        return {
            products: new Array(4)
        }
    }
}
</script>

