<template>
    <div>
        <table class="min-w-full divide-y divide-gray-300">
            <thead>
            <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Motorista
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Horário</th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
            <tr v-for="(motorista,index) in motoristas" :key="index">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" class="cursor-pointer"
                                       @click="editMotorista(motorista.motorista.id)"
                                       v-if="!isEditingMotorista(motorista.motorista.id)"/>
                    {{ motorista.motorista.nome }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div v-if="!isEditingMotorista(motorista.motorista.id)">
                        <p v-for="(horario,indexHorario) in motorista.horarios" :key="indexHorario">
                            {{ dtToFront(horario.dt_inicio) }} -> {{ dtToFront(horario.dt_fim) }} | {{
                                calculateTimeDifference(horario.dt_inicio, horario.dt_fim)
                            }}
                            <font-awesome-icon icon="fa-solid fa-times" class="cursor-pointer text-red-300"
                                               @click="removeMotorista(motorista.motorista)"/>
                        </p>


                    </div>
                    <div v-else>

                        <div v-for="(horario,indexHorario) in motorista.horarios" :key="indexHorario">
                            <div class="flex">
                                <InputText type="datetime-local"
                                           v-model="motorista.horarios[indexHorario].dt_inicio"/>
                                até
                                <InputText type="datetime-local" v-model="motorista.horarios[indexHorario].dt_fim"/>
                            </div>
                            {{
                                calculateTimeDifference(motorista.horarios[indexHorario].dt_inicio, motorista.horarios[indexHorario].dt_fim)
                            }}


                        </div>

                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import duration from 'dayjs/plugin/duration';


export default {
    name: 'ChooseMotoristasHorario',
    props: {
        servico: {
            type: Object
        },

    },
    data() {
        return {
            motoristas: [],
            motoristasEditing: [],
        }
    },
    mounted() {
        this.fetchHorarios();
        this.dayjs.extend(duration);
    },
    computed: {
        dtInicio() {
            let dt_inicio = this.servico.dt_partida
            return dt_inicio
        },
        dtFim() {
            let dt_fim = this.servico.dt_regresso
            return dt_fim
        },
        totalHorasServico() {
            if (this.dtInicio && this.dtFim) {
                // Calculate the difference in hours using dayjs
                const start = this.dayjs(this.dtInicio);
                const end = this.dayjs(this.dtFim);

                // Difference in hours
                const totalHours = end.diff(start, 'hour', true); // 'true' returns float (fractional hours)
                return totalHours;
            }
            return 0;
        },
    },
    methods: {
        fetchHorarios() {
            axios.get(route('web-api.servicos.motoristas.horario', {servico: this.servico.id})).then((res) => {
                res.data.forEach((el) => {
                    let motoristaObject = {
                        motorista: el.colaborador,
                        horarios: el.horarios
                    }
                    this.motoristas.push(motoristaObject)
                })
            })
        },
        calculateTimeDifference(start, end) {
            const diff = this.dayjs(end).diff(this.dayjs(start));
            const duration = this.dayjs.duration(diff);
            const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            return `${hours} horas e ${minutes} minutos`;
        },
        editMotorista(motorista_id) {
            this.motoristasEditing.push(motorista_id)
        },
        isEditingMotorista(motorista_id) {
            return this.motoristasEditing.includes(motorista_id);
        },
        addMotorista(motorista) {
            let motoristaObject = {
                motorista: motorista,
                horarios: [
                    {
                        dt_inicio: this.dtInicio,
                        dt_fim: this.dtFim
                    }
                ]
            }
            this.motoristas.push(motoristaObject)
        },
        removeMotorista(motoristaToRemove) {
            const index = this.motoristas.findIndex(motorista => motorista.motorista.id === motoristaToRemove.id);

            if (index !== -1) {
                this.motoristas.splice(index, 1);
            }
        },
    },
}
</script>
