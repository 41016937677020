<template>
    <div>

        <WSkeleton v-if="loading"/>
        <DataTable v-if="data"
                   v-model:filters="filtersDT"
                   v-model:selection="resourceSelected"
                   :first="0"
                   :lazy="true"
                   :loading="loading"
                   :paginator="true"
                   :rows="10"
                   :sortOrder="1"
                   :totalRecords="data.total"
                   :value="data.data"
                   filterDisplay="row"
                   selectionMode="single"
                   sortField="name"
                   @filter="onFilter($event)"
                   @page="onPage($event)"
                   @sort="onSort($event)"

        >

            <Column field="id" header="Código" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.id }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por codigo.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="nome" header="Nome" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.nome }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter" placeholder="Procurar por nome.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>


            <Column field="telemovel" header="Telemóvel" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.telemovel }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter"
                               placeholder="Procurar por telemovel.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>

            <Column field="localidade" header="Localidade" style="min-width: 12rem">
                <template #body="{ data }">
                    {{ data.localidade }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" class="p-column-filter"
                               placeholder="Procurar por localidade.."
                               type="text"
                               @keydown.enter="filterCallback()"/>
                </template>
            </Column>


            <template #empty>
                <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum cliente encontrado"/>
            </template>
        </DataTable>

        <div class="flex justify-center">

            <Button class="p-button-outlined mt-2" @click="resourceCreateIsOpen=true">+ Criar novo cliente</Button>
        </div>

        <Drawer v-model:visible
                    ="resourceCreateIsOpen" class="lg:!w-[60rem]" position="right"
                @hide="closeSidebar()">
            <ClientesCreate
                v-if="resourceCreateIsOpen"
                :is-open="resourceCreateIsOpen"
                @closeSidebar="closeSidebar"
                @refresh="fetchData()"
                @resourceCreated="resourceCreated"
            />
        </Drawer>

    </div>
</template>


<script>


import WDataTable from "@/Mixins/WDataTable.js";
import Cliente from "@/Models/Cliente.js";
import {FilterMatchMode} from '@primevue/core/api';
import ClientesCreate from "@/Pages/Clientes/Create.vue";
import WCrudMixin from "@/Mixins/WCrudMixin.js";

export default {
    mixins: [WDataTable, WCrudMixin],
    components: {ClientesCreate},
    name: 'Cliente',
    props: {
        cliente: {
            type: Object
        },
    },

    data() {
        return {
            resourceSelected: null,
            filtersDT: {
                id: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                nome: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                telemovel: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                localidade: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
            },
            filters: {
                search: '',
                date: [null, null],
                dt: {
                    page: 0,
                    sort: {
                        sortField: 'nome',
                        sortOrder: 1,
                    },
                },
            },
        }
    },

    watch: {
        resourceSelected(newValue, oldValue) {
            this.$emit('updateCliente', newValue)
        }
    },
    mounted() {
        this.fetchData()
    },

    methods: {
        async resourceCreated(codigo) {

            let data = await Cliente
                .params({
                    id: codigo,
                })
                .page(this.filters.dt.page)
                .limit(10)
                .orderBy(this.sortField)
                .get()

            this.resourceSelected = data.data[0]
        },
        onFilter() {
            this.filters.dt.page = 0
            this.fetchData()
        },
        async fetchData() {
            this.loading = true;
            this.data = await Cliente
                .params({
                    id: this.filtersDT.id.value,
                    nome: this.filtersDT.nome.value,
                    telemovel: this.filtersDT.telemovel.value,
                    localidade: this.filtersDT.localidade.value,
                    is_contratante: true,
                    is_passageiro: false,
                    ativo: true
                })
                .page(this.filters.dt.page)
                .limit(10)
                .orderBy(this.sortField)
                .get()


            this.loading = false;
        },

        closeSidebar() {
            this.resourceCreateIsOpen = false
        },

    }

}
</script>
