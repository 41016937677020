<template>
    <div>
        Adicionar Serviço Satélite
        <form @submit.prevent="submit">
            <FormErrors class="mb-4" :errors="form.errors"/>

            <FormGroup :erro="form.errors.paragem_partida_id" forProp="localidade_partida" label="Localidade Partida"
                       required>
                <Select v-model="form.paragem_partida_id" :options="paragens" optionLabel="localidade.name"
                        optionValue="id"
                        :filter="true" @change="form.validate('paragem_partida_id')"/>
            </FormGroup>


            <FormGroup :erro="form.errors.paragem_chegada_id" forProp="paragem_chegagem_id" label="Localidade Chegada"
                       required>
                <Select v-model="form.paragem_chegada_id" :options="paragens" optionLabel="localidade.name"
                        optionValue="id"
                        :filter="true" @change="form.validate('paragem_chegada_id')"/>
            </FormGroup>

            <div class="flex justify-end mt-10 bg-gray-100 py-2">
                <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                        label="Guardar" @click="submit()">
                </Button>
            </div>

        </form>
    </div>
</template>

<script>
import {useForm} from "laravel-precognition-vue";
import FormErrors from "@/Components/FormErrors.vue";

export default {
    props: {
        relatedToId: {
            type: Number,
        },
        relatedToType: {
            type: String,
        },
    },
    components: {
        FormErrors,
    },
    data() {
        return {
            paragens: null,
            formInputs: {
                paragem_partida_id: null,
                paragem_chegada_id: null,
            },
            form: null
        }
    },
    beforeMount() {
        this.form = useForm(this.formMethod,
            this.formRoute,
            this.formInputs);
    },
    mounted() {
        this.fetchParagens();
    },
    computed: {
        formMethod() {
            return 'post'
        },
        formRoute() {
            return route('servicos.satelites.store', this.relatedToId)
        }
    },
    methods: {
        fetchParagens() {
            this.loading = true
            let params = {
                relatedToType: this.relatedToType,
                relatedToId: this.relatedToId
            }
            return axios.get(route('web-api.paragens.index', params)).then((res => {
                this.paragens = res.data
                this.loading = false
            }))
        },
        submit() {

            this.form.submit().then((response) => {
                this.form.reset();
                this.$swal('Sucesso',
                    'Operação realizada com sucesso',
                    'success'
                );
                this.$emit('resourceCreated', response.data)
                this.$emit('closeSidebar')
                this.$emit('refresh')
            })

        }
    },
}
</script>


