<template>
  <template v-if="servicoFilho">
    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
      <TableServicoAutocarro :servico="servicoFilho" @updated="updateAutocarro"/>
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <WInlineEdit :classCSS="[errors.lotacao ? 'p-invalid' : '']"
                   :value="servicoFilho.lotacao"
                   class="w-full"
                   field="lotacao"
                   type="number"
                   @saveField="updateServicoLotacao"
      />
    </td>
    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
      <TableServicoMotoristas :servico="servicoFilho" @updated="fetchData"/>
    </td>
    <td>
      <Button class="p-button-outlined" severity="danger" @click="deleteServicoFilho(servicoFilho.id)">
        Eliminar
      </Button>
    </td>
      <td>
          <Button class="p-button-outlined" @click="editServicoFilho(servicoFilho.id)">
              <font-awesome-icon icon="fas fa-edit"/>
          </Button>
      </td>
  </template>
</template>

<script>
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";
import {mapActions} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  components: {FontAwesomeIcon, TableServicoMotoristas, WInlineEdit, TableServicoAutocarro},
  emits: ['deleted'],
  props: {
    servicoFilhoProp: {
      type: Object,
    },
  },
  data() {
    return {
      servicoFilho: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
      ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),
      editServicoFilho(id) {
          this.openModalServicoEdit(id)
      },
    deleteServicoFilho() {
      if (!confirm('Tem a certeza que pretende eliminar este serviço?')) return
      axios.delete(route('servicos.destroy', this.servicoFilho.id))
          .then(response => {
            this.$emit('deleted', this.servicoFilho.id)
            this.$swal.fire({
              title: 'Sucesso!',
              text: 'Serviço eliminado com sucesso!',
              icon: 'success',
              confirmButtonText: 'Fechar',
              timer: 3000,
            })
          })
          .catch(error => {
            console.log(error)
          })
    },
    updateAutocarro(autocarro) {
      if (autocarro) {
        this.servicoFilho.autocarro_id = autocarro.id
        this.servicoFilho.autocarro = autocarro
      } else {
        this.servicoFilho.autocarro_id = null
        this.servicoFilho.autocarro = null
      }

    },
    updateServicoLotacao(payload) {
      axios.patch(route('web-api.servicos.updateField', this.servicoFilho.id), payload)
          .then(response => {
            this.servicoFilho.lotacao = payload['value']
          })
          .catch(error => {
            console.log(error)
          })
    },
    fetchData() {
      axios.get(route('web-api.servicos.show', this.servicoFilhoProp.id)).then((res => {
        this.servicoFilho = res.data
      }))
    }
  },
}
</script>
