import Localidade from "@/Models/Localidade.js";

const LocalidadesMixin = {
    data() {
        return {
            localidadesSuggestions: [],
        };
    },

    methods: {
        async searchLocalidades(search) {
            const response = await Localidade.where('filter', search.query)
                .page(0)
                .limit(50)
                .orderBy('freguesia')
                .get()

            this.localidadesSuggestions = response.data

        }

    },

};

export default LocalidadesMixin;
