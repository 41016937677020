<template>
    <div>
        <Tag v-if="bilhete.estado==='POR_UTILIZAR'" severity="success">POR_UTILIZAR</Tag>
        <Tag v-if="bilhete.estado==='EM_CREDITO'" severity="warning">EM_CREDITO</Tag>
        <Tag v-if="bilhete.estado==='CANCELADO'">CANCELADO</Tag>
        <Tag v-if="bilhete.estado==='UTILIZADO'" severity="success">UTILIZADO</Tag>
        <Tag v-if="bilhete.estado==='PENDENTE'" severity="warning">PENDENTE</Tag>
        <Tag v-if="bilhete.estado==='EXPIRADO'" severity="danger">EXPIRADO</Tag>
    </div>
</template>

<script>
import Tag from 'primevue/tag';

export default {
    props: {
        bilhete: {
            type: Object,
        },
    },
    components: {
        Tag,
    },
}
</script>
