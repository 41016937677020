<template>
    <div >
        <Message severity="error" :closable="false" v-if="!checkIfCanCancel">O serviço só poderia ser editado até às 17h de {{dtToFront(dayjs(bilhete.servico.dt_partida).subtract(1,'day'))}}</Message>
        <FormH @w-submit="submit" @submit.prevent v-if="checkIfCanCancel">
            <div class="border-t border-gray-100">
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Preço</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{bilhete.preco}} €</dd>
                    </div>
                </dl>
                <div >
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Horas até ao serviço</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{horasQueFaltam}} h</dd>
                    </div>
                </dl>
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Percentagem aplicada</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{percentagemADevolver}}%</dd>
                    </div>
                </dl>
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt class="text-sm font-medium text-gray-900">Valor a devolver</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{valorADevolver}} €</dd>
                    </div>
                </dl>
                </div>

                <div v-if="horasQueFaltam<=0 && !checkIfCanCancel">
                    <Message :closable="false" severity="error">Impossível cancelar o bilhete, a viagem já ocorreu</Message>
                </div>
            </div>

            <div v-if="checkIfCanCancel || horasQueFaltam>0">
            <FormGroup :erro="form.errors.motivo" forProp="motivo"
                       label="Motivo"
                       required>
             <Textarea v-model="form.motivo" :class="[errors.motivo ? 'p-invalid' : '']" autoResize
                       class="w-full"
                       rows="5"/>
            </FormGroup>

            <div class="pt-5">
                <div class="flex justify-end">

                    <button :disabled="form.processing" class="ml-3 w-btn-primary" type="submit">
                        <i v-if="form.processing" class="pi pi-spin pi-spinner mr-2"
                           style="font-size: 1rem"></i>
                        Guardar
                    </button>
                </div>
            </div>
            </div>

        </FormH>
    </div>
</template>

<script>
import FormGroup from "@/Components/FormHorizontal/FormGroup.vue";
import FormH from "@/Components/FormHorizontal/Form.vue";
import {useForm} from "laravel-precognition-vue";

export default {
    components: {FormH, FormGroup},
    props: {
        bilhete: {
            type: Object,
        },
    },
    data() {
        return {
            loaded:false,
            formInputs: {
                motivo: null,
            },
            form: null,
            servico:null,
            horasQueFaltam:0
        }
    },
    computed: {
        checkIfCanCancel(){
            if(!this.bilhete.servico_id){
                return  true
            }
            if(!this.servico){
                return true
            }
            let dt_servico = this.servico.dt_partida;
            let serviceDate = this.dayjs(dt_servico);
            let cutoffTime = serviceDate.subtract(1, 'day').hour(17).minute(0).second(0);

            let now = this.dayjs();

            if (now.isBefore(cutoffTime)) {
                console.log("Service can be cancelled");
                return true;
            } else {
                console.log("Service cannot be cancelled");
                return false;
            }
        },
        percentagemADevolver() {
            if (this.horasQueFaltam >= 72) {
                return 75;
            } else if (this.horasQueFaltam > 48) {
                return 50;
            } else if (this.horasQueFaltam < 48) {
                return 0;
            }

        },
        valorADevolver() {
            return (this.bilhete.preco * this.percentagemADevolver) / 100;
        }
    },
    beforeMount() {
        if(this.bilhete.servico_id) {
            this.fetchServico();
        }
        this.form = useForm('patch',
            route('web-api.bilhetes.invalidar', this.bilhete.id),
            this.formInputs);
    },
    methods: {
        fetchServico(){
            this.loaded = false
          axios.get(route('web-api.servicos.show', this.bilhete.servico_id)).then((response) => {
             this.servico = response.data
              this.calculateHorasQueFaltam(response.data.dt_partida)
this.loaded = true
          })
        },
        calculateHorasQueFaltam(){
            if(!this.servico){
                this.horasQueFaltam = 0
            }
            const targetDate = this.dayjs(this.servico.dt_partida);

            const now = this.dayjs();

            const horas = targetDate.diff(now, 'hour')

            if(horas>=0){
               this.horasQueFaltam = 0
            }
           this.horasQueFaltam =  Math.abs(targetDate.diff(now, 'hour'));
        },
        submit() {

            this.form.submit().then((response) => {
                this.form.reset();
                this.$swal('Sucesso',
                    'Operação realizada com sucesso',
                    'success'
                );
                this.$emit('resourceCreated', response.data)
                this.$emit('closeSidebar')
                this.$emit('refresh')
            })

        }
    }
}
</script>


