<template>
    <div class="space-y-6 sm:space-y-5">
        <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <FormLabel :forProp="forProp" :required="required">{{ label }}</FormLabel>
            <div class="mt-1 sm:col-span-2 sm:mt-0">
                <slot></slot>
                <p class="mt-2 text-sm text-red-600" v-if="erro">{{ erro }}</p>
            </div>
        </div>
    </div>
</template>


<script>
import FormLabel from '../../Components/FormHorizontal/FormLabel.vue'

export default {
    components: {
        FormLabel
    },
    props: {
        forProp: String,
        label: String,
        required: Boolean,
        erro: String
    },
}
</script>
