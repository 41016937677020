<template>
    <div>
        <Button type="button" icon="pi pi-ellipsis-v" text @click="toggle" aria-haspopup="true"
                aria-controls="overlay_menu"/>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true"/>


        <Drawer v-model:visible
        ="modalInvalidarIsOpen" header="Invalidar Bilhete" position="right" style="width:50%">
        <BilheteInvalidar :bilhete="bilhete" @refresh="refresh" @closeSidebar="closeSidebar"/>
        </Drawer>

        <Drawer v-model:visible
        ="modalColocarEmCreditoIsOpen" header="Colocar Bilhete em crédito" position="right" style="width:50%">
        <BilheteColocarEmCredito :bilhete="bilhete" @refresh="refresh" @closeSidebar="closeSidebar"/>
        </Drawer>
        <Drawer v-model:visible
        ="modalPorUtilizar" header="Colocar Bilhete em por utilizar" position="right" style="width:50%">
        <BilhetePorUtilizar :bilhete="bilhete" @refresh="refresh" @closeSidebar="closeSidebar"/>
        </Drawer>
        <Drawer v-model:visible
        ="modalPendente" header="Colocar Bilhete pendente" position="right" style="width:50%">
        <BilhetePendente :bilhete="bilhete" @refresh="refresh" @closeSidebar="closeSidebar"/>
        </Drawer>
    </div>
</template>

<script>
import Menu from 'primevue/menu';
import BilheteColocarEmCredito from "@/Pages/Bilhetes/Components/Show/Estados/BilheteColocarEmCredito.vue";
import BilhetePorUtilizar from "@/Pages/Bilhetes/Components/Show/Estados/BilhetePorUtilizar.vue";
import BilheteInvalidar from "@/Pages/Bilhetes/Components/Show/Estados/BilheteInvalidar.vue";
import BilhetePendente from "@/Pages/Bilhetes/Components/Show/Estados/BilhetePendente.vue";

export default {
    components: {BilhetePendente, BilheteInvalidar, BilhetePorUtilizar, BilheteColocarEmCredito, Menu},
    props: {
        bilhete: {
            type: Object,
        },
    },
    data() {
        return {
            items: [],
            modalInvalidarIsOpen: false,
            modalColocarEmCreditoIsOpen: false,
            modalPorUtilizar: false,
            modalPendente: false
        }
    },
    watch: {
        bilhete(newValue, oldValue) {
            this.buildItems()
        }
    },
    mounted() {
        this.buildItems();
    },
    methods: {
        refresh() {
            this.$emit('refresh')
        },
        closeSidebar() {
            this.modalInvalidarIsOpen = false;
            this.modalColocarEmCreditoIsOpen = false;
            this.modalPorUtilizar = false;
            this.modalPendente = false
        },
        buildItems() {
            let items = [];

            if (this.bilhete.estado != 'EM_CREDITO') {
                items.push({
                    label: 'Colocar em crédito',
                    icon: 'pi pi-euro',
                    command: () => {
                        this.modalColocarEmCreditoIsOpen = true;
                    }
                });
            }

            if (this.bilhete.estado != 'CANCELADO') {
                items.push({
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    command: () => {
                        this.modalInvalidarIsOpen = true;
                    }
                });
            }

            if (this.bilhete.estado != 'POR_UTILIZAR') {
                items.push({
                    label: 'Por utilizar',
                    icon: 'pi pi-check',
                    command: () => {
                        this.modalPorUtilizar = true;
                    }
                });
            }

            if (this.bilhete.estado != 'PENDENTE') {
                items.push({
                    label: 'Pendente',
                    icon: 'pi pi-clock',
                    command: () => {
                        this.modalPendente = true;
                    }
                });
            }

            this.items = items

        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        }
    },
}
</script>
