import {defineStore} from 'pinia'

export const useServicoStore = defineStore('servico', {
    state: () => {
        return {
            servico: null,
        }
    },
    getters: {},
    actions: {
        async fetchServico(servico_id) {
            this.servico = null
            await axios.get(route('web-api.servicos.edit', {servico: servico_id}))
                .then((res) => {
                    this.servico = res.data
                })

        },
    },
})
