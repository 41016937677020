import {defineStore} from 'pinia'

export const useServicoNotasStore = defineStore('servico_notas', {
    state: () => {
        return {
            modalServicoNotasIsOpen: false,
            notas: null,
            modalServicoNotasCreateIsOpen: false,
        }
    },
    getters: {
        totalNotas() {
            if (this.notas) {
                return this.notas.length
            }
            return 0
        }
    },
    actions: {

        async fetchServicoNotas(servico_id) {
            this.notas = null
            await axios.get(route('web-api.servicos.notas.index', {servico: servico_id}))
                .then((res) => {
                    this.notas = res.data
                })
        },
        openModalServicoNotas(servico_id) {
            this.fetchServicoNotas(servico_id)
            this.modalServicoNotasIsOpen = true

        },

        closeModalServicoNotas() {
            this.modalServicoNotasIsOpen = false

        },

    },
})
