<template>
    <div>
        <InputGroup>
            <InputText v-model="dataValue" type="datetime-local"
            />
            <Button outlined @click="calculateData()">
                <font-awesome-icon icon="fa-solid fa-circle-info"/>
            </Button>
        </InputGroup>
        <div v-if="validationResult && !validationResult.error">
            <Message v-if="validationResult" :closable="false" severity="info">
                <p>Data sugerida: {{ validationResult.dt_chegada }}</p>
                <p>Kms: {{validationResult.kms}}</p>
                <Button text @click="preencher()">
                    <font-awesome-icon icon="fa-solid fa-arrow-up"/>
                    Preencher campos
                </Button>
            </Message>

            <Message v-else severity="error">
                Faltam campos
            </Message>

        </div>


        <p v-if="erro" class="text-red-500">{{ validationResult.error.info }}</p>
    </div>
</template>

<script>
import Message from 'primevue/message';
import InputGroup from 'primevue/inputgroup';
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: 'WInputDataChegada',
    components: {
        FontAwesomeIcon,
        Message,
        InputGroup
    },
    props: ['modelValue','dtPartida','origemLocalidade','destinoLocalidade'],
    data() {
        return {
            validationResult: null,
            erro: false
        };
    },
    computed: {
        dataValue: {
            get() {
                return this.modelValue;
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue);
            },
        },
    },
    methods: {
        preencher() {
            let payload = {
                dt_chegada: this.validationResult.dt_chegada,
            }
            this.$emit('preencher', payload)
        },
        calculateData() {
            this.erro = false
            this.validationResult = null
            if (!this.dtPartida){
                alert('Data de partida não preenchida')
                return
            }
            if(!this.origemLocalidade){
                alert('Localidade de origem não preenchida')
                return
            }
            if(!this.destinoLocalidade){
                alert('Localidade de destino não preenchida')
                return
            }
            let params = {
                dt_partida: this.dtPartida,
                origem_localidade: this.origemLocalidade,
                destino_localidade: this.destinoLocalidade,
            }

            axios.post(route('web-api.localidades.localidades.calculate-data-chegada'), params)
                .then((response) => {
                    this.validationResult = response.data;

                })
                .catch((error) => {
                    alert('Nao foi possivel calcular a data de chegada')
                });
        },
    },
};
</script>
