<template>
    <div class="bg-gray-100">
        <WCard class="" iconTitulo="fa-solid fa-bus" titulo="Serviços satélites"
        >
            <template #actions>
                <Button class="p-button-outlined" @click="resourceCreateIsOpen=true">
                    Adicionar Serviço Satélite
                </Button>
            </template>

            <template #content class="bg-gray-100">
                <DataTable v-if="servicos" :value="servicos" dataKey="id"
                           tableStyle="min-width: 60rem">
                    <Column header="Cod">
                        <template #body="slotProps">
                            <TableServicoEstado :show-full-label="true" :servico="slotProps.data" @updated="fetchData"/>
                        </template>
                    </Column>
                    <Column header="Passageiros">
                        <template #body="slotProps">{{ slotProps.data.bilhetes.length }}</template>
                    </Column>
                    <Column header="Viagem">
                        <template #body="slotProps">
                            <TableHorasServico :editable="true" :show-full-dates="true"
                                               :dt-partida="slotProps.data.dt_partida"
                                               :servicoId="slotProps.data.id"
                                               :dt-regresso="slotProps.data.dt_regresso"
                                               :localidadeChegada="slotProps.data.localidade_chegada"
                                               :localidadePartida="slotProps.data.localidade_partida"
                                               @refresh="refreshAll"
                            />
                            <div class="flex space-x-4">
                                <TableServicoAutocarro :editable="true" :servico="slotProps.data" @updated="fetchData"/>
                                <TableServicoMotoristas :servico="slotProps.data" @updated="fetchData"/>
                            </div>
                        </template>
                    </Column>
                    <Column header="Ações">
                        <template #body="slotProps">
                            <div class="flex space-x-4">
                                <a :href="'servicos/'+slotProps.data.id+'/satelite/print'" target="_blank">
                                    <font-awesome-icon class="cursor-pointer" icon="fas fa-print"/>
                                </a>

                                <MenuOpcoesServicoSateliteLinha :servico="slotProps.data" @refresh="refreshAll()"/>
                            </div>
                        </template>
                    </Column>

                </DataTable>

            </template>
        </WCard>

        <Drawer v-model:visible
                    ="resourceCreateIsOpen" class="lg:!w-[60rem]" position="right"
                @hide="closeSidebar()">
            <ServicoSateliteCreate
                v-if="resourceCreateIsOpen"
                :is-open="resourceCreateIsOpen"
                @closeSidebar="closeSidebar"
                :relatedToId="relatedToId"
                :relatedToType="relatedToType"
                @refresh="fetchData()"
            />
        </Drawer>


    </div>
</template>

<script>
import WCrudMixin from "@/Mixins/WCrudMixin.js";
import ClientesCreate from "@/Pages/Clientes/Create.vue";
import ServicoSateliteCreate
    from "@/Pages/Servicos/Internacionais/Components/ServicosSatelites/ServicoSateliteCreate.vue";
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";
import TableHorasServico from "@/Pages/Servicos/Servicos/components/Table/TableHorasServico.vue";
import MenuOpcoesServicosLista from "@/Pages/Servicos/Components/MassEdit/MenuOpcoesServicosLista.vue";
import MenuOpcoesServicoLinha from "@/Pages/Contratos/Ocasionais/Components/MenuOpcoesServicoLinha.vue";
import TableServicoEstado from "@/Pages/Servicos/Servicos/components/Table/TableServicoEstado.vue";
import WDataTable from "@/Mixins/WDataTable.js";
import {mapActions} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import MenuOpcoesServicoSateliteLinha
    from "@/Pages/Servicos/Internacionais/Components/MenuOpcoesServicoSateliteLinha.vue";

export default {
    components: {
        MenuOpcoesServicoSateliteLinha,
        TableServicoEstado, MenuOpcoesServicoLinha, MenuOpcoesServicosLista,
        TableHorasServico,
        TableServicoMotoristas,
        WInlineEdit,
        TableServicoAutocarro,
        ServicoSateliteCreate, ClientesCreate
    },
    mixins: [WCrudMixin, WDataTable],
    props: {
        relatedToId: {},
        relatedToType: {},
    },

    data() {
        return {
            servicos: [],
        }
    },

    mounted() {
        this.fetchData();
    },
    methods: {
        ...mapActions(useGlobalModalsStore, ['openModalServicoEdit']),

        showServico(servico) {
            this.openModalServicoEdit(servico.id)
        },
        fetchData() {
            axios.get(route('servicos.satelites.index', {
                servico: this.relatedToId,
            })).then((res => {
                this.servicos = res.data
            }))
        }
    },
}
</script>

