<template>
    <div>
        <h3 class="text-3xl font-bold">Criar Grupo</h3>

        <div>
            <form @submit.prevent="handleSubmit">
                <BreezeValidationErrors class="mb-4"/>


                <BreezeLabel for="nome" value="Nome"/>
                <InputText v-model="form.name" :class="[errors.name ? 'p-invalid' : '']" class="w-full"

                />

                <BreezeLabel for="permissoes" value="Permissões"/>
                <MultiSelect v-model="form.permissoes" :options="allPermissions" class="w-full md:w-20rem" display="chip"
                             filter
                             optionLabel="name"
                             optionValue="id"
                             placeholder="Selecione as permissões"/>

                <div class="flex justify-end mt-10 bg-gray-100 py-2">
                    <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                            label="Guardar" @click="handleSubmit()">
                    </Button>
                </div>

            </form>

        </div>

    </div>
</template>


<script>
import Sidebar from 'primevue/sidebar';
import AutoComplete from 'primevue/autocomplete';
import ToggleButton from 'primevue/togglebutton';
import Textarea from "primevue/textarea";
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';

export default {
    components: {
        Checkbox,
        Textarea,
        Sidebar,
        AutoComplete,
        ToggleButton,
        MultiSelect
    },
    name: 'CreateRole',

    data() {
        return {
            allPermissions: [],
            form: this.$inertia.form({
                name: null,
                permissoes: null,
            }),
        }
    },

    mounted() {
        this.fetchData();
    },

    methods: {

        fetchData() {
            axios.get('/web-api/roles/create')
                .then(response => {
                    this.allPermissions = response.data.permissions
                })
                .catch(error => {
                    console.log(error)
                })
        },
        closeSidebar() {
            this.form.reset();
            this.$emit('closeSidebar')
            this.$emit('refresh');
        },
        handleSubmit() {
            this.form.post(route("roles.store"),
                {
                    onSuccess: () => {
                        this.closeSidebar();
                        this.$swal('Grupo Inserido',
                            'O Grupo foi inserido com sucesso!',
                            'success'
                        );
                    },
                }
            );
        },

    }

}
</script>
