<template>

    <div>
        <WCard class="" iconTitulo="fa-solid fa-ticket" titulo="Tipo de Bilhetes">
            <template #actions>
                <Button @click="preencherDasConfiguracoes" outlined>Preencher das configurações</Button>
            </template>

            <template #content>

                <WSkeleton v-if="loading"/>
                <DataTable v-if="data"
                           :first="0"
                           :lazy="true"
                           :loading="loading"
                           :paginator="false"
                           :rows="10"
                           :sortOrder="1"
                           :value="data"
                           v-model:selection="resourceSelected"
                           currentPageReportTemplate="A mostrar do {first} até {last} de um total de {totalRecords}"
                           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           selectionMode="single"
                           sortField="dt_partida"
                           @page="onPage($event)"
                           @sort="onSort($event)">

                    <Column :sortable="true" field="nome" header="Nome"></Column>

                    <Column :sortable="true" field="desconto" header="Desconto"></Column>


                    <template #empty>
                        <WEmptyState icon="fa-solid fa-inbox" titulo="Nenhum bilhete encontrado"/>
                    </template>
                </DataTable>
            </template>
        </WCard>

        <Drawer v-model:visible="resourceCreateIsOpen" class="lg:!w-[60rem]" position="right"
                @hide="closeSidebar()">
            <TiposCreate
                :related-to-type="relatedToType"
                :related-to-id="relatedToId"
                v-if="resourceCreateIsOpen"
                :is-open="resourceCreateIsOpen"
                @closeSidebar="closeSidebar"
                @refresh="fetchData()"
            />
        </Drawer>

        <Drawer v-model:visible
                    ="resourceEditIsOpen" class="lg:!w-[60rem]" position="right"
                @hide="closeSidebar()">
            <TiposCreate
                :related-to-type="relatedToType"
                :related-to-id="relatedToId"
                :resource="resourceSelected"
                v-if="resourceEditIsOpen"
                :is-open="resourceEditIsOpen"
                @closeSidebar="closeSidebar"
                @refresh="fetchData()"
            />
        </Drawer>

    </div>

</template>

<script>

import WCrudMixin from "@/Mixins/WCrudMixin.js";
import WDataTable from "@/Mixins/WDataTable.js";
import TiposCreate from "@/Pages/Bilhetes/Components/TableTipoBilhetes/Create.vue";

export default {
    name: 'TableTipoBilhetes',
    components: {TiposCreate},
    mixins: [WDataTable, WCrudMixin],
    props: {
        relatedToId: {
            type: Number,
            required: true
        },
        relatedToType: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            resourceSelected: null,
            data: [],
            loading: false,
        }
    },
    watch: {
        resourceSelected(newValue) {
            this.resourceEditIsOpen = true
        }
    },
    mounted() {
        this.fetchData()
    },

    methods: {
        preencherDasConfiguracoes() {
            let params = {
                related_to_id: this.relatedToId,
                related_to_type: this.relatedToType,
            }
            axios.post(route('web-api.bilhetes.tipos.preencher-das-configuracoes'), params).then(response => {
                this.fetchData()
                this.$swal.fire({
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Tipos de bilhetes preenchidos com sucesso'
                })
            })
        },
        editResource(resource) {
            this.selectedResource = resource
        },
        async fetchData() {
            let params = {
                related_to_id: this.relatedToId,
                related_to_type: this.relatedToType,
            }
            axios.get(route('web-api.bilhetes.tipos.index', params)).then(response => {
                this.data = response.data
            })
        },

    }
}
</script>
