import {defineStore} from 'pinia'
import {useConfirmacoesStore} from "@/Store/ConfirmacoesStore.js";

export const useDashboardStore = defineStore('dashboard', {
    state: () => {
        return {
            dt: null,
            tabServicosSelected: 'Ocasionais',
            loadingAgendaDia: false,
            servicos: [],
            totalGuiasDeTransportePorImprimir: 0,
            totalRegulares: 0,
            totalOcasionais: 0,
            totalInternacionais: 0,
            modalServicoIsOpen: false,
            servicoSelected: null,
        }
    },
    getters: {},
    actions: {
        openModalServico(servico) {
            this.servicoSelected = servico
            this.modalServicoIsOpen = true
        },
        closeModalServico() {
            this.fetchServicosDia()
            this.servicoSelected = null
            this.modalServicoIsOpen = false
        },
        fetchServicosDia() {
            this.loadingAgendaDia = true
            this.servicos = null
            let tipo_servico = 'Ocasional';
            if (this.tabServicosSelected === 'Regulares') {
                tipo_servico = 'Regular'
            }
            if (this.tabServicosSelected === 'Internacionais') {
                tipo_servico = 'Internacional'
            }
            let params = {
                dt: this.dt,
                tipo_servico: tipo_servico,
                include_servico_regresso: true,
                is_servico_regresso: false,
            }
            axios.get(route('web-api.servicos.dashboard.agenda-dia', params)).then((res => {
                this.servicos = res.data.servicos
                this.totalGuiasDeTransportePorImprimir = 0
                this.loadingAgendaDia = false
            }))
        },
        updateServico(servicoId) {

            let index = this.servicos.findIndex((s) => s.id == servicoId) //Procurar o servico na lista de servicos da store

            if (index == -1) { // se não encontrar o servico na lista de servicos da store
                let servicoIda = this.servicos.find(servico => servico.servico_regresso_id == servicoId)


                index = this.servicos.findIndex((s) => s.id === servicoIda.id)
            }

            if (!servicoId) {
                console.log('Servico não encontrado' + servicoId)
                return
            }
            

            axios.get(route('web-api.servicos.show', servicoId)).then((res => {

                if (this.servicos && typeof index === 'number' && index >= 0 && index < this.servicos.length) {
                    this.servicos[index] = res.data
                }
                useConfirmacoesStore().fetchConfirmacoesCount(this.dt)
            }))
        }

    },
})
