<template>
    <div v-if="loaded" class="w-full overflow-x-scroll">


        <Panel header="Editar várias" toggleable>
            <div class="grid grid-cols-2">
                <div class="col-span-1">
                    <FormVerticalGroup :erro="form.errors.selectedOrigens" forProp="nome" label="Origens" required>
                        <MultiSelect v-model="form.selectedOrigens" :options="paragens" filter optionLabel="localidade.name"  optionValue="id" placeholder="Selecione as Origens"
                                     :maxSelectedLabels="3" class="w-full md:w-20rem" />
                    </FormVerticalGroup>

                </div>

                <div class="col-span-1">
                    <FormVerticalGroup :erro="form.errors.selectedDestinos" forProp="nome" label="Destinos" required>
                        <MultiSelect v-model="form.selectedDestinos" :options="paragens" filter optionLabel="localidade.name"  optionValue="id" placeholder="Selecione as Origens"
                                     :maxSelectedLabels="3" class="w-full md:w-20rem" />
                    </FormVerticalGroup>
                </div>

                <div class="col-span-1">
                    <FormVerticalGroup :erro="form.errors.selectedDestinos" forProp="preco" label="Preço" required>
                        <InputNumber autofocus ref="inputPreco" v-model="form.preco" mode="currency" currency="EUR" locale="pt-PT"
                                     class="w-1"/>
                    </FormVerticalGroup>
                </div>
            </div>

            <div class="flex justify-end mt-10 bg-gray-100 py-2">
                <Button :disabled="form.processing" :loading="form.processing" class="ml-4"
                        label="Guardar" @click="submit()">
                </Button>
            </div>
        </Panel>


        <DataTable :value="paragens" scrollable showGridlines stripedRows columnResizeMode="fit" selectionMode="single"
                   scrollHeight="700px"
                   tableStyle="min-width: 50rem;">
            <Column header="Paragem" style="min-width: 100px" frozen class="font-bold">
                <template #body="slotProps">
                    {{ slotProps.data.localidade.name }}
                </template>
            </Column>
            <template v-for="(paragemTH,index) in paragens"
                      :key="paragemTH.id">
                <Column v-if="index>0" :header="paragemTH.localidade.name">
                    <template #body="slotProps">

                        <TDParagemPreco
                            v-if="paragens[index] && Number(paragens[index].ordem) >= Number(slotProps.data.ordem) && paragens[index].localidade_id != slotProps.data.localidade_id"
                            :paragem="slotProps.data" :paragemPara="paragens[index]"
                            :relatedToId="relatedToId"
                            :relatedToType="relatedToType"
                        />
                    </template>
                </Column>
            </template>
        </DataTable>
    </div>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import TDParagemPreco from "@/Pages/Paragens/Components/TDParagemPreco.vue";
import {mapActions, mapState} from "pinia";
import {useParagemPrecoStore} from "@/Pages/Paragens/Store/ParagemPrecoStore.js";
import {useParagemStore} from "@/Pages/Paragens/Store/ParagemStore.js";
import MultiSelect from 'primevue/multiselect';
import FormVerticalGroup from "@/Components/FormVertical/FormVerticalGroup.vue";
import {useForm} from "laravel-precognition-vue";

export default {
    name: 'TableParagensPrecos',
    components: {FormVerticalGroup, MultiSelect,TDParagemPreco, DataTable, Column},
    props: {
        relatedToId: {
            required: true
        },
        relatedToType: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            form: useForm('PATCH',route('web-api.paragens.precos.update-multiples'), {
                selectedOrigens: [],
                selectedDestinos: [],
                preco: null,
                related_to_id: this.relatedToId,
                related_to_type: this.relatedToType,
            }),
            selectedOrigens:[],
            selectedDestinos:[],
            loaded: false,
        }
    },

    mounted() {
        this.fetchData()
    },
    computed: {
        ...mapState(useParagemStore, ['paragens']),
    },
    methods: {
        ...mapActions(useParagemStore, ['fetchParagens']),
        ...mapActions(useParagemPrecoStore, ['fetchParagensPrecos']),
        submit() {
            this.form.submit().then((response) => {
                this.form.reset();
                this.fetchData()
            })

        },
        async fetchData() {
            this.loaded = false;
            this.fetchParagens(this.relatedToType, this.relatedToId).then(() => {
                this.fetchParagensPrecos(this.relatedToType, this.relatedToId).then(() => {
                    this.loaded = true;
                })
            })
        },
    },
}
</script>


