<template>
  <div>
    <div class="cursor-pointer" @click="printGuiaDeTransporte()">
      <font-awesome-icon v-if="servico.guia_impressa" class="text-green-500"
                         icon="fa-solid fa-print"/>

      <font-awesome-icon v-else class="text-red-500"
                         icon="fa-solid fa-print"/>
    </div>


  </div>
</template>

<script>
import WBadge from "@/Components/WBadge.vue";
import {mapActions} from "pinia";
import {useDashboardStore} from "@/Store/DashboardStore.js";

export default {
  components: {WBadge},

  props: {
    servico: {
      type: Object,
    },
  },


  methods: {
    ...mapActions(useDashboardStore, ['updateServico']),

    printGuiaDeTransporte() {
      window.open(route('servicos.print.guia-de-transporte', this.servico))
      this.$confirm.require({
        message: 'Deseja marcar o serviços como impresso?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios.patch(route('web-api.servicos.update.guia-de-transporte-impressa', this.servico))
              .then(response => {

                this.updateServico(this.servico.servico_parent_id ? this.servico.servico_parent_id : this.servico.id)

                this.$toast.add({
                  severity: 'success',
                  summary: 'Sucesso',
                  detail: 'Serviço marcado como impresso',
                  life: 3000
                });
              })
              .catch(error => {
                    this.$toast.add({
                      severity: 'error',
                      summary: 'Erro',
                      detail: 'Erro ao marcar serviços como impressos',
                      life: 3000
                    });
                  }
              );

        },
        reject: () => {
          this.modalPrintGuiasDeTransporteIsOpen = false
        }
      });
    },
  },
}
</script>

