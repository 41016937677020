<template>
    <tr>
        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0" colspan="5">
            <div class="flex justify-center">
                <Button :disabled="form.processing" :loading="form.processing" text class=" ml-4"
                        label="+ Adicionar paragem" @click="createParagemIsOpen=true" v-if="!createParagemIsOpen">
                </Button>
                <Button :disabled="form.processing" :loading="form.processing" text class=" ml-4"
                        label="x Cancelar" @click="createParagemIsOpen=false" v-if="createParagemIsOpen">
                </Button>
            </div>
        </td>
    </tr>
    <tr v-if="createParagemIsOpen">
        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"></td>
        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
            <AddressAutocomplete v-model="form.paragem"
                                 :classCSS="[errors.paragem ? 'p-invalid' : 'w-full']"
                                 :closable="false"
                                 :is-edit-prop="true"
                                 :valueProp="form.paragem"
                                 field="paragem"
                                 @changeFormValue="changePlaceValue"/>
            <span v-if="errors.paragem" :class="[errors.paragem ? 'text-red-400' : '']">Paragem obrigatória</span>
        </td>
        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
            <InputText type="time" v-model="form.hora_partida" :class="[errors.hora_partida ? 'p-invalid' : '']"
            />
        </td>
        <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <Button :disabled="form.processing" :loading="form.processing" class=" ml-4"
                    @click="handleSubmit">
                <font-awesome-icon icon="fa-solid fa-check"/>
                Adicionar
            </Button>
        </td>
    </tr>

</template>

<script>
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import {useForm} from "laravel-precognition-vue";
import {ParagensRelatedToTypeEnum} from "@/Pages/Paragens/Enums/ParagensRelatedToTypeEnum.js";

export default {
    emits: ['ParagemCreated'],
    components: {AddressAutocomplete},
    props: {
        relatedToId: {
            type: Number,
            required: true
        },
        relatedToType: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            createParagemIsOpen: false,
            form: useForm('POST',route('web-api.paragens.store',[this.relatedToType,this.relatedToId]),{
                paragem: null,
                hora_partida: null,
            }),
        }
    },
    methods: {
        handleSubmit() {
            this.form.submit().then((response) => {
                this.form.reset()
                this.$emit('refresh')
                this.$swal('Paragem adicionada',
                    'A Paragem foi adicionada com sucesso!',
                    'success'
                );
                this.createParagemIsOpen = false
                this.$emit('ParagemCreated')
            })

        },
    },
}
</script>

