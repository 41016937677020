<template>
    <div v-if="loaded">
        <BreezeValidationErrors class="mb-4"/>

        <div
            class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-100 sm:grid-cols-4 sm:divide-x sm:divide-y-0 ">
            <div class="px-6 py-5 text-center text-sm font-medium">
                <div class="flex">
                    <TableServicoEstado :showFullLabel="true" :servico="servico" @updated="fetchData" class="mt-2"/>
                </div>
            </div>
            <div class="px-6 py-5 text-center text-sm font-medium">
                <FormVerticalGroup label="Preco">
                    <WInlineEdit :classCSS="[errors.preco ? 'p-invalid' : '']" :value="servico.preco"
                                 class="w-full"
                                 type="price"
                                 field="preco"
                                 post-fix="€"
                                 @saveField="saveFormField"
                    />
                    <p v-if="servico.preco" class=" text-xs text-gray-400">taxa de reserva: {{ precoTaxa }} €</p>
                </FormVerticalGroup>
            </div>
            <div class="px-6 py-5 text-center text-sm font-medium">
                <FormVerticalGroup label="Lotação">
                    <WInlineEdit :classCSS="[errors.lotacao ? 'p-invalid' : '']" :value="servico.lotacao"
                                 class="w-full"
                                 type="number"
                                 field="lotacao"
                                 @saveField="saveFormField"
                    />
                </FormVerticalGroup>
            </div>
            <div class="px-6 py-5 text-center text-sm font-medium">
                <FormVerticalGroup label="TCC">
                    <WInlineEdit :classCSS="[errors.tcc ? 'p-invalid' : '']" :value="servico.tcc"
                                 class="w-full"
                                 type="checkbox"
                                 field="tcc"
                                 @saveField="saveFormField"
                    />
                </FormVerticalGroup>
            </div>

            <div class="px-6 py-5 text-center text-sm font-medium">
                <FormVerticalGroup label="Tipo Cinto">
                    <WInlineEdit :classCSS="[errors.tipo_cinto ? 'p-invalid' : '']" :value="servico.tipo_cinto"
                                 class="w-full"
                                 type="select"
                                 field="tipo_cinto"
                                 :options-select="[{label: 'Sem Dados', value: null},{label: '2 Pontos', value: '2 Pontos'}, {label: '3 Pontos', value: '3 Pontos'}]"
                                 @saveField="saveFormField"
                    />
                </FormVerticalGroup>
            </div>

            <div class="px-6 py-5 text-center text-sm font-medium">
                <FormVerticalGroup label="Bancos Elevatórios">
                    <WInlineEdit :classCSS="[errors.bancos_elevatorios ? 'p-invalid' : '']"
                                 :value="servico.bancos_elevatorios"
                                 class="w-full"
                                 type="text"
                                 field="bancos_elevatorios"
                                 @saveField="saveFormField"
                    />
                </FormVerticalGroup>
            </div>

            <div class="px-6 py-5 text-center text-sm font-medium">
                <FormVerticalGroup label="Finalidade">
                    <WInlineEdit :classCSS="[errors.finalidade ? 'p-invalid' : '']" :value="servico.finalidade"
                                 class="w-full"
                                 field="finalidade"
                                 @saveField="saveFormField"
                    />
                </FormVerticalGroup>
            </div>
        </div>


        <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-2">
            <div>
                <Panel header="IDA" class="w-full">
                    <TableHorasServico :editable="true" :showFullDates="true" :dt-partida="servico.dt_partida"
                                       :servico-id="servico.id"
                                       :dt-regresso="servico.dt_regresso"
                                       :localidadeChegada="servico.localidade_chegada"
                                       :localidadePartida="servico.localidade_partida"
                                       @refresh="refreshAll()"
                    />

                    <TableServicoAutocarroMotoristas :editable="true" :servico="servico"
                                                     @updated="fetchData"/>


                </Panel>
            </div>
            <div>
                <Panel header="REGRESSO" class="w-full">

                    <template #icons>
                        <Button @click="deleteServico(servico.servico_regresso.id)" v-if="servico.servico_regresso"
                                outlined severity="danger">
                            Eliminar
                        </Button>
                    </template>

                    <div v-if="servico.servico_regresso">
                        <TableHorasServico :editable="true" :showFullDates="true"
                                           :dt-partida="servico.servico_regresso.dt_partida"
                                           :servico-id="servico.servico_regresso.id"
                                           :dt-regresso="servico.servico_regresso.dt_regresso"
                                           :localidadeChegada="servico.servico_regresso.localidade_chegada"
                                           :localidadePartida="servico.servico_regresso.localidade_partida"
                                           @refresh="refreshAll()"
                        />

                        <TableServicoAutocarroMotoristas :editable="true" :servico="servico.servico_regresso"
                                                         @updated="fetchData"/>
                    </div>
                    <div v-else>
                        <WEmptyState titulo="Sem regresso">
                            <Button :disabled="loadingSubmitAddRegresso" :loading="loadingSubmitAddRegresso"
                                    @click="addServicoRegresso" label="Adicionar regresso"/>
                        </WEmptyState>
                    </div>
                </Panel>
            </div>
        </dl>


        <WLabel>Observações</WLabel>
        <WInlineEdit :classCSS="[errors.observacoes ? 'p-invalid' : '']" :value="servico.observacoes"
                     class="w-full"
                     field="observacoes"
                     type="textarea"
                     @saveField="saveFormField"
        />

    </div>
</template>

<script>
import InlineEdit from "@/Components/Display/InlineEdit.vue";
import LocalidadesMixin from "@/Mixins/LocalidadesMixin.js";
import {mapActions, mapState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import WInlineEdit from "@/Components/WInlineEdit.vue";
import Checkbox from 'primevue/checkbox';
import InputNumber from "primevue/inputnumber";
import AddressAutocomplete from "@/Components/AddressAutocomplete.vue";
import Fieldset from "primevue/fieldset";
import {useDashboardStore} from "@/Store/DashboardStore.js";
import Textarea from "primevue/textarea";
import ServicoCliente from "@/Pages/Servicos/Servicos/components/Edit/ServicoCliente.vue";
import ServicoAutocarro from "@/Pages/Servicos/Servicos/components/Edit/ServicoAutocarro.vue";
import WBadge from "@/Components/WBadge.vue";
import ServicoAutocarros from "@/Pages/Servicos/Servicos/components/Edit/ServicoAutocarros.vue";
import TableHorasServico from "@/Pages/Servicos/Servicos/components/Table/TableHorasServico.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import TableServicoEstado from "@/Pages/Servicos/Servicos/components/Table/TableServicoEstado.vue";
import ServicoEstadoTag from "@/Pages/Servicos/Servicos/components/ServicoEstadoTag.vue";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import TableServicoAutocarro from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarro.vue";
import TableServicoMotoristas from "@/Pages/Servicos/Servicos/components/Table/TableServicoMotoristas.vue";
import TableServicoAutocarroMotoristas
    from "@/Pages/Servicos/Servicos/components/Table/TableServicoAutocarroMotoristas.vue";

export default {
    name: 'ServicoDetalhes',
    components: {
        TableServicoAutocarroMotoristas,
        TableServicoMotoristas,
        TableServicoAutocarro,
        ServicoEstadoTag,
        TableServicoEstado,
        FontAwesomeIcon,
        TableHorasServico,
        ServicoAutocarros,
        AddressAutocomplete,
        InlineEdit,
        WInlineEdit,
        Checkbox,
        InputNumber,
        Fieldset,
        Textarea,
        ServicoCliente,
        ServicoAutocarro,
        WBadge,

    },
    mixins: [LocalidadesMixin],
    props: {
        servicoId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            servico: null,
            loaded: false,
            modalEditServicoDatasELocalidadesIsOpen: false,
            loadingSubmitAddRegresso: false,
        }
    },
    mounted() {
        this.fetchData()
        const unsubscribe = useGlobalModalsStore().$onAction(
            ({
                 name, // name of the action
                 store, // store instance, same as `someStore`
                 args, // array of parameters passed to the action
                 after, // hook after the action returns or resolves
                 onError, // hook if the action throws or rejects
             }) => {

                if (name === 'globalModalStoreRefreshServicos') {
                    this.fetchData()
                }

            }
        )
    },
    computed: {
        precoTaxa() {
            if (this.servico.preco) {
                return Number(this.servico.preco * 0.25).toFixed(2)
            }
        }
    },
    methods: {
        ...mapActions(useServicoStore, ['closeModalServico']),
        ...mapActions(useDashboardStore, ['fetchServicosDia']),

        deleteServico(servico_id) {

            if (!confirm('Tem a certeza que deseja eliminar? ESTA AÇÃO É IRREVERSÍVEL!')) {
                return false;
            }

            this.$inertia.delete(route("servicos.destroy", servico_id),
                {
                    onSuccess: () => {
                        // this.$emit('closeModalServico')
                        this.$emit('refresh')
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Serviço eliminado',
                            detail: 'Serviço eliminado com sucesso',
                            life: 3000
                        });
                    },
                }
            );
        },
        refreshAll() {
            this.fetchData()
            this.$emit('refresh')
        },
        fetchData() {
            this.loaded = false
            axios.get(route('web-api.servicos.show', this.servicoId))
                .then(response => {
                    this.servico = response.data
                    this.loaded = true
                })
        },

        saveFormField(payload) {
            axios.patch(route('web-api.servicos.updateField', this.servico.id), payload).then((res => {
                this.fetchData()
            })).catch((error) => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: error.response.data.message,
                    life: 3000
                });
            })
        },
        addServicoRegresso() {
            this.loadingSubmitAddRegresso = true
            axios.post(route('web-api.servicos.add-servico-regresso', this.servico.id)).then((res => {
                this.refreshAll()
                this.loadingSubmitAddRegresso = false
            }))
        },
    },
}
</script>
