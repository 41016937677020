<template>
  <div v-if="!isEdit" class="cursor-pointer" @click="isEdit=true">


    <div v-if="!valueTemp">
      <span class="txt-editable-sem-dados ">Sem Dados</span>
    </div>


    <div v-if="valueTemp">

         <span v-if="typeof valueTemp ==='object' && Object.keys(valueTemp).length ==0"
               class="txt-editable-sem-dados ">Sem Dados</span>

      <div v-if="typeof valueTemp === 'string' || typeof  valueTemp ==='number'">
        <span v-if="valueTemp" class="txt-editable">{{ valueTemp }}</span>

        <span v-if="postFix">{{ postFix }} </span>
      </div>


      <div v-if="typeof valueTemp ==='boolean'">
            <span v-if="valueTemp === true"
                  class="txt-editable ">SIM</span>
        <span v-if="valueTemp === false"
              class="txt-editable ">NÃO</span>
      </div>

      <div v-if="typeof valueTemp === 'object'">

        <span v-if="valueTemp" class="txt-editable" v-html="valueTemp"></span>

        <span v-if="postFix">{{ postFix }} </span>
      </div>

    </div>


  </div>
  <div v-else>
    <div class="p-inputgroup">
      <InputText v-if="type==='text' || type==='date' || type==='number'" v-model="newValue" :type="type"/>
      <Textarea v-if="type==='textarea'" v-model="newValue" :autoResize="true" class="w-full" rows="5"
                style="width:100% !important;"
      ></Textarea>


      <InputNumber v-if="type==='price'" v-model="newValue" currency="EUR"
                   locale="pt-PT" mode="currency"/>

      <select v-if="type=='select'" v-model="newValue" class="w-input-select">
        <option v-for="option in resolveOptionsSelect()" :key="option.value"
                :selected="option.value==newValue">
          {{ option.label }}
        </option>
      </select>

      <div v-if="type==='simnao'">
        <div class="flex space-x-4 pr-4">
          <label for="rb1">Sim</label>
          <RadioButton v-model="newValue" inputId="rb1" value="Sim"/>
          <label for="rb2">Não</label>
          <RadioButton v-model="newValue" inputId="rb2" value="Não"/>
        </div>
      </div>


      <div v-if="type=='checkbox'">
        <div class="flex space-x-4 pr-4">
          <Checkbox v-model="newValue" :binary="true" :value="true"
          />
        </div>
      </div>

      <MultiSelect v-if="type=='multiselect'" v-model="newValue" :filter="true"
                   :options="resolveOptionsSelect()"
                   display="chip"
                   optionLabel="label"
                   optionValue="value"
      />


      <Button icon="pi pi-check" @click="saveField()"/>
      <Button class="p-button-outlined" icon="pi pi-times" @click="isEdit=false"/>
    </div>
  </div>

</template>

<script>
import Inplace from 'primevue/inplace';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import MultiSelect from 'primevue/multiselect';
import InputNumber from "primevue/inputnumber";
import Checkbox from "primevue/checkbox";

export default {
  components: {
    Inplace,
    Textarea,
    RadioButton,
    MultiSelect,
    InputNumber,
    Checkbox
  },
  //props:['field','value','clienteId','type','extra'],
  props: {
    field: {
      required: true,
    },
    value: {
      required: true
    },

    extra: {
      default: false
    },
    type: {
      default: 'text',
    },
    optionsSelect: {
      default: [],
    },
    postFix: {
      default: false,
    },
  },
  data() {
    return {
      valueTemp: this.value,
      newValue: this.value,
      isEdit: false,
    }
  },

  mounted() {
  },
  methods: {
    resolveOptionsSelect() {
      const options = [];

      if (typeof this.optionsSelect[0] === 'string') {
        this.optionsSelect.forEach((el) => {
          options.push({
            value: el,
            label: el
          })
        })
      }
      if (typeof this.optionsSelect[0] === "object") {
        return this.optionsSelect
      }
      return options
    },
    saveField() {
      const payload = {
        field: this.field,
        value: this.newValue,
        extra: this.extra
      };
      this.$emit('saveField', payload)
      this.isEdit = false
      this.valueTemp = this.newValue
    }
  }
}
</script>
