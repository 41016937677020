<!-- BaseInput.vue component -->

<template>
    <div>
        <span class="p-input-icon-left">
    <i class="pi pi-search"/>
     <InputText :placeholder="placeholder" :value="modelValue" type="search" @input="updateValue"/>
</span>

    </div>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
    components: {
        InputText,
    },
    props: {
        placeholder: null,
        modelValue: '',
    },
    data() {
        return {
            debounce: null
        }
    },
    methods: {
        updateValue(event) {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.$emit('update:modelValue', event.target.value);
            }, 600)
        }
    }
}
</script>
