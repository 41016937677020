<template>
  <div v-for="item in navigation" :key="item.name">
    <div v-if="!item.childs" class="flex w-full">
      <Link
        v-show="item.pode"
        :aria-current="item.current ? 'page' : undefined"
        :class="[
          isUrl(item.route)
            ? 'bg-gray-800 text-white'
            : 'text-gray-300 hover:text-white hover:bg-gray-900',
          'w-full group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
        ]"
        :href="item.href"
      >
        <div class="flex w-full justify-between">
          <span> {{ item.name }}</span>
          <div class="flex justify-end ml-4">
            <component
              :is="item.icon"
              :class="[isUrl(item.route) ? 'text-blue-500' : 'text-gray-400']"
              aria-hidden="true"
              class="flex-grow mr-2 h-6 w-6"
            />
          </div>
        </div>
      </Link>
    </div>

    <div v-if="item.childs">
      <div>
        <button
          class="flex items-center p-2 w-full text-base font-normal text-gray-300 rounded-lg transition duration-75 group hover:bg-gray-900 dark:text-white dark:hover:bg-gray-700"
          type="button"
          @click="item.open = !item.open"
        >
          <div class="flex w-full justify-between">
            <span
              class="flex-1 text-left whitespace-nowrap"
              sidebar-toggle-item
              >{{ item.name }}</span
            >

            <div class="flex items-center mr-2 text-lg">
              <i
                v-if="!item.open"
                class="pi pi-chevron-left h-2 w-2"
                style="font-size: 0.5rem"
              ></i>
              <i
                v-if="item.open"
                class="pi pi-chevron-down h-2 w-2"
                style="font-size: 0.5rem"
              ></i>
            </div>
            <div class="flex justify-end ml-4">
              <component
                :is="item.icon"
                :class="[isUrl(item.route) ? 'text-blue-500' : 'text-gray-400']"
                aria-hidden="true"
                class="flex-grow mr-2 h-6 w-6"
              />
            </div>
          </div>
        </button>
      </div>
      <div>
        <ul
          v-if="item.open"
          id="dropdown-example"
          class="py-4 space-y-2 px-0 bg-gray-900"
        >
          <li v-for="subItem in item.childs" :key="subItem.name">
            <Link
              v-show="subItem.pode"
              :href="subItem.href"
              class="text-gray-300 hover:text-white hover:bg-gray-900 w-full group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
            >
              <div class="flex w-full justify-between">
                <span>{{ subItem.name }}</span>
                <div class="flex justify-end ml-4">
                  <component
                    :is="subItem.icon"
                    :class="[
                      isUrl(subItem.route) ? 'text-blue-500' : 'text-gray-400',
                    ]"
                    aria-hidden="true"
                    class="flex-grow mr-2 h-6 w-6"
                  />
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navigation: {
      type: Object,
    },
  },

  methods: {
    isUrl(...urls) {
      // Get the current URL path excluding the leading slash
      let currentUrl = this.$page.url.substr(1);

      if (currentUrl.includes('?')) {
        currentUrl = currentUrl.split('?')[0]; // Or you can use substring method if you prefer
      }

      // Check if the current URL exactly matches any of the provided URLs
      return urls.some((url) => currentUrl === url);
    },
  },
};
</script>

