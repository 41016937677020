<template>
    <div>
        <div class="px-4 sm:px-0">
            <h3 class="text-base font-semibold leading-7 text-gray-900">Procurar servico</h3>
        </div>
        <div class="mt-6">
            <dl class="grid grid-cols-1 sm:grid-cols-2">
                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">DE</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ bilhete.origem_localidade.name }}</dd>
                </div>
                <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                    <dt class="text-sm font-medium leading-6 text-gray-900">PARA</dt>
                    <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{ bilhete.destino_localidade.name }}</dd>
                </div>
            </dl>
        </div>

        <Panel v-if="servicos" v-for="(servico,index) in servicos" :key="servico.id">
            <template #header>
                <div class="flex align-items-center gap-2">
                    <span class="font-bold">#{{ servico.id }}</span> <span></span>
                </div>
            </template>
            <template #footer>
                <div class="flex flex-wrap align-items-center justify-content-between gap-3">
                    <div class="flex align-items-center gap-2">
                        <Button icon="pi pi-calendar" severity="secondary" rounded text
                                :label="servico.dt_partida"></Button>
                    </div>
                    <span class="p-text-secondary"></span>
                </div>
            </template>
            <template #icons>
                <Button type="button" label="Selecionar" icon="pi pi-check" outlined
                        @click="selecionarViagem(servico)"/>

            </template>
            <p class="m-0">
                <dl class="grid grid-cols-1 sm:grid-cols-2">
                    <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">DE</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                            <p> {{ servico.localidade_partida }}</p>
                            <p>{{ servico.dt_partida }}</p>
                        </dd>
                    </div>
                    <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">PARA</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><p> {{ servico.localidade_chegada }}</p>
                            <p>{{ servico.dt_regresso }}</p>
                        </dd>
                    </div>
                </dl>
            </p>
        </Panel>

    </div>
</template>

<script>
import Panel from 'primevue/panel';

export default {
    props: {
        bilhete: Object
    },
    components: {
        Panel,
    },
    data() {
        return {
            form: {
                servico_id: null,
                bilhete_id: null,
            },
            servicos: null
        }
    },
    mounted() {
        this.fetchData();
    },

    methods: {
        selecionarViagem(servico) {
            if (confirm('Tem a certeza que deseja associar esta servico ao bilhete?')) {
                this.form.servico_id = servico.id
                this.form.bilhete_id = this.bilhete.id
                axios.post(route('web-api.bilhetes.associar-servico'), this.form)
                    .then(response => {
                        this.$emit('servicoAssociada', response.data)
                        this.$emit('refresh')
                        this.$emit('closeSidebar')
                        this.$swal('Sucesso',
                            'Operação realizada com sucesso',
                            'success'
                        );
                    })
                    .catch(error => console.log(error))
            }
        },
        fetchData() {
            let params = {
                origem_localidade_id: this.bilhete.origem_localidade_id,
                destino_localidade_id: this.bilhete.destino_localidade_id,
            }
            axios.get(route('web-api.reservas.create.servicos-por-localidades', params))
                .then(response => {
                    this.servicos = response.data
                })
                .catch(error => console.log(error))
        }
    },
}
</script>

