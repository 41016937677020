<template>
    <div>

        <div class="bg-gray-100">
            <section aria-labelledby="filter-heading" class="grid items-center border-b border-t border-gray-200">
                <h2 id="filter-heading" class="sr-only">Filters</h2>
                <div class="relative col-start-1 row-start-1 py-4">
                    <div class="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
                        <div>

                            <ServicoNotas/>

                        </div>
                        <div class="pl-6" v-if="servico.tipo_servico!='Internacional'">
                            <a :href="route('servicos.print.guia-de-transporte',servico.id)" target="_blank">
                                <font-awesome-icon icon="fa-solid fa-print"/>
                                <button class="text-gray-500 ml-2" type="button">Imprimir Guia de transporte</button>
                            </a>
                        </div>
                        <div class="pl-6" v-if="servico.tipo_servico==='Internacional'">
                            <a :href="route('servicos.print.internacional.show',servico.id)"
                               target="_blank">
                                <font-awesome-icon icon="fa-solid fa-print"/>
                                <button class="text-gray-500 ml-2" type="button">Imprimir Serviço</button>
                            </a>
                        </div>
                        <div class="pl-6">
                            <a :href="route('servicos.google-maps-url',servico.id)" target="_blank">
                                <font-awesome-icon icon="fa-solid fa-map"/>
                                <button class="text-gray-500 ml-2" type="button">Google maps</button>
                            </a>
                        </div>
                        <div class="pl-6" v-if="servico.tipo_servico==='Internacional'">
                            <a href="#">
                                <font-awesome-icon icon="fa-solid fa-euro"/>
                                <button class="text-gray-500 ml-2" type="button" @click="modalPrecosIsOpen=true">
                                    Preços
                                </button>
                            </a>
                        </div>
                        <div class="pl-6">

                            <font-awesome-icon icon="fa-solid fa-trash"/>
                            <button class="text-gray-500 ml-2" type="button" @click="deleteServico">Eliminar Serviço
                            </button>

                        </div>
                    </div>
                </div>


            </section>
        </div>


        <Dialog v-if="modalPrecosIsOpen" v-model:visible="modalPrecosIsOpen" modal header="Preços"
                :style="{ width: '90%' }">
            <TableParagensPrecos :relatedToId="servico.id" related-to-type="SERVICO"/>

            <div class="mt-20">
                <TableTipoBilhetes :related-to-id="servico.id"
                                   related-to-type="SERVICO"/>
            </div>
        </Dialog>

    </div>
</template>

<script>
import Menubar from 'primevue/menubar';
import ServicoAlterarEstado from "@/Pages/Servicos/Servicos/components/Edit/ServicoAlterarEstado.vue";
import NotasCreateEdit from "@/Pages/Notas/components/CreateEdit.vue";
import ServicoNotas from "@/Pages/Servicos/Servicos/components/Edit/ServicoNotas.vue";
import {mapState} from "pinia";
import {useServicoStore} from "@/Store/ServicoStore.js";
import TableParagensPrecos from "@/Pages/Paragens/Components/TableParagensPrecos.vue";
import TableTipoBilhetes from "@/Pages/Bilhetes/Components/TableTipoBilhetes/TableTipoBilhetes.vue";

export default {
    name: 'menuModalServico',
    components: {
        TableTipoBilhetes,
        TableParagensPrecos,
        ServicoAlterarEstado,
        Menubar,
        NotasCreateEdit,
        ServicoNotas,
    },
    data() {
        return {
            modalPrecosIsOpen: false,
        };
    },
    computed: {
        ...mapState(useServicoStore, ['servico'])
    },

    methods: {
        groupServico() {

        },
        deleteServico() {

            if (!confirm('Tem a certeza que deseja eliminar? ESTA AÇÃO É IRREVERSÍVEL!')) {
                return false;
            }

            this.$inertia.delete(route("servicos.destroy", this.servico.id),
                {
                    onSuccess: () => {

                        this.$emit('closeModalServico')
                        this.$emit('refresh')
                        this.$swal('Serviço eliminado',
                            'O Serviço foi eliminado com sucesso!',
                            'success'
                        );
                    },
                }
            );
        }
    },


}
</script>

