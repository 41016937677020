<template>
    <Dialog v-model:visible="modalServicoCreateIsOpen"
            :modal="true"
            :style="{width: '90vw'}"
            :header="getTituloModal"
            @hide="closeModal()"

    >

        <ServicoInternacionalCreate v-if="tipoServico==='Internacional'" @closeModal="closeModal"/>
        <ServicoCreate v-if="tipoServico==='Ocasional'" @closeModal="closeModal"/>

    </Dialog>
</template>

<script>
import {mapWritableState} from "pinia";
import {useGlobalModalsStore} from "@/Store/GlobalsModalsStore.js";
import ServicoInternacionalCreate from "@/Pages/Servicos/Internacionais/Create.vue";
import ServicoCreate from "@/Pages/Servicos/Servicos/Create.vue";
export default {
  name:'GlobalModalServicoCreate',
    components: {
        ServicoInternacionalCreate,
        ServicoCreate
    },
    computed: {
        ...mapWritableState(useGlobalModalsStore, ['tipoServico','modalServicoCreateIsOpen']),
        getTituloModal(){
            if(this.tipoServico==='Internacional'){
                return 'Novo Serviço Internacional';
            }
            return 'Novo Serviço Ocasional'
        },
    },

    methods: {
        closeModal() {
            this.modalServicoCreateIsOpen = false
        },
    },
}
</script>

